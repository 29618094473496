import React, { useEffect, useState } from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import { useQuery, NetworkStatus } from '@apollo/client'
import { format } from 'date-fns'

import history from 'shared/config/history'
import Pagination from 'shared/components/pagination'
import { UserPaymentType, UserPaymentTypeType } from 'shared/models/UserPayment/enums'
import { GET_USER_PAYMENTS } from '../queries'
import UserPaymentList from '../UserPaymentList'
import UserPaymentListFilter from './components/UserPaymentListFilter'
import LoadableContent from 'shared/components/LoadableContent'

export interface UserPaymentListFilterProps {
  dateAfter: any,
  dateBefore: any,
  paymentType: UserPaymentTypeType,
}

interface Props {
  withFilter?: boolean,
}

const defaultFilterProps = {
  dateAfter: undefined,
  dateBefore: undefined,
  paymentType: UserPaymentType.ANY,
}

const FilteredUserPaymentList: React.FC<Props> = ({ withFilter }) => {
  const match = useRouteMatch<any>()
  const { page } = useParams<any>()

  const [ filters, setFilters ] = useState<UserPaymentListFilterProps>(defaultFilterProps)
  const [ payments, setPayments ] = useState<any>()
  
  const { data, loading, refetch, networkStatus } = useQuery(GET_USER_PAYMENTS, {
    variables: {
      page,
      dateAfter: filters.dateAfter && format(filters.dateAfter, 'yyyy-MM-dd'),
      dateBefore: filters.dateBefore && format(filters.dateBefore, 'yyyy-MM-dd'),
      type: filters.paymentType,
    },
    notifyOnNetworkStatusChange: true,
  })
  
  useEffect(() => {
    if (JSON.stringify(filters) === JSON.stringify(defaultFilterProps)) return
    if (page && page === '1') return
    
    history.push(
      match?.params?.page
        ? match.path.replace(':page', '1')
        : match.path + '/1'
    )
    
    if (networkStatus === NetworkStatus.ready) refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  useEffect(() => {
    if (data?.userPayments) setPayments(data.userPayments)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  
  return (
    <LoadableContent ns={networkStatus} loading={loading}>
      {withFilter && <UserPaymentListFilter filters={filters} updateFilters={setFilters} />}
      {payments && (payments.paginatorInfo?.count
        &&  <>
              <UserPaymentList payments={payments.data} />
              <Pagination paginator={payments.paginatorInfo} urlBased />
            </>
        ||  <h2 className="py-5 text-center">Įmokų nėra</h2>)}
    </LoadableContent>
  )
}

export default FilteredUserPaymentList