import { gql } from '@apollo/client'

const MESSAGE_LOG_PAGINATOR = gql`
  fragment messageLogPaginator on MessageLogPaginator {
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      perPage
      total
    }
  }
`

const MESSAGE_LOG_ENTRY_ATTRIBUTES = gql`
  fragment messageLogEntryAttributes on MessageLog {
    created_at
    last_attempt_at
    last_error
    delivered_at

    message_template {
      id
      category
      description
    }
    type
    content

    recipient {
      id
      first_name
      last_name
    }

    loan {
      id
    }
  }
  ${MESSAGE_LOG_PAGINATOR}
`

export {
  MESSAGE_LOG_PAGINATOR,
  MESSAGE_LOG_ENTRY_ATTRIBUTES,
}