import React, { useContext } from 'react'

import { modalContext } from 'components/modals/context'
import LoanDetailsModal from './LoanDetailsModal'

const useLoanModal = () => {
  const { pushModal, popModal } = useContext(modalContext)

  const displayLoanModal = (id: string) =>
    pushModal(
      <LoanDetailsModal
        key={id}
        id={id}
        toggle={() => popModal(id)}
      />
    )

  return { displayLoanModal }
}

export default useLoanModal