import React from 'react'
import { Table } from 'reactstrap'

import UserPaymentListHeader from './components/UserPaymentListHeader'
import UserPaymentListBody from './components/UserPaymentListBody'

interface Props {
  payments: any[],
  columns?: {[key:string]: boolean},
}

const UserPaymentList: React.FC<Props> = ({ payments, columns }) => {
  columns = {
    payer: true,
    actions: true,
    ...columns,
  }

  return (
    <Table striped hover size="sm">
      <UserPaymentListHeader columns={columns} />
      <UserPaymentListBody columns={columns} payments={payments} />
    </Table>
  )
}

export default UserPaymentList
