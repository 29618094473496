import React, { useState, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import Pagination from 'shared/components/pagination'
import LoadableContent from 'shared/components/LoadableContent'
import {
  LoanStatusType, LoanStatus,
  LoanRepaymentStatus, LoanRepaymentStatusType
} from 'shared/models/Loan/enums'
import { GET_LOANS_BY_STATUS } from '../queries'
import LoanList from '../LoanList'
import LoanListFilter from './components/LoanListFilter'
import { getOrderByLoanStatus } from 'shared/models/Loan/helpers'

export interface LoanListFilterProps {
  search?: string,
  loanStatus: LoanStatusType,
  loanRepaymentStatus: LoanRepaymentStatusType,
  duePaymentLateDays: number|undefined,
}

interface Props {
  withFilter?: boolean,
  initialStatus?: LoanStatusType,
  initialRepaymentStatus?: LoanRepaymentStatusType,
}

const defaultFilterProps = {
  search: '',
  loanStatus: LoanStatus.ANY,
  loanRepaymentStatus: LoanRepaymentStatus.ANY,
}

const FilteredLoanList: React.FC<Props> = ({
  withFilter,
  initialStatus = LoanStatus.ACTIVE,
  initialRepaymentStatus = LoanRepaymentStatus.ANY,
}) => {
  const match = useRouteMatch<any>()
  // const { page } = useParams<any>()

  const [ filters, setFilters ] = useState<LoanListFilterProps>({
    ...defaultFilterProps,
    loanStatus: initialStatus,
    loanRepaymentStatus: initialRepaymentStatus,
    duePaymentLateDays: undefined,
  })

  const [ loans, setLoans ] = useState<any>()

  const { data, loading, networkStatus } = useQuery(GET_LOANS_BY_STATUS, {
    variables: {
      page: match?.params?.page || 1,
      search: filters.search,
      status: filters.loanStatus,
      repaymentStatus: filters.loanRepaymentStatus,
      duePaymentLateDays: filters.duePaymentLateDays,
      orderBy: getOrderByLoanStatus(filters.loanStatus),
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (data?.loans) setLoans(data.loans)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  
  useEffect(() => {
    if (JSON.stringify(filters) === JSON.stringify(defaultFilterProps)) return
    // if (page && page === '1') return
    
    // history.push(
    //   match?.params?.page
    //     ? match.path.replace(':page', '1')
    //     : match.path + '/1'
    // )
    
    // if (networkStatus === NetworkStatus.ready) refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  return (
    <LoadableContent ns={networkStatus} loading={loading}>
      {withFilter && <LoanListFilter filters={filters} updateFilters={setFilters} />}
      {loans && (loans.paginatorInfo?.count
        &&  <>
              <LoanList loans={loans.data} status={filters.loanStatus} />
              <Pagination paginator={loans.paginatorInfo} urlBased />
            </>
        ||  <h2 className="py-5 text-center">Paskolų nėra</h2>)}
    </LoadableContent>
  )
}

export default FilteredLoanList