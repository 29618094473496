import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import toaster from 'toasted-notes'

import { cache } from 'shared/config/graphql'
import UserPaymentMetadata from './components/UserPaymentMetadata'
import UserPaymentActions from './components/UserPaymentActions'
import UserPaymentEditModal from './components/UserPaymentEditModal'
import { DELETE_USER_PAYMENT, GET_USER_PAYMENT } from '../queries'
import LoadableContent from 'shared/components/LoadableContent'

interface Props {
  id: string,
  onAction(action: string): void,
}

const UserPaymentDetails: React.FC<Props> = ({ id, onAction }) => {
  const [ payment, setPayment ] = useState<any>()
  const [ editUserPaymentModal, toggleEditUserPaymentModal ] = useState<boolean>(false)
  
  const { data, loading, networkStatus } = useQuery(GET_USER_PAYMENT, {
    variables: { id },
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    data?.userPayment && setPayment(data.userPayment)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const [ deletePayment ] = useMutation(DELETE_USER_PAYMENT, {
    variables: { id },
    errorPolicy: 'all',
  })

  const userPaymentActionsCallback = (action: string) => {
    switch (action) {
      case 'editUserPayment':
        toggleEditUserPaymentModal(true)
        break

      case 'deleteUserPayment':
        deletePayment()
          .then((response: any) => {
            if (response.errors?.length) {
              toaster.notify('Klaida trinant įmoką: ' + response.errors[0].message, { duration: 5000 })
            } else {
              cache.modify({
                id: cache.identify(payment),
                fields: (_, {DELETE}) => DELETE,
              })
              toaster.notify('Įmoka ištrinta, mokėjimų grafikas pakoreguotas.', { duration: 5000 })
              onAction && onAction('deleteUserPayment')
            }
          })
        break
    }
  }

  const toggleCallback = (): void => {
    toggleEditUserPaymentModal(false)
  }
  
  return (
    <LoadableContent ns={networkStatus} loading={loading}>
      {payment && <>
        <UserPaymentActions onClick={userPaymentActionsCallback} />
        <UserPaymentMetadata payment={payment} />

        <UserPaymentEditModal
          isOpen={editUserPaymentModal}
          payment={payment}
          onAction={() => toggleCallback()}
        />
      </>}
    </LoadableContent>
  )
}

export default UserPaymentDetails
