import React from 'react'

import { FormatDate } from 'shared/components/FormatDate'

interface Props {
  loan: any,
  withDate?: boolean,
}

const LoanApplicationStatus: React.FC<Props> = ({ loan, withDate = false }) => {
  if (loan.rejected_at)
    return (<>
      {withDate && <>
          <FormatDate date={loan.rejected_at} format="yyyy-MM-dd HH:mm" />
          <br />
        </>}
      <strong className="text-danger">Atmesta</strong>
    </>)

  if (loan.closed_at)
    return (<>
      {withDate && <>
          <FormatDate date={loan.closed_at} format="yyyy-MM-dd HH:mm" />
          <br />
        </>}
      <strong className="text-success">Uždaryta</strong>
    </>)

  if (loan.confirmed_at)
    return (<>
      {withDate && <>
          <FormatDate date={loan.confirmed_at} format="yyyy-MM-dd HH:mm" />
          <br />
        </>}
      <strong className="text-info">Patvirtinta</strong>
    </>)

  return (<>
    {withDate && <>
        <FormatDate date={loan.requested_at} format="yyyy-MM-dd HH:mm" />
        <br />
      </>}
    <strong className="text-warning">Nepatvirtinta</strong>
  </>)
}

export default LoanApplicationStatus
