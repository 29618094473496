import React, { useEffect, useState } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Button, Alert, Label, FormGroup, Input, Row, Col, Spinner } from 'reactstrap'
import DatePicker from 'react-datepicker'
import toaster from 'toasted-notes'
import { subDays, format } from 'date-fns'

import { getLoanStatus } from 'shared/models/Loan/helpers'
import { LoanStatus } from 'shared/models/Loan/enums'
import ButtonWithConfirmation from 'shared/components/ButtonWithConfirmation'

import {
  GET_LOAN_REQUEST_DOCUMENT_PACKAGE_LINK,
  CONFIRM_LOAN_APPLICATION,
  REJECT_LOAN_APPLICATION
} from '../../queries'
import { GET_OPTION } from 'shared/models/Options/queries'
import LoadableContent from 'shared/components/LoadableContent'

interface Props {
  loan: any,
}

interface DownloadDocumentPackageButtonProps {
  loan: any,
  confirmedAt: any,
}

const DownloadDocumentPackageButton: React.FC<DownloadDocumentPackageButtonProps> = ({ loan, confirmedAt }) => {
  const [ url, setUrl ] = useState<string>()

  const [ load, { loading, data } ] = useLazyQuery(GET_LOAN_REQUEST_DOCUMENT_PACKAGE_LINK, {
    variables: { id: loan.id, confirmedAt: format(confirmedAt, 'yyyy-MM-dd HH:mm:ss') }
  })

  const getDocumentPackage = () => {
    if (!data || !url)
      load()
    else
      window.open(url)
  }

  useEffect(() => {
    if (!data) return
    setUrl(data.loanRequestDocumentPackage.url)
  }, [data])

  return (
      <Button
        color="success"
        size="sm"
        outline={!data}
        onClick={() => getDocumentPackage()}
      >
        {!data && !loading && <span>Dokumentų paketas</span>}
        {loading && <span>Dokumentai ruošiami <Spinner size="sm" color="success" /></span>}
        {data && <span>Atsisiųsti dokumentų paketą</span>}
      </Button>
    )
}

const PendingApplication: React.FC<Props> = ({ loan }) => {
  const [ reasonClassificator, setReasonClassificator ] = useState<any[]>()
  const [ confirmedAt, setConfirmedAt ] = useState<Date>(new Date())
  const [ rejectedBecause, setRejectedBecause ] = useState<string>('')

  const { loading, networkStatus } = useQuery(GET_OPTION, {
    variables: { key: 'loan_rejected_reason_classificator' },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data: any ) => {
      if (!data?.option?.option_value) return
      setReasonClassificator(JSON.parse(data.option.option_value))
    }
  })

  const [ confirm, { loading: confirmInProgress } ] = useMutation(CONFIRM_LOAN_APPLICATION, {
    variables: { id: loan.id, confirmedAt: format(confirmedAt, 'yyyy-MM-dd HH:mm:ss') }
  })
  const [ reject, { loading: rejectInProgress } ] = useMutation(REJECT_LOAN_APPLICATION, {
    variables: { id: loan.id, rejectedBecause }
  })

  if (getLoanStatus(loan) !== LoanStatus.PENDING) return null

  const confirmApplication = async () => {
    await confirm()
    toaster.notify('Paskolos paraiška patvirtinta.', { duration: 5000 })
  }

  const rejectApplication = async () => {
    await reject()
    toaster.notify('Paskolos paraiška atmesta.', { duration: 5000 })
  }

  return (
    <LoadableContent ns={networkStatus} loading={loading}>
      {reasonClassificator &&
        <Alert color={'warning'} className="w-100">
          <div className="display-4 mb-4 text-center">Paskolos paraiška</div>
          <Row>
            <Col md={6} className="pb-3">
              <FormGroup className="mb-2">
                <Label for="confirmedAt" className="d-block mb-1">Sutarties įsigaliojimo data:</Label>
                <DatePicker
                  wrapperClassName=""
                  dateFormat="yyyy-MM-dd"
                  minDate={subDays(new Date(), 14)}
                  selected={confirmedAt}
                  onChange={(value: Date) => setConfirmedAt(value)}
                  withPortal
                  customInput={(
                    <Input
                      id="confirmedAt"
                      name="confirmedAt"
                      type="text"
                      bsSize="sm"
                    />
                  )}
                />
              </FormGroup>
              <FormGroup className="m-n1">
                <div className="d-inline-block m-1">
                  <DownloadDocumentPackageButton loan={loan} confirmedAt={confirmedAt}/>
                </div>
                <ButtonWithConfirmation
                  color="success"
                  size="sm"
                  className="m-1"
                  buttonLabel="Patvirtinti"
                  confirmationTooltip="Tikrai norite patvirtinti paraišką?"
                  handleClick={confirmApplication}
                  displayLoader={confirmInProgress}
                />
              </FormGroup>
            </Col>

            <Col md={6} className="pb-3">
              <FormGroup className="mb-2">
                <Label for="rejectedBecause" className="d-block mb-1">Atmetimo priežastis:</Label>
                <Input
                    type="select"
                    id="rejectedBecause" name="rejectedBecause"
                    bsSize="sm"
                    onChange={e => setRejectedBecause(e.target.value)}>
                  <option key={'empty'}></option>
                  {Object.entries(reasonClassificator).map(([key, option]) =>
                    <option key={key} value={key}>{option}</option>
                  )}
                </Input>
              </FormGroup>
              <FormGroup>
                <ButtonWithConfirmation
                  color="danger"
                  size="sm"
                  outline
                  buttonLabel="Atmesti"
                  confirmationTooltip="Tikrai norite atmesti paraišką?"
                  handleClick={rejectApplication}
                  displayLoader={rejectInProgress}
                />
              </FormGroup>
            </Col>
          </Row>
        </Alert>}
    </LoadableContent>
  )
}

export { PendingApplication }