import { gql } from '@apollo/client'

const CUSTOMER_DETAILS_FRAGMENT = gql`
  fragment customerDetails on Customer {
    first_name
    last_name
    birthday
    personal_id
    phone_number
    bank_account_no

    residence_country
    residence_city
    residence_address
    registration_country
    registration_city
    registration_address

    avg_monthly_income
    avg_monthly_liabilities

    score
    classificator
    rating

    blocked_at
    blocked_by {
      id
      first_name
      last_name
    }
    blocked_because
    
    is_messaging_enabled
  }
`

const CUSTOMER_LOAN_STATS_FRAGMENT = gql`
  fragment customerLoanStats on Customer {
    loanCount
    activeLoanCount
    closedLoanCount
    pendingLoanApplicationCount
    rejectedLoanApplicationCount
  }
`

export {
  CUSTOMER_DETAILS_FRAGMENT,
  CUSTOMER_LOAN_STATS_FRAGMENT,
}
