import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { Col, Form, FormGroup, Input, Button, Label, Alert } from 'reactstrap'
import { Redirect } from 'react-router-dom'

import { LOGIN } from 'shared/models/User/queries'

const LoginForm: React.FC = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [redirect, setRedirect] = useState(false)
  
  const [login, { called, loading, data, error }] = useMutation(
    LOGIN,
    {
      onCompleted(response) {
        localStorage.setItem('token', response.login.access_token)
      },
      onError() {
        localStorage.removeItem('token')
      },
    }
  )

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault()
    login({ variables: { username, password } })
  }
  
  if (data && !redirect) {
    setRedirect(true)
  }

  return (
    <>
      {redirect && <Redirect to='/dashboard' />}

      <Form onSubmit={e => handleSubmit(e)}>
        <Alert color="danger" isOpen={!!error}>
          Klaidingi prisijungimo duomenys
        </Alert>

        <FormGroup row>
          <Label xs={12} md={4} for="login-username">Vartotojas</Label>
          <Col xs={12} md={8}>
            <Input type="text" id="login-username" name="username" autoComplete="username"
                  value={username}
                  onChange={e => setUsername(e.target.value)} />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label xs={12} md={4} for="login-password">Slaptažodis</Label>
          <Col xs={12} md={8}>
            <Input
              type="password" id="login-password" name="password" autoComplete="current-password"
              value={password}
              onChange={e => setPassword(e.target.value)} />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col md={{ size: 8, offset: 4 }}>
            <Button type="submit" color="primary" disabled={called && loading}>Prisijungti</Button>
          </Col>
        </FormGroup>
      </Form>
    </>
  )
}

export default LoginForm
