import React, { useContext } from 'react'
import { format as dfnsFormat, formatDistance, formatDistanceStrict, differenceInDays, endOfDay, Locale } from 'date-fns'
import { lt } from 'date-fns/locale'

import { localeContext } from 'shared/components/locale/context'

const locales = { lt }
const weekStartsOn = 1

interface DfnsOptions {
  locale?: Locale,
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6,
  firstWeekContainsDate?: number,
  useAdditionalWeekYearTokens?: boolean,
  useAdditionalDayOfYearTokens?: boolean,
}

interface FormatProps {
  date: string,
  format: string,
  options?: DfnsOptions,
}

export const FormatDate: React.FC<FormatProps> = ({date, format, options}) => {
  const { locale: localeKey } = useContext(localeContext)
  const locale  = locales[localeKey]

  options = {...options, locale, weekStartsOn}

  return (
    <span>{dfnsFormat(new Date(date), format, options)}</span>
  )
}

interface FormatSplitProps {
  date: string,
  formatOne?: string,
  formatTwo?: string,
  options?: DfnsOptions,
}

export const FormatDateSplit: React.FC<FormatSplitProps> = ({
  date,
  formatOne = "yyyy-MM-dd",
  formatTwo = "HH:mm",
  options,
}) => (<>
  <FormatDate format={formatOne} date={date} options={options} />
  <div className="small text-muted">
    <FormatDate format={formatTwo} date={date} options={options} />
  </div>
</>)

interface FormatDistanceProps {
  date: string,
  baseDate?: Date | number,
  options?: {
    includeSeconds?: boolean,
    addSuffix?: boolean,
    locale?: Locale,
  }
}

export const FormatDistance: React.FC<FormatDistanceProps> = ({date, baseDate = new Date(), options}) => {
  const {locale: localeKey } = useContext(localeContext)
  const locale = locales[localeKey]
  
  return (
    <span>{formatDistance(new Date(date), baseDate, { ...options, locale, addSuffix: true })}</span>
    )
}

export const FormatDistanceStrict: React.FC<FormatDistanceProps> = ({date, baseDate = new Date(), options}) => {
  const {locale: localeKey } = useContext(localeContext)
  const locale = locales[localeKey]
  
  return (
    <span>{formatDistanceStrict(new Date(date), baseDate, { ...options, locale, unit: 'day', roundingMethod: 'ceil', addSuffix: false })}</span>
  )
}
  
interface DifferenceInDaysProps {
  dateLeft?: string,
  dateRight?: string,
}

export const DifferenceInDays: React.FC<DifferenceInDaysProps> = ({dateLeft, dateRight}) => {
  const dl = endOfDay(dateLeft ? new Date(dateLeft) : Date.now())
  const dr = endOfDay(dateRight ? new Date(dateRight) : Date.now())
  const diff = differenceInDays(dl, dr)
  const className = diff > 7 ? '' : (diff > 0 ? 'text-warning' : 'text-danger')
  
  return <span className={className}>{diff}</span>
}
