import React from 'react'
import { Col, Form, Row, Input } from 'reactstrap'
import { useForm } from 'react-hook-form'

import CopyToClipboard from 'shared/components/CopyToClipboard'
import { FormatDate } from 'shared/components/FormatDate'
import { useMutation } from '@apollo/client'
import { UPDATE_CUSTOMER_SCORE, UPDATE_CUSTOMER_CLASSIFICATOR } from 'components/customers/queries'
import toaster from 'toasted-notes'
import { customerClassificatorOptions } from 'components/customers/CustomerClassificatorBadge'
import { customerScoreOptions } from 'components/customers/CustomerScoreBadge'

interface Props {
  user: any,
  customer: any,
}

const Profile: React.FC<Props> = ({ user, customer }) => {
  const [ updateCustomerScore ] = useMutation(UPDATE_CUSTOMER_SCORE, { errorPolicy: 'all' })
  const [ updateCustomerClassificator ] = useMutation(UPDATE_CUSTOMER_CLASSIFICATOR, { errorPolicy: 'all' })
  
  const { register, getValues } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      score: customer.score,
      classificator: customer.classificator,
    }
  })

  const onSubmitScore = (data: any) => {
    try {
      updateCustomerScore({variables: { id: customer.id, score: parseInt(data.score) }})
        .then(() => toaster.notify('Kliento klasifikacija išsaugota', { duration: 5000 }))
        .catch(() => toaster.notify('Klaida išsaugant klasifikatorių!', { duration: 5000 }))
    } catch (e) {
      // ...
    }
  }

  const onSubmitClassificator = (data: any) => {
    try {
      updateCustomerClassificator({variables: { id: customer.id, classificator: parseInt(data.classificator) }})
        .then(() => toaster.notify('Kliento klasifikacija išsaugota', { duration: 5000 }))
        .catch(() => toaster.notify('Klaida išsaugant klasifikatorių!', { duration: 5000 }))
    } catch (e) {
      // ...
    }
  }

  return (<>
    <Row>
      <Col xs={12} lg={6} className="mb-3">
        <Row className='align-items-center'>
          <Col className="font-weight-bold text-right">
            <CopyToClipboard data={customer.score}>Klasifikacija</CopyToClipboard>
          </Col>
          <Col className="text-left">
            <Form autoComplete="off">
              <div className="mb-1">
                <Input
                  id="score" name="score"
                  type="select"
                  bsSize="sm"
                  innerRef={register()}
                  onChange={() => onSubmitScore(getValues())}
                >
                  {customerScoreOptions.map(score => (
                    <option key={score.value} value={score.value} className={`text-${score.color}`}>{score.label}</option>
                  ))}
                </Input>
              </div>
              
              <div>
                <Input
                  id="classificator" name="classificator"
                  type="select"
                  bsSize="sm"
                  innerRef={register()}
                  onChange={() => onSubmitClassificator(getValues())}
                >
                  {customerClassificatorOptions.map(classificator => (
                    <option key={classificator.value} className={`text-${classificator.color}`}>{classificator.value}</option>
                  ))}
                </Input>
              </div>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>

    <Row>
      <Col xs={12} lg={6} className="mb-3">
        <Row className="mb-1">
          <Col className="font-weight-bold text-right">
            <CopyToClipboard data={customer.first_name}>Vardas</CopyToClipboard>
          </Col>
          <Col className="text-left">{customer.first_name}</Col>
        </Row>
        <Row className="mb-1">
          <Col className="font-weight-bold text-right">
            <CopyToClipboard data={customer.last_name}>Pavardė</CopyToClipboard>
          </Col>
          <Col className="text-left">{customer.last_name}</Col>
        </Row>
        <Row className="mb-1">
          <Col className="font-weight-bold text-right">
            <CopyToClipboard data={customer.personal_id}>Asmens kodas</CopyToClipboard>
          </Col>
          <Col className="text-left">{customer.personal_id}</Col>
        </Row>
        <Row className="mb-1">
          <Col className="font-weight-bold text-right">
            <CopyToClipboard data={customer.birthday}>Gimimo data</CopyToClipboard>
          </Col>
          <Col className="text-left">{customer.birthday}</Col>
        </Row>
        <Row className="mb-1">
          <Col className="font-weight-bold text-right">
            <CopyToClipboard data={user.email}>El. paštas</CopyToClipboard>
          </Col>
          <Col className="text-left">{user.email}</Col>
        </Row>
        <Row className="mb-1">
          <Col className="font-weight-bold text-right">
            <CopyToClipboard data={customer.phone_number}>Tel. nr.</CopyToClipboard>
          </Col>
          <Col className="text-left">{customer.phone_number}</Col>
        </Row>
        <Row className="mb-1">
          <Col className="font-weight-bold text-right">
            <CopyToClipboard data={customer.bank_account_no}>Sąsk. nr.</CopyToClipboard>
          </Col>
          <Col className="text-left">{customer.bank_account_no}</Col>
        </Row>
      </Col>

      <Col xs={12} lg={6} className="mb-3">
        <Row className="mb-1">
          <Col className="font-weight-bold text-right">
            <CopyToClipboard data={user.created_at}>Reg. data</CopyToClipboard>
          </Col>
          <Col className="text-left">
            <FormatDate format="yyyy-MM-dd HH:mm" date={user.created_at} />
          </Col>
        </Row>
        <Row className="mb-1">
          <Col className="font-weight-bold text-right">
            <CopyToClipboard data={[customer.workplace, ' ', customer.workplace_position]}>Darbovietė, pareigos</CopyToClipboard>
          </Col>
          <Col className="text-left">{customer.workplace} {customer.workplace_position}</Col>
        </Row>
        <Row className="mb-1">
          <Col className="font-weight-bold text-right">
            <CopyToClipboard data={customer.registration_address}>Reg. adresas</CopyToClipboard>
          </Col>
          <Col className="text-left">{customer.registration_address}</Col>
        </Row>
        <Row className="mb-1">
          <Col className="font-weight-bold text-right">
            <CopyToClipboard data={customer.registration_address + ', ' + customer.registration_country}>Reg. miestas</CopyToClipboard>
          </Col>
          <Col className="text-left">{customer.registration_city}, {customer.registration_country}</Col>
        </Row>
        <Row className="mb-1">
          <Col className="font-weight-bold text-right">
            <CopyToClipboard data={customer.residence_address}>Gyv. adresas</CopyToClipboard>
          </Col>
          <Col className="text-left">{customer.residence_address}</Col>
        </Row>
        <Row className="mb-1">
          <Col className="font-weight-bold text-right">
            <CopyToClipboard data={customer.residence_city + ", " + customer.residence_country}>Gyv. miestas</CopyToClipboard>
          </Col>
          <Col className="text-left">{customer.residence_city}, {customer.residence_country}</Col>
        </Row>
      </Col>

      <Col xs={12} lg={6} className="mb-3">
        <Row className="mb-1">
          <Col className="font-weight-bold text-right">
            <CopyToClipboard data={customer.avg_monthly_income}>Mėn. pajamos</CopyToClipboard>
          </Col>
          <Col className="text-left">{customer.avg_monthly_income}</Col>
        </Row>
        <Row className="mb-1">
          <Col className="font-weight-bold text-right">
            <CopyToClipboard data={customer.avg_monthly_liabilities}>Mėn. įsipareigojimai</CopyToClipboard>
          </Col>
          <Col className="text-left">{customer.avg_monthly_liabilities}</Col>
        </Row>
      </Col>
    </Row>
  </>)
}

export default Profile
