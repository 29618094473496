import React from 'react'
import { Redirect, match, useRouteMatch } from 'react-router-dom'

type TParams = { id: string };

const View: React.FC = () => {  
  const routeMatch: match<TParams> | null = useRouteMatch()

  if (!routeMatch) return <Redirect to="/users/system/list" />

  return (
    <h2>Sistemos vartotojas {routeMatch.params.id}</h2>
  )
}

export default View
