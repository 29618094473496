import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import { Container } from 'reactstrap'


const Lenders: React.FC = () => (
  <Container>
    <Switch>
      <Redirect to="/dashboard" />
    </Switch>
  </Container>
)

export default Lenders
