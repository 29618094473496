import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/client'
import { Helmet } from 'react-helmet'
import { Router, Route, Redirect, Switch } from 'react-router-dom'

import client from 'shared/config/graphql'
import history from 'shared/config/history'

/* styles */
import 'shared/sass/index.scss'

/* layout */
import AdminLayout from 'components/layout/AdminLayout'

/* routes */
import NotFound from 'routes/404'
import Login from 'routes/login'
import Accounting from 'routes/accounting'
import Activity from 'routes/activity'
import Dashboard from 'routes/dashboard'
import Loans from 'routes/loans'
import Settings from 'routes/settings'
import Tools from 'routes/tools'
import UserPayments from 'routes/user-payments'
import Users from 'routes/users'

/* contexts */
import AuthContextProvider from 'shared/components/auth/context'
import LocaleContextProvider from 'shared/components/locale/context'
import ModalContextProvider from 'components/modals/context'

/* app */
const App: React.FC = () => (
  <ApolloProvider client={client}>
    <Helmet titleTemplate="%s | Goldfish" defaultTitle="Goldfish"></Helmet>

    <Router history={history}>
      <Switch>
        <Route exact path="/" component={Login} />

        <LocaleContextProvider>
          <AuthContextProvider>
            <ModalContextProvider>
              <AdminLayout>
                <Switch>
                  <Route exact path="/dashboard" component={Dashboard} />
                  <Route path="/accounting" component={Accounting} />
                  <Route path="/loans" component={Loans} />
                  <Route path="/user-payments" component={UserPayments} />
                  <Route path="/settings" component={Settings} />
                  <Route path="/tools" component={Tools} />
                  <Route path="/users" component={Users} />
                  <Route path="/activity" component={Activity} />

                  <Route exact path="/404" status={404} component={NotFound} />
                  <Redirect to="/404" />
                </Switch>
              </AdminLayout>
            </ModalContextProvider>
          </AuthContextProvider>
        </LocaleContextProvider>
      </Switch>
    </Router>
  </ApolloProvider>
)

ReactDOM.render(<App />, document.getElementById('root'))