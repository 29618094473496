import React from 'react'

import { UserPaymentTypeType } from 'shared/models/UserPayment/enums'

interface IProps {
  type: UserPaymentTypeType,
}

const UserPaymentType: React.FC<IProps> = ({ type }) => {
  let element;

  switch (type) {
    case 'REGISTRATION':
      element = <span className="user-payment-type">registracija</span>
      break
    case 'INSTALLMENT':
      element = <span className="user-payment-type">įmoka</span>
      break
    case 'EARLY_REPAYMENT':
      element = <span className="user-payment-type">išankstinis grąžinimas</span>
      break
  }

  return element || <React.Fragment />
}

export default UserPaymentType
