import React from 'react'
import { ChatLeftDots, ChatLeftDotsFill, InfoCircleFill } from 'react-bootstrap-icons'

import AprDisplay from 'shared/components/AprDisplay'
import DurationDisplay from 'shared/components/DurationDisplay'
import { FormatDate } from 'shared/components/FormatDate'
import CurrencyDisplay from 'shared/components/CurrencyDisplay'
import InterestDisplay from 'shared/components/InterestDisplay'
import PrincipalDisplay from 'shared/components/PrincipalDisplay'
import { LoanStatus, LoanStatusType } from 'shared/models/Loan/enums'
import { getCurrentDebt, getCurrentPayments, getStartingDebt, getLoanStatus, getRepaymentStatus, getCurrentDiscount } from 'shared/models/Loan/helpers'

import ScheduleType from 'components/loans/ScheduleType'
import LoanApplicationStatus from './LoanApplicationStatus'
import LoanLink from 'components/loans/LoanLink';
import CustomerLink from 'components/customers/CustomerLink';
import CustomerRatingBadge from 'components/customers/CustomerScoreBadge'
import CustomerClassificatorBadge from 'components/customers/CustomerClassificatorBadge'

interface Props {
  loans: [any],
  status: LoanStatusType,
  columns: {[key: string]: boolean},
}

const LoanListBody: React.FC<Props> = ({ loans, status, columns }) => {
  const dateColumn = (loan: any) => {
    switch (status) {
      case LoanStatus.ANY:
      case LoanStatus.UNAUTHORIZED:
      case LoanStatus.PENDING:
        return <td><FormatDate format="yyyy-MM-dd HH:mm" date={loan.requested_at} /></td>
      case LoanStatus.ACTIVE:
        return <td><FormatDate format="yyyy-MM-dd" date={loan.confirmed_at} /></td>
      case LoanStatus.CLOSED:
        return <td><FormatDate format="yyyy-MM-dd" date={loan.closed_at} /></td>
      case LoanStatus.REJECTED:
        return <td><FormatDate format="yyyy-MM-dd" date={loan.rejected_at} /></td>
      default:      
        return <td><FormatDate format="yyyy-MM-dd" date={loan.requested_at} /></td>
    }
  }

  const getLoanStatusClass = (loan: any) => 'loan-status-' + getLoanStatus(loan).toLowerCase()
  const getRepaymentStatusClass = (loan: any) => 'repayment-status-' + getRepaymentStatus(loan).toLowerCase()

  return (
    <tbody>
      {loans.map((loan: any) => (
        <tr
          key={loan.id}
          className={'loan-list-row ' + getLoanStatusClass(loan) + ' ' + getRepaymentStatusClass(loan)}
        >
          {columns.status && <td>
            <LoanApplicationStatus loan={loan} />
          </td>}
          {dateColumn(loan)}
          {columns.customer && <td>
            <CustomerLink subject={loan.borrower} opensModal />
            {loan.borrower.customer.score && (
              <span className='ml-1'>
                <CustomerRatingBadge customer={loan.borrower.customer} />
              </span>)}
            {loan.borrower.customer.classificator && (
              <span className='ml-1'>
                <CustomerClassificatorBadge customer={loan.borrower.customer} />
              </span>)}
          </td>}
          {columns.loanInfo && <td className="text-left">
            <LoanLink className="d-flex align-items-center" loanId={loan.id} >
              <InfoCircleFill size={15} />
              {columns.notes && loan.has_relevant_notes && ( loan.has_important_notes
                ? <ChatLeftDotsFill className="text-warning ml-2" size={15} />
                : <ChatLeftDots className="text-primary ml-2" size={15} />
              )}
            </LoanLink>
          </td>}
          <td className="text-left text-muted">
            <ScheduleType type={loan.type} />
          </td>
          <td className="text-right">
            <DurationDisplay periods={loan.periods} periodsExtension={loan.delay_periods} periodsPerYear={loan.periods_per_year} />
          </td>
          <td className="principal text-right">
            <PrincipalDisplay value={loan.principal} />
          </td>
          <td className="interest text-right">
            <InterestDisplay value={loan.interest} />
          </td>
          <td className="starting-debt text-right">
            <CurrencyDisplay value={getStartingDebt(loan)} decimalScale={0}/>
          </td>
          {columns.discount && <td className="discount text-right">
            <CurrencyDisplay value={getCurrentDiscount(loan)} />
          </td>}
          {columns.paid && <td className="current-payments text-right">
            <CurrencyDisplay value={getCurrentPayments(loan)} />
          </td>}
          {columns.debt && <td className="current-debt text-right">
            <CurrencyDisplay value={getCurrentDebt(loan)} />
          </td>}
          <td className="text-right">
            <AprDisplay value={loan.apr} />
          </td>
        </tr>
      ))}
    </tbody>
  )
}

export default LoanListBody