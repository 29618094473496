import React from 'react'
import { Container } from 'reactstrap'
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom'

import Create from './Create'
import Edit from './Edit'
import List from './List'
import View from './View'

const Customers: React.FC<RouteComponentProps<{id: string}>> = ({ match }) => (
  <Container fluid>
    <Switch>
      <Route exact path={`${match.path}/create`} component={Create} />
      <Route exact path={`${match.path}/edit/:id`} component={Edit} />
      <Route exact path={[`${match.path}/list`, `${match.path}/list/:page`]} component={List} />
      <Route path={`${match.path}/view/:id`} component={View} />
      
      <Redirect to="/dashboard" />
    </Switch>
  </Container>
)

export default Customers