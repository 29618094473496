import React from 'react'

const CustomerListHeader: React.FC = () => (
  <>
    <thead className="thead-dark">
      <tr>
        <th scope="col">Vardas</th>
        <th scope="col">R</th>
        <th scope="col">El. paštas</th>
        <th scope="col">Miestas</th>
        <th scope="col">Asmens kodas</th>
        <th scope="col">Tel. nr.</th>
        <th scope="col">Reg. data</th>
        <th scope="col">Paraiškos</th>
      </tr>
    </thead>
  </>
)

export default CustomerListHeader
