import React from 'react'

interface Props {
  columns: {[key:string]: boolean},
}

const AttachmentListHeader: React.FC<Props> = ({ columns }) => (
  <thead className="thead-dark">
    <tr>
      <th scope="col" className="text-center">
        <span className="mr-3">Įkeltas</span>
      </th>
      {columns.description && <th scope="col" className="text-center">Pavadinimas</th>}
      {columns.filename && <th scope="col" className="text-center">Dokumentas</th>}
    </tr>
  </thead>
)

export default AttachmentListHeader
