import React from 'react'
import { Table } from 'reactstrap'

import { LoanStatusType, LoanStatus } from 'shared/models/Loan/enums'

import LoanListHeader from './components/LoanListHeader'
import LoanListBody from './components/LoanListBody'

interface Props {
  loans: [any],
  status: LoanStatusType,
  columns?: {[key:string]: boolean},
}

const LoanList: React.FC<Props> = ({ loans, status = 'ANY', columns = {} }) => {
  columns = {
    customer: true,
    loanInfo: true,
    status: false,
    notes: true,
    discount: true,
    paid: true,
    debt: true,
    ...columns
  }
  
  switch (status) {
    case LoanStatus.ANY:
      columns.status = true
      break
      
    case LoanStatus.UNAUTHORIZED:
    case LoanStatus.PENDING:
      columns.discount = false
      columns.paid = false
      columns.debt = false
      break

    case LoanStatus.ACTIVE:
      break

    case LoanStatus.CLOSED:
      break
      
    case LoanStatus.REJECTED:
      columns.discount = false
      columns.paid = false
      columns.debt = false
      break
  }

  return (
    <Table striped hover size="sm" className="loan-list">
      <LoanListHeader status={status} columns={columns} />
      <LoanListBody loans={loans} status={status} columns={columns} />
    </Table>
  )
}

export default LoanList
