import React from 'react'
import { Badge } from 'reactstrap'

interface Props {
  customer: any,
}

export const customerClassificatorOptions = [
  { value: 211, color: 'danger' },
  { value: 212, color: 'danger' },
  { value: 222, color: 'orange' },
  { value: 230, color: 'warning' },
  { value: 240, color: 'info' },
  { value: 241, color: 'info' },
  { value: 245, color: 'info' },
  { value: 246, color: 'info' },
  { value: 247, color: 'info' },
  { value: 249, color: 'info' },
  { value: 251, color: 'success' },
  { value: 252, color: 'success' },
  { value: 253, color: 'success' },
  { value: 254, color: 'success' },
];

const CustomerRatingBadge: React.FC<Props> = ({ customer }) => {
  const classificatorOption = customerClassificatorOptions.find(o => o.value === customer.classificator);

  if (!customer.classificator || !classificatorOption) return null

  return (
    <Badge
      color={classificatorOption.color}
      style={{ padding: '.125rem .25rem' }}
    >
      {customer.classificator}
    </Badge>
  )
}

export default CustomerRatingBadge
