import React from 'react'

interface Props {
  columns: {[key:string]: boolean},
}

const LoanScheduleHeader: React.FC<Props> = ({ columns }) => (
  <>
    <thead className="thead-dark">
      <tr>
        {columns.index &&
          <th scope="col" className="text-right">#</th>}
        {columns.deadline &&
          <th scope="col" className="text-center">Terminas</th>}
        {columns.daysUntillDeadline &&
          <th scope="col" className="text-center">Liko d.</th>}
        {columns.dayOfPayment &&
          <th scope="col" className="text-center">Įmokos diena</th>}
        {columns.installmentAmount &&
          <th scope="col" className="text-right">Įmoka<br /><small>(kūnas&#8202;/&#8202;palūkanos)</small></th>}
        {columns.discount &&
          <th scope="col" className="text-right">Nuolaida<br /><small>(kūnas&#8202;/&#8202;palūkanos)</small></th>}
        {columns.paidAmount &&
          <th scope="col" className="text-right">Apmokėta<br /><small>(kūnas&#8202;/&#8202;palūkanos)</small></th>}
        {columns.payableAmount &&
          <th scope="col" className="text-right">Mokėtina<br /><small>(kūnas&#8202;/&#8202;palūkanos)</small></th>}
        {columns.datePaid &&
          <th scope="col" className="text-center">Apmokėjimo<br />data</th>}
        {columns.actions &&
          <th scope="col px-0" />}
      </tr>
    </thead>
  </>
)

export default LoanScheduleHeader
