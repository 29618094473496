import React from 'react'
import { InfoCircleFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import useLoanModal from './useLoanModal';

interface Props {
  children?: any,
  className?: any,
  loanId: string,
}

const LoanLink: React.FC<Props> = ({ children, className, loanId}) => {
  const { displayLoanModal } = useLoanModal()
  
  return (
    <Link
      className={className}
      to={`/loans/view/${loanId}`}
      onClick={(e) => {
        e.preventDefault()
        displayLoanModal(loanId)
      }}
    >
      {children
        ? children
        : <InfoCircleFill size={15} />
      }
    </Link>
  )
}

export default LoanLink
