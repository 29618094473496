import React from 'react'
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom'
import { Container } from 'reactstrap'

import FilteredLoanList from 'components/loans/FilteredLoanList'
import LoanDetails from 'components/loans/LoanDetails'

const Loans: React.FC<RouteComponentProps<{id: string}>> = ({ match }) => (
  <Container fluid>
    <Switch>
      <Route path={[`${match.path}/list/:page`, `${match.path}/list`]}>
        <FilteredLoanList withFilter />
      </Route>

      <Route path={`${match.path}/view/:id`}>
        <Container>
          <LoanDetails id={match.params.id} />
        </Container>
      </Route>
      
      <Redirect to="/dashboard" />
    </Switch>
  </Container>
)

export default Loans