import React from 'react'
import { Table } from 'reactstrap'

import MessageLogHeader from './components/MessageLogHeader'
import MessageLogBody from './components/MessageLogBody'

interface Props {
  messages: any[],
  columns?: {[key:string]: boolean},
}

const MessageLog: React.FC<Props> = ({ messages, columns }) => {
  columns = {
    loan: true,
    recipient: true,
    description: true,
    content: true,
    ...columns,
  }

  return (
    <Table striped hover size="sm">
      <MessageLogHeader columns={columns} />
      <MessageLogBody
        columns={columns}
        messages={messages}
      />
    </Table>
  )
}

export default MessageLog
