import React from 'react'
import { Form, FormGroup, Label, Input } from 'reactstrap'
import DatePicker from 'react-datepicker'

interface FilterProps {
  dateAfter: any,
  dateBefore: any,
}

interface Props {
  filters: FilterProps,
  updateFilters: (filters: FilterProps) => void,
}

const ActivityListFilter: React.FC<Props> = ({ filters, updateFilters }) => (
  <Form
    onSubmit={e => e.preventDefault()}
    className="pb-2 justify-content-end"
    autoComplete="off"
    inline
  >
    <FormGroup className="mr-2">
      <Label for="date-from" className="mx-1">Atlikta nuo:</Label>
      <DatePicker
        dateFormat="yyyy-MM-dd"
        selected={filters.dateAfter}
        onChange={(value: Date) => updateFilters({...filters, dateAfter: value})}
        customInput={(
          <Input
            id="date-from" name="date-from"
            type="text"
            bsSize="sm"
          />
        )}
      />
    </FormGroup>
    
    <FormGroup>
      <Label for="date-to" className="mr-1">iki:</Label>
      <DatePicker
        dateFormat="yyyy-MM-dd"
        selected={filters.dateBefore}
        onChange={(value: Date) => updateFilters({...filters, dateBefore: value})}
        customInput={(
          <Input
            id="date-to" name="date-to"
            type="text"
            bsSize="sm"
          />
        )}
      />
    </FormGroup>
  </Form>
)

export default ActivityListFilter