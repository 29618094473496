import React, { useEffect, useState } from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import { useQuery, NetworkStatus } from '@apollo/client'
import { format } from 'date-fns'

import history from 'shared/config/history'
import Pagination from 'shared/components/pagination'

import { GET_ACTIVITIES } from '../queries'
import ActivityList from '../ActivityList'
import ActivityListFilter from './components/ActivityListFilter'
import LoadableContent from 'shared/components/LoadableContent'

export interface ActivityListFilterProps {
  dateAfter: any,
  dateBefore: any,
}

interface Props {
  withFilter?: boolean,
}

const defaultFilterProps = {
  dateAfter: undefined,
  dateBefore: undefined,
}

const FilteredActivityList: React.FC<Props> = ({ withFilter }) => {
  const match = useRouteMatch<any>()
  const { page } = useParams<any>()

  const [ filters, setFilters ] = useState<ActivityListFilterProps>(defaultFilterProps)
  const [ activities, setActivities ] = useState<any>()
  
  const { data, loading, refetch, networkStatus } = useQuery(GET_ACTIVITIES, {
    variables: {
      dateAfter: filters.dateAfter && format(filters.dateAfter, 'yyyy-MM-dd'),
      dateBefore: filters.dateBefore && format(filters.dateBefore, 'yyyy-MM-dd'),
    },
    notifyOnNetworkStatusChange: true,
    pollInterval: 10 * 1000,
  })
  
  useEffect(() => {
    if (JSON.stringify(filters) === JSON.stringify(defaultFilterProps)) return
    if (page && page === '1') return
    
    history.push(
      match?.params?.page
        ? match.path.replace(':page', '1')
        : match.path + '/1'
    )
    
    if (networkStatus === NetworkStatus.ready) refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  useEffect(() => {
    if (data?.activities) setActivities(data.activities)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  
  return (
    <LoadableContent ns={networkStatus} loading={loading}>
      {withFilter && <ActivityListFilter filters={filters} updateFilters={setFilters} />}
      {activities && (activities.paginatorInfo?.count
        &&  <>
              <ActivityList activities={activities.data} />
              <Pagination paginator={activities.paginatorInfo} urlBased />
            </>
        ||  <h2 className="py-5 text-center">Įrašų nėra</h2>)}
    </LoadableContent>
  )
}

export default FilteredActivityList