import React from 'react'

interface Props {
  columns: {[key:string]: boolean},
}

const ActivityListHeader: React.FC<Props> = ({ columns }) => (
  <thead className="thead-dark">
    <tr>
      {columns.performedAt && <th scope="col" className="text-center">Laikas</th>}
      {columns.action && <th scope="col" className="text-center">Veiksmas</th>}
      {columns.subject && <th scope="col" className="text-center">Subjektas</th>}
      {columns.causer && <th scope="col" className="text-center">Atliko</th>}
    </tr>
  </thead>
)

export default ActivityListHeader
