import React from 'react'
import { Route, Switch, Redirect, NavLink as Link, RouteComponentProps } from 'react-router-dom'
import { Container, Nav, Navbar, NavItem, NavLink } from 'reactstrap'

import General from 'components/settings/General/index'
import DocumentTemplates from 'components/settings/DocumentTemplates/index'
import MessageTemplates from 'components/settings/MessageTemplates'

const Settings: React.FC<RouteComponentProps<{id: string}>> = ({ match }) => (<>
  <Navbar color="dark" dark expand className="justify-content-center mt-n2 mb-2">
    <Nav navbar>
      <NavItem>
        <NavLink tag={Link} replace activeClassName="active" to={`loans`}>
          Bendri nustatymai
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={Link} replace activeClassName="active" to={`document-templates`}>
          Dokumentai
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={Link} replace activeClassName="active" to={`message-templates`}>
          Pranešimai
        </NavLink>
      </NavItem>
    </Nav>
  </Navbar>

  <Container>      
    <Switch>
      <Route exact path={`${match.path}/loans`} component={General} />
      <Route exact path={`${match.path}/document-templates`} component={DocumentTemplates} />
      <Route exact path={`${match.path}/message-templates`} component={MessageTemplates} />
      
      <Redirect to="/dashboard" />
    </Switch>
  </Container>
</>)

export default Settings
