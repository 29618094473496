import React from 'react'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'

import CustomerDetails from './CustomerDetails'
import { useHistory } from 'react-router-dom'

interface Props {
  id: any,
  toggle: () => void,
}

const CustomerDetailsModal: React.FC<Props> = ({ id, toggle }) => {
  const history = useHistory()

  const onToggle = () => {
    toggle()
    history.goBack()
  }

  return (
    <Modal isOpen={!!id} toggle={onToggle} size="lg">
      <ModalBody className="p-0">
        <CustomerDetails id={id} />
      </ModalBody>
      <ModalFooter />
    </Modal>
  )
}

export default CustomerDetailsModal
