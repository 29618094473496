import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { Table } from 'reactstrap'
import LoadableContent from 'shared/components/LoadableContent'
import { MessageTemplateCategoryType } from 'shared/models/MessageLog/enums'
import { cleanUpMessage, getTemplateCategoryNicename } from 'shared/models/MessageLog/helpers'

import { GET_MESSAGE_TEMPLATES } from './queries'

const MessageTemplates: React.FC = () => {
  const templateNameColWidth: string = 'col-2'
  
  const [ templates, setTemplates ] = useState<any[]>([])
  const [ templateCategories, setTemplateCategories ] = useState<any[]>([])

  const { data, loading, networkStatus } = useQuery(GET_MESSAGE_TEMPLATES, {
    notifyOnNetworkStatusChange: true,
  })
  
  useEffect(() => {
    data?.messageTemplates && setTemplates(data.messageTemplates)

    const categories = data?.messageTemplates
      .map((t: any) => t.category)
      .filter((c: MessageTemplateCategoryType, i: number, s: MessageTemplateCategoryType[]) => s.indexOf(c) === i)
    
    setTemplateCategories(categories)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <LoadableContent ns={networkStatus} loading={loading}>
      {data && templateCategories?.length > 0 && templates?.length > 0 &&
        templateCategories.map((templateCategory: MessageTemplateCategoryType) => (
          <div className="mb-4" key={templateCategory}>
            <h3>{getTemplateCategoryNicename(templateCategory)}</h3>
            <Table>
              <thead className="thead-dark">
                <tr className="d-flex">
                  <th className={templateNameColWidth} scope="col">Šablonas</th>
                  <th className="col" scope="col">SMS</th>
                  <th className="col" scope="col">El. paštas</th>
                </tr>
              </thead>
              <tbody>
                {templates
                  .filter((t: any) => t.category === templateCategory)
                  .map((template: any) => (
                    <tr className="d-flex" key={template.id}>
                      <td className={templateNameColWidth}>{template.description}</td>
                      <td className="col">
                        {/* {template.sms_enabled} */}
                        {template.sms_content}
                      </td>
                      <td className="col">
                        {/* {template.email_enabled} */}
                        <div className="mb-1 font-weight-bold">{template.email_subject}</div>
                        {cleanUpMessage(template.email_content)}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </div>
        ))}
    </LoadableContent>
  )
}

export default MessageTemplates