import React from 'react'
import { Row, Col, UncontrolledTooltip } from 'reactstrap'
import { InfoCircle } from 'react-bootstrap-icons'

import { FormatDate } from 'shared/components/FormatDate'
import CopyToClipboard from 'shared/components/CopyToClipboard'
import { isLoanActive, isLoanClosed, isLoanRejected } from 'shared/models/Loan/helpers'
import CurrencyDisplay from 'shared/components/CurrencyDisplay'

interface IProps {
  loan: any,
}

const LoanMetadata: React.FC<IProps> = ({ loan }) => (
  <Row className="pt-2 pb-3">
    <Col xs={12} lg={6}>
      <Row className="mb-1">
        <Col className="font-weight-bold text-right">
          <CopyToClipboard data={loan.agreement_no}>Sutarties nr.:</CopyToClipboard>
        </Col>
        <Col className="text-left">{loan.agreement_no}</Col>
      </Row>
      {isLoanActive(loan) && 
        <Row className="mb-1">
          <Col className="font-weight-bold text-right">
            <CopyToClipboard data={loan.early_repayment/100}>Išankst. grąžinimas:</CopyToClipboard>
          </Col>
          <Col className="text-left">
            {loan.early_repayment > 0
              ? <CurrencyDisplay value={loan.early_repayment} suffix />
              : 'negalimas'}
          </Col>
        </Row>}
    </Col>

    <Col xs={12} lg={6}>
      <Row className="mb-1">
        <Col className="font-weight-bold text-right">Paraiška pateikta:</Col>
        <Col className="text-left">
          <FormatDate format="yyyy-MM-dd HH:mm" date={loan.requested_at} />
        </Col>
      </Row>

      <Row className="mb-1">
        <Col className="font-weight-bold text-right">Paskirtis:</Col>
        <Col className="text-left">{loan.purpose}</Col>
      </Row>

      {isLoanRejected(loan) && (
        <Row className="mb-1">
          <Col className="font-weight-bold text-right text-danger">Paraiška atmesta:</Col>
          <Col className="text-left">
            <FormatDate format="yyyy-MM-dd HH:mm" date={loan.rejected_at} />
            <span className="align-text-bottom">
              <InfoCircle size="12" className="text-info ml-1" id={'rejected_at_tooltip-' + loan.id} />
            </span>
            <UncontrolledTooltip target={'rejected_at_tooltip-' + loan.id}>
              <b>Atliko:</b> {loan.rejected_by.first_name + ' ' + loan.rejected_by.last_name}
              {loan.rejected_because && <div>
                <b>Komentaras:</b> {loan.rejected_because}
              </div>}
            </UncontrolledTooltip>
          </Col>
        </Row>)}

      {loan.issued_at && (
        <Row className="mb-1">
          <Col className="font-weight-bold text-right text-success">Paraiška patvirtinta:</Col>
          <Col className="text-left">
            <FormatDate format="yyyy-MM-dd HH:mm" date={loan.issued_at} />
            <span className="align-text-bottom">
              <InfoCircle size="12" className="text-info ml-1" id={'issued_at_tooltip-' + loan.id} />
            </span>
            <UncontrolledTooltip target={'issued_at_tooltip-' + loan.id}>
              <b>Atliko:</b> {loan.confirmed_by.first_name + ' ' + loan.confirmed_by.last_name}
            </UncontrolledTooltip>
          </Col>
        </Row>)}

      {isLoanClosed(loan) && (
        <Row className="mb-1">
          <Col className="font-weight-bold text-right text-danger">Sutartis uždaryta:</Col>
          <Col className="text-left">
            <FormatDate format="yyyy-MM-dd HH:mm" date={loan.closed_at} />
            <span className="align-text-bottom">
              <InfoCircle size="12" className="text-info ml-1" id={'closed_at_tooltip-' + loan.id} />
            </span>
            <UncontrolledTooltip target={'closed_at_tooltip-' + loan.id}>
              <b>Atliko:</b> {loan.closed_by.first_name + ' ' + loan.closed_by.last_name}
              {loan.closed_because && <div>
                <b>Komentaras:</b> {loan.closed_because}
              </div>}
            </UncontrolledTooltip>
          </Col>
        </Row>)}
    </Col>
  </Row>
)


export default LoanMetadata