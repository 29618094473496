export type InstallmentRepaymentStatusType = 'CLOSED' | 'APPROACHING' | 'PENDING' | 'LATE' | 'OVERDUE'

type InstallmentRepaymentStatusEnum = {
  [key: string]: InstallmentRepaymentStatusType
}

export const InstallmentRepaymentStatus: InstallmentRepaymentStatusEnum = {
  CLOSED      : 'CLOSED',
  PENDING     : 'PENDING',
  APPROACHING : 'APPROACHING',
  LATE        : 'LATE',
  OVERDUE     : 'OVERDUE',
}