import { useLazyQuery } from "@apollo/client"
import React, { useEffect, useState } from "react"
import { Download } from "react-bootstrap-icons"
import { Button, Spinner } from "reactstrap"

import { GET_ATTACHMENT_DOWNLOAD_LINK } from "./queries"

interface Props {
  id: string,
  label?: string,
}

const DownloadAttachment: React.FC<Props> = ({ id, label }) => {
  const [ url, setUrl ] = useState<string>()

  const [ load, { loading, data } ] = useLazyQuery(GET_ATTACHMENT_DOWNLOAD_LINK, {
    variables: { id }
  })

  const handleClick = () => {
    if (!data || !url)
      load()
    else
      window.open(url)
  }

  useEffect(() => {
    if (!data) return
    setUrl(data.downloadAttachment.url)
  }, [data])

  return (
      <Button
        color="inline btn-link"
        size="sm"
        onClick={() => handleClick()}
      >
        {!data && !loading &&
          (label || <span>Dokumentų paketas</span>)}
        {loading && <span>Ruošiama... <Spinner size="sm" color="success" /></span>}
        {data && ' ' && <span>Atsisiųsti <Download size="18" /></span>}
      </Button>
    )
}

export default DownloadAttachment