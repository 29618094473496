import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { GET_USERS } from '../queries'
import UserList from '../user-list'
import Pagination from 'shared/components/pagination';

const AllUsers: React.FC = () => {
  const { page } = useParams()
  const { data, loading, error, refetch, fetchMore, networkStatus } = useQuery(GET_USERS, {
    variables: { page },
    notifyOnNetworkStatusChange: true,
  })
  
  useEffect(() => {
    if (!loading) {
      fetchMore({
        variables: { page },
        updateQuery: (prev, { fetchMoreResult }) => fetchMoreResult || prev,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (!loading) refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  if (loading) return <p>Loading...</p>
  if (error) return <p>ERROR</p>
  if (!data.users.data || !data.users.paginatorInfo.count) return <p className="lead">Vartotojų nėra</p>
  
  return (
    <>
      {networkStatus === 4 && 'Refetching!'}
      <UserList users={data.users.data} />
      <Pagination paginator={data.users.paginatorInfo} urlBased />
    </>
  )
}

export default AllUsers
