import React from 'react'
import { Container } from 'reactstrap'

import PendingLoanApplications from 'components/loans/PendingLoanApplications'
import RecentlyRegisteredCustomers from 'components/customers/RecentlyRegisteredCustomers'

const Dashboard: React.FC = () => (
  <Container fluid className="page-dashboard">
    <PendingLoanApplications />
    <RecentlyRegisteredCustomers />
  </Container>
)

export default Dashboard