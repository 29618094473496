import React, { useEffect, useState } from 'react'
import { useMutation, useLazyQuery } from '@apollo/client'
import { Alert, Label, FormGroup, Input, Row, Col } from 'reactstrap'
import toaster from 'toasted-notes'

import LoadableContent from 'shared/components/LoadableContent'
import { getLoanStatus } from 'shared/models/Loan/helpers'
import { LoanStatus } from 'shared/models/Loan/enums'
import ButtonWithConfirmation from 'shared/components/ButtonWithConfirmation'

import { PROPOSE_LOAN_APPLICATION } from '../../queries'
import { GET_USER } from 'components/customers/queries'

interface Props {
  loan: any,
}

const RejectedApplication: React.FC<Props> = ({ loan }) => {
  const [ canProposeApplication, setCanProposeApplication ] = useState<boolean>(false)

  const [ proposedPrincipal, setProposedPrincipal ] = useState<number>(500)
  const [ proposedPeriods, setProposedPeriods ] = useState<number>(12)
  const [ proposedDelayPeriods, setProposedDelayPeriods ] = useState<number>(1)
  const [ proposedApr, setProposedApr ] = useState<number>(16)

  const [ checkProposalPossibility, { loading, networkStatus } ] = useLazyQuery(GET_USER, {
    variables: { id: loan.borrower.id },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data: any ) => {
      const customer = data.user?.customer;
      if (!customer) return

      setCanProposeApplication(
        customer.activeLoanCount === 0 &&
        customer.pendingLoanApplicationCount === 0
      )
    }
  })

  const [ propose, { loading: proposeInProgress } ] = useMutation(PROPOSE_LOAN_APPLICATION, {
    variables: {
      loanId: loan.id,
      principal: proposedPrincipal,
      delayPeriods: proposedDelayPeriods,
      periods: proposedPeriods,
      apr: proposedApr,
    }
  })

  const proposeApplication = async () => {
    await propose()
    setCanProposeApplication(false)
    toaster.notify('Paskolos pasiūlymas pateiktas.', { duration: 5000 })
  }

  useEffect(() => {
    if (!loan || getLoanStatus(loan) !== LoanStatus.REJECTED) return
    checkProposalPossibility()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loan])

  return (
    <LoadableContent ns={networkStatus} loading={loading}>
      {canProposeApplication &&
        <Alert color={'warning'} className="w-100">
          <div className="display-4 mb-4 text-center">Pasiūlymo pateikimas</div>
          <Row>
            <Col md={12}>
              <FormGroup row className="mb-2">
                <Col>
                  <Label for="proposedPrincipal" className="d-block mb-1">Paskola (&euro;):</Label>
                  <Input
                    type="number"
                    id="proposedPrincipal" name="proposedPrincipal"
                    value={proposedPrincipal}
                    onChange={e => setProposedPrincipal(parseInt(e.target.value))} />
                </Col>
                <Col>
                  <Label for="proposedPeriods" className="d-block mb-1">Trukmė (mėn.):</Label>
                  <Input
                    type="number"
                    id="proposedPeriods" name="proposedPeriods"
                    value={proposedPeriods}
                    onChange={e => setProposedPeriods(parseInt(e.target.value))} />
                </Col>
                <Col>
                  <Label for="proposedDelayPeriods" className="d-block mb-1">Atidėjimas (mėn.):</Label>
                  <Input
                    type="number"
                    id="proposedDelayPeriods" name="proposedDelayPeriods"
                    min="1"
                    max="3"
                    step="1"
                    value={proposedDelayPeriods}
                    onChange={e => setProposedDelayPeriods(parseInt(e.target.value))} />
                </Col>
                <Col>
                  <Label className="d-block mb-1">Grafikas:</Label>
                  <Input
                    type="text"
                    value="Anuitetas"
                    disabled />
                </Col>
                <Col>
                  <Label className="d-block mb-1">MKGN (%):</Label>
                  <Input
                    type="number"
                    id="proposedApr" name="proposedApr"
                    value={proposedApr}
                    onChange={e => setProposedApr(parseInt(e.target.value))} />
                </Col>
              </FormGroup>

              <FormGroup className="text-center mt-3">
                <ButtonWithConfirmation
                  color="success"
                  size="sm"
                  outline
                  buttonLabel="Pateikti pasiūlymą"
                  confirmationTooltip="Tikrai norite pateikti pasiūlymą?"
                  handleClick={proposeApplication}
                  displayLoader={proposeInProgress}
                />
              </FormGroup>
            </Col>
          </Row>
        </Alert>}
    </LoadableContent>
  )
}

export { RejectedApplication }