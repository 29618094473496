const parseCurrencyInput = (input: string|number|undefined): number => {
  const amount = String(input).replace(',', '.')

  if (isNaN(Number(amount))) {
    return 0
  }

  return Math.trunc(parseFloat(amount) * 100)
}

const normalizeCurrencyDecimalSeparator = (input: string|number|undefined): string => {
  const amount = String(input).replace(',', '.')

  if (isNaN(Number(amount))) {
    return "0"
  }

  return amount
}

export {
  parseCurrencyInput,
  normalizeCurrencyDecimalSeparator
}