import React from 'react'
import { Container } from 'reactstrap'
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom'

import FilteredUserPaymentList from 'components/user-payments/FilteredUserPaymentList'
import UserPaymentInput from 'components/user-payments/UserPaymentInput'

const UserPayments: React.FC<RouteComponentProps<{id: string}>> = ({ match }) => (
  <Container fluid>
    <Switch>
      <Route exact path={`${match.path}/create`} component={UserPaymentInput} />
      <Route exact path={[`${match.path}/list`, `${match.path}/list/:page`]}>
        <FilteredUserPaymentList withFilter />
      </Route>
      
      <Redirect to="/dashboard" />
    </Switch>
  </Container>
)

export default UserPayments
