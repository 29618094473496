import { gql } from '@apollo/client'
import { LOAN_PARAMETERS_FRAGMENT, LOAN_INSTALLMENTS_FRAGMENT, LOAN_USER_PAYMENTS_FRAGMENT } from 'shared/models/Loan/fragments'
import { USER_PAYMENT_BASE_FIELDS_FRAGMENT, USER_PAYMENT_EXTENDED_FIELDS_FRAGMENT } from 'shared/models/UserPayment/fragments'

const PAGINATOR = gql`
  fragment userPaymentPaginator on UserPaymentPaginator {
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      perPage
      total
    }
  }
`

const GET_USER_PAYMENT = gql`
  query GetUserPayment($id: ID!) {
    userPayment(id: $id) {
      id
      ...userPaymentBaseFields
      ...userPaymentExtendedFields
    }
  }
  ${USER_PAYMENT_BASE_FIELDS_FRAGMENT}
  ${USER_PAYMENT_EXTENDED_FIELDS_FRAGMENT}
`

const GET_USER_PAYMENTS = gql`
  query GetUserPayments(
    $type: UserPaymentType = ANY,
    $first: Int = 30,
    $dateAfter: Date,
    $dateBefore: Date
  ) {
    userPayments(
      type: $type,
      first: $first,
      dateAfter: $dateAfter,
      dateBefore: $dateBefore,
    ) {
      ...userPaymentPaginator
      data {
        id
        ...userPaymentBaseFields
        ...userPaymentExtendedFields
      }
    }
  }
  ${PAGINATOR}
  ${USER_PAYMENT_BASE_FIELDS_FRAGMENT}
  ${USER_PAYMENT_EXTENDED_FIELDS_FRAGMENT}
`

const SAVE_USER_PAYMENT = gql`
  mutation SaveUserPayment(
    $purpose: String!,
    $type: UserPaymentType!,
    $amount: String!,
    $paidAt: DateTime!,
    $loan: ID!,
    $force: Boolean
  ) {
    saveUserPayment(
      purpose: $purpose,
      type: $type,
      amount: $amount,
      paid_at: $paidAt,
      loan: $loan,
      force: $force
    ) {
      id
      ...loanParameters
      ...loanInstallments
      ...loanUserPayments
    }
  }
  ${LOAN_PARAMETERS_FRAGMENT}
  ${LOAN_INSTALLMENTS_FRAGMENT}
  ${LOAN_USER_PAYMENTS_FRAGMENT}
`

const EDIT_USER_PAYMENT = gql`
  mutation EditUserPayment(
    $id: ID!,
    $data: EditUserPaymentInput!
  ) {
    editUserPayment(
      id: $id,
      data: $data
    ) {
      id
      ...loanParameters
      ...loanInstallments
      ...loanUserPayments
    }
  }
  ${LOAN_PARAMETERS_FRAGMENT}
  ${LOAN_INSTALLMENTS_FRAGMENT}
  ${LOAN_USER_PAYMENTS_FRAGMENT}
`

const DELETE_USER_PAYMENT = gql`
  mutation DeleteUserPayment($id: ID!) {
    deleteUserPayment(id: $id) {
      id
      ...loanParameters
      ...loanInstallments
      ...loanUserPayments
    }
  }
  ${LOAN_PARAMETERS_FRAGMENT}
  ${LOAN_INSTALLMENTS_FRAGMENT}
  ${LOAN_USER_PAYMENTS_FRAGMENT}
`

export {
  GET_USER_PAYMENT,
  GET_USER_PAYMENTS,
  SAVE_USER_PAYMENT,
  EDIT_USER_PAYMENT,
  DELETE_USER_PAYMENT,
}
