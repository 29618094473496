import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Alert, Label, FormGroup, Input, Row, Col } from 'reactstrap'
import toaster from 'toasted-notes'

import { getLoanStatus } from 'shared/models/Loan/helpers'
import { LoanStatus } from 'shared/models/Loan/enums'
import ButtonWithConfirmation from 'shared/components/ButtonWithConfirmation'

import {
  AUTHORIZE_LOAN_APPLICATION,
  REJECT_LOAN_APPLICATION
} from '../../queries'
import { GET_OPTION } from 'shared/models/Options/queries'
import LoadableContent from 'shared/components/LoadableContent'

interface Props {
  loan: any,
}

const UnauthorizedApplication: React.FC<Props> = ({ loan }) => {
  const [ reasonClassificator, setReasonClassificator ] = useState<any[]>()
  const [ loanCategory, setLoanCategory ] = useState<string>('A')
  const [ rejectedBecause, setRejectedBecause ] = useState<string>('')

  const { loading, networkStatus } = useQuery(GET_OPTION, {
    variables: { key: 'loan_rejected_reason_classificator' },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data: any ) => {
      if (!data?.option?.option_value) return
      setReasonClassificator(JSON.parse(data.option.option_value))
    }
  })

  const [ authorize, { loading: authorizeInProgress } ] = useMutation(AUTHORIZE_LOAN_APPLICATION, {
    variables: { id: loan.id, loanCategory }
  })
  const [ reject, { loading: rejectInProgress } ] = useMutation(REJECT_LOAN_APPLICATION, {
    variables: { id: loan.id, rejectedBecause }
  })

  if (getLoanStatus(loan) !== LoanStatus.UNAUTHORIZED) return null

  const authorizeApplication = async () => {
    await authorize()
    toaster.notify('Paskolos paraiška autorizuota.', { duration: 5000 })
  }

  const rejectApplication = async () => {
    await reject()
    toaster.notify('Paskolos paraiška atmesta.', { duration: 5000 })
  }

  return (
    <LoadableContent ns={networkStatus} loading={loading}>
      {reasonClassificator &&
        <Alert color={'danger'} className="w-100">
          <div className="display-4 mb-4 text-center">Paskolos autorizavimas</div>
          <Row>
            <Col md={6} className="pb-3 pb-md-0">
              <FormGroup className="text-center mb-2">
                <Label for="confirmedAt" className="d-block mb-1">Kategorija:</Label>
                <Input
                  type="select"
                  id="loanCategory" name="loanCategory"
                  bsSize="sm"
                  onChange={e => setLoanCategory(e.target.value)}
                >
                  <option key={'A'} value={'A'}>A</option>
                  <option key={'B'} value={'B'}>B</option>
                  <option key={'C'} value={'C'}>C</option>
                  <option key={'D'} value={'D'}>D</option>
                  <option key={'E'} value={'E'}>E</option>
                </Input>
              </FormGroup>
              <FormGroup row className="justify-content-center align-items-center">
                <ButtonWithConfirmation
                  color="success"
                  size="sm"
                  className="ml-2"
                  buttonLabel="Autorizuoti"
                  confirmationTooltip="Tikrai norite autorizuoti paraišką?"
                  handleClick={authorizeApplication}
                  displayLoader={authorizeInProgress}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup className="text-center mb-2">
                <Label for="rejectedBecause" className="d-block mb-1">Atmetimo priežastis:</Label>
                <Input
                    type="select"
                    id="rejectedBecause" name="rejectedBecause"
                    bsSize="sm"
                    onChange={e => setRejectedBecause(e.target.value)}>
                  <option key={'empty'}></option>
                  {Object.entries(reasonClassificator).map(([key, option]) =>
                    <option key={key} value={key}>{option}</option>
                  )}
                </Input>
              </FormGroup>
              <FormGroup row className="justify-content-center align-items-center">
                <ButtonWithConfirmation
                  color="danger"
                  size="sm"
                  outline
                  className="ml-2"
                  buttonLabel="Atmesti"
                  confirmationTooltip="Tikrai norite atmesti paraišką?"
                  handleClick={rejectApplication}
                  displayLoader={rejectInProgress}
                />
              </FormGroup>
            </Col>
          </Row>
        </Alert>}
    </LoadableContent>
  )
}

export { UnauthorizedApplication }