import React from 'react'

import { LoanStatus } from 'shared/models/Loan/enums'

interface Props {
  status: string,
  columns: {[key:string]: boolean},
}

const LoanListHeader: React.FC<Props> = ({ status, columns }) => {
  let dateColumn = null

  switch (status) {
    case LoanStatus.ANY:
      dateColumn = 'Data'
      break
    case LoanStatus.UNAUTHORIZED:
      dateColumn = 'Pateikta'
      break
    case LoanStatus.PENDING:
      dateColumn = 'Pateikta'
      break
    case LoanStatus.CLOSED:
      dateColumn = 'Uždaryta'
      break
    case LoanStatus.ACTIVE:
      dateColumn = 'Patvirtinta'
      break
    case LoanStatus.REJECTED:
      dateColumn = 'Atmesta'
      break
  }

  return (
    <thead className="thead-dark">
      <tr>
        {columns.status && <th scope="col">Būsena</th>}
        {dateColumn && <th scope="col">{dateColumn}</th>}
        {columns.customer && <th scope="col">Vardas</th>}
        {columns.loanInfo && <th scope="col">Paraiška</th>}
        <th scope="col" className="text-left">Tipas</th>
        <th scope="col" className="text-right">Trukmė</th>
        <th scope="col" className="text-right">Kūnas</th>
        <th scope="col" className="text-right">Palūkanos</th>
        <th scope="col" className="text-right">Grąžintina</th>
        {columns.discount && <th scope="col" className="text-right">Nuolaida</th>}
        {columns.paid && <th scope="col" className="text-right">Apmokėta</th>}
        {columns.debt && <th scope="col" className="text-right">Skola</th>}
        <th scope="col" className="text-right">MKGN</th>
      </tr>
    </thead>
  )
}

export default LoanListHeader
