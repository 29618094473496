import React from 'react'
import { Helmet } from 'react-helmet'
import { Container, Row, Col } from 'reactstrap'

import LoginForm from 'components/login-form'

const Login: React.FC = () => (<>
  <Helmet>
    <title>Prisijunkite</title>
  </Helmet>
  
  <Container>
    <Row className="justify-content-center">
      <Col xs="12" md="8" lg="6">
        <h1 className="text-center py-4">Prisijunkite</h1>
        <LoginForm />
      </Col>
    </Row>
  </Container>
</>)

export default Login