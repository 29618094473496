import React, { useContext } from 'react'

import { modalContext } from 'components/modals/context'
import UserPaymentModal from './UserPaymentModal'

const useUserPaymentModal = () => {
  const { pushModal, popModal } = useContext(modalContext)

  const displayUserPaymentModal = (id: any ) =>
    pushModal(
      <UserPaymentModal
        key={id}
        id={id}
        toggle={() => popModal(id)}
      />
    )

  return { displayUserPaymentModal }
}

export default useUserPaymentModal