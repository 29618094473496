import React, { useState } from 'react'
import { Table, Badge, Popover, PopoverHeader, PopoverBody } from 'reactstrap'
import { XSquare, CheckSquare, QuestionSquare, CaretRightSquare } from 'react-bootstrap-icons'

import { FormatDate } from 'shared/components/FormatDate'
import CustomerLink from 'components/customers/CustomerLink'
import CustomerScoreBadge from 'components/customers/CustomerScoreBadge'
import CustomerClassificatorBadge from 'components/customers/CustomerClassificatorBadge'

interface Props {
  customers: [any],
}

const CustomerListBody: React.FC<Props> = ({ customers }) => {
  const [ activePopover, setActivePopover ] = useState(0)

  const togglePopover = (customerId: number) => setActivePopover(customerId === activePopover ? 0 : customerId)

  return (
    <tbody>
      {customers.map((customer: any) => {
        return (
          <tr key={customer.id}>
            <td>
              <CustomerLink subject={customer} userId={customer.user.id} opensModal />
            </td>
            <td>
              <CustomerScoreBadge customer={customer} />
              {' '}
              <CustomerClassificatorBadge customer={customer} />
            </td>
            <td>{customer.user.email}</td>
            <td>{customer.residence_city}</td>
            <td>{customer.personal_id}</td>
            <td>{customer.phone_number}</td>
            <td>
              <FormatDate date={customer.user.created_at} format="yyyy-MM-dd" />
            </td>
            <td id={'loan-stats-' + customer.id}>
              {!!customer.loanCount && <>
                {!!customer.pendingLoanApplicationCount &&
                  <Badge color='warning'>
                    <QuestionSquare size={12} alignmentBaseline="baseline" /> {customer.pendingLoanApplicationCount}
                  </Badge>}
                {!!customer.rejectedLoanApplicationCount &&
                  <Badge color='danger'>
                    <XSquare size={12} alignmentBaseline="baseline" /> {customer.rejectedLoanApplicationCount}
                  </Badge>}
                {!!customer.activeLoanCount &&
                  <Badge color='success'>
                    <CaretRightSquare size={12} alignmentBaseline="baseline" /> {customer.activeLoanCount}
                  </Badge>}
                {!!customer.closedLoanCount &&
                  <Badge color='info'>
                    <CheckSquare size={12} alignmentBaseline="baseline" /> {customer.closedLoanCount}
                  </Badge>}
                
                <Popover trigger="hover" placement="auto" isOpen={activePopover === customer.id} target={'#loan-stats-' + customer.id} toggle={() => togglePopover(customer.id)}>
                  <PopoverHeader>Paraiškų statistika</PopoverHeader>
                  <PopoverBody>
                    <Table borderless size="sm" className="mb-0">
                      <tbody>
                        <tr>
                          <td>Aktyvos paraiškos:</td>
                          <td>{customer.pendingLoanApplicationCount}</td>
                        </tr>
                        <tr>
                          <td>Atmestos paraiškos:</td>
                          <td>{customer.rejectedLoanApplicationCount}</td>
                        </tr>
                        <tr>
                          <td>Aktyvūs kreditai:</td>
                          <td>{customer.activeLoanCount}</td>
                        </tr>
                        <tr>
                          <td>Uždaryti kreditai:</td>
                          <td>{customer.closedLoanCount}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </PopoverBody>
                </Popover>
              </>}
            </td>
          </tr>
        )
      })}
    </tbody>
  )
}

export default CustomerListBody
