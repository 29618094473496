import React from 'react'

interface Props {
  periods: number,
  periodsExtension?: number,
  periodsPerYear: number,
  displayPeriods?: boolean,
}

const DurationDisplay: React.FC<Props> = ({ periods, periodsExtension = 0, periodsPerYear, displayPeriods = true }) => {
  if (periods === 0)
    return <span className="text-muted">0</span>

  const periodDuration = 365 / periodsPerYear
  
  return (
    <>
      {Math.round((periods + periodsExtension) * periodDuration)}&thinsp;
      {displayPeriods && <span className="text-muted">({!!periodsExtension && <span>{periodsExtension}&#8202;+&#8202;</span>}{periods})</span>}
    </>
  )
}

export default DurationDisplay
