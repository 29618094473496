import React from 'react'
import { Link } from 'react-router-dom';
import useCustomerModal from './useCustomerModal';

interface Props {
  children?: any,
  className?: string,
  subject: any,
  userId?: string,
  opensModal?: boolean,
}

const CustomerLink: React.FC<Props> = ({ children, className, subject, userId, opensModal }) => {
  const { displayCustomerModal } = useCustomerModal()
  
  const id = userId ? userId : subject.id
  const fullname = subject.first_name + ' ' + subject.last_name

  return opensModal
    ?   <Link
          className={className}
          to={`/users/customers/view/${id}`}
          onClick={(e) => {
            e.preventDefault()
            displayCustomerModal(id)
          }}
        >
          {children
            ? children
            : fullname
          }
        </Link>
    :   <span>{fullname}</span>
}

export default CustomerLink
