import { gql } from '@apollo/client'

const USER_PAYMENT_BASE_FIELDS_FRAGMENT = gql`
  fragment userPaymentBaseFields on UserPayment {
    type
    purpose
    amount

    created_at
    created_by {
      id
      first_name
      last_name
    }

    paid_at
    paid_by {
      id
      first_name
      last_name
    }
  }
`

const USER_PAYMENT_EXTENDED_FIELDS_FRAGMENT = gql`
  fragment userPaymentExtendedFields on UserPayment {
    loan {
      id
      agreement_no
      borrower {
        id
        first_name
        last_name
      }
    }
  }
`

export {
  USER_PAYMENT_BASE_FIELDS_FRAGMENT,
  USER_PAYMENT_EXTENDED_FIELDS_FRAGMENT,
}