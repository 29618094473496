import { gql } from '@apollo/client'

const ATTACHMENTS_PAGINATOR = gql`
  fragment attachmentsPaginator on AttachmentPaginator {
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      perPage
      total
    }
  }
`

const ATTACHMENT_DETAILS_FRAGMENT = gql`
  fragment attachmentDetails on Attachment {
    description
    file_name
    file_path
    description

    created_at
    created_by {
      id
      first_name
      last_name
    }

    subject {
      ... on Loan {
        id
      }

      ... on User {
        id
        first_name
        last_name
      }
    }
  }
`

export {
  ATTACHMENTS_PAGINATOR,
  ATTACHMENT_DETAILS_FRAGMENT,
}