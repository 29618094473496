import { gql } from '@apollo/client'

const GET_OPTION = gql`
  query GetOption($key: ID!) {
    option(option_key: $key) {
      option_key
      option_type
      option_value
    }
  }
`

const GET_OPTIONS = gql`
  query GetOptions {
    options {
      option_key
      option_type
      option_value
    }
  }
`

const UPDATE_OPTION = gql`
  mutation UpdateOption(
    $key: String!,
    $value: String!,
  ) {
    updateOption(
      key: $key,
      value: $value
    ) {
      option_key
      option_type
      option_value
    }
  }
`

export {
  GET_OPTION,
  GET_OPTIONS,
  UPDATE_OPTION
}