import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Alert, Button, Col, Form, FormFeedback, FormGroup, Input, Row, Table } from 'reactstrap'

import { FormatDateSplit } from 'shared/components/FormatDate'

import { GET_USER_SODRA_DATA, QUERY_USER_SODRA_DATA } from '../../queries'
import LoadableContent from 'shared/components/LoadableContent'
import { useForm } from 'react-hook-form'
import ReactJson from 'react-json-view'

interface Props {
  id: any,
  customer: any,
  compact?: boolean,
  includeTrashed?: boolean,
}

const SodraData: React.FC<Props> = ({ id, customer, compact = false, includeTrashed = false }) => {
  const [ sodraData, setSodraData ] = useState([])

  const { data, loading, networkStatus } = useQuery(GET_USER_SODRA_DATA, {
    variables: {
      id,
      trashed: includeTrashed ? 'WITH' : 'WITHOUT',
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })
  
  const { register, handleSubmit, errors, formState } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: {
      firstName: customer.first_name,
      lastName: customer.last_name,
      personalId: customer.personal_id,
    }
  })
  
  const [ querySodraData, {
    loading: queryingSodra, error: sodraQueryError,
  } ] = useMutation(QUERY_USER_SODRA_DATA, {
    errorPolicy: 'all',
  })

  const onSubmit = async (data: any) => {
    try {      
      querySodraData({variables: {
        user: id,
        firstName: data.firstName,
        lastName: data.lastName,
      }})
    } catch (e) {
      // ...
    }
  }

  useEffect(() => {
    data?.getSodraData?.sodraData && setSodraData(data.getSodraData.sodraData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <LoadableContent ns={networkStatus} loading={!compact && loading}>
      {!compact &&
        <div className="pb-1 mb-3 border-bottom justify-content-center">
          <Form
            onSubmit={handleSubmit(onSubmit)}
            className="py-4"
            autoComplete="off"
          >
            <Row form>
              <Col>
                <FormGroup>
                  <Input
                    id="firstName" name="firstName"
                    className={errors.firstName ? 'is-invalid':''}
                    type="text"
                    bsSize="sm"
                    innerRef={register({ required: true })}
                  />
                  <FormFeedback valid={!(errors.firstName?.type === 'required')}>Įveskite vardą</FormFeedback>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Input
                    id="lastName" name="lastName"
                    className={errors.lastName ? 'is-invalid':''}
                    type="text"
                    bsSize="sm"
                    innerRef={register({ required: true })}
                  />
                  <FormFeedback valid={!(errors.lastName?.type === 'required')}>Įveskite pavardę</FormFeedback>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Input
                    id="personalId" name="personalId"
                    type="text"
                    bsSize="sm"
                    innerRef={register({ required: true })}
                    disabled
                  />
                </FormGroup>
              </Col>
        
              <Col md="auto">
                <FormGroup>
                  <div>
                    <Button
                      color="outline-primary"
                      size="sm"
                      type="submit"
                      disabled={loading || queryingSodra}
                    >Teikti užkausą</Button>
                  </div>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  {formState.isSubmitted && <>
                    {!sodraQueryError && !queryingSodra && formState.isSubmitSuccessful &&
                      <div className="text-success">Užklausos duomenys išsaugoti</div>}
                    {sodraQueryError?.graphQLErrors?.length && <div>
                      <span className="text-danger font-weight-bold mr-1">{sodraQueryError.graphQLErrors[0]?.message}</span>
                      <span className="text-muted">
                        {sodraQueryError.graphQLErrors[0]?.extensions?.reason}
                      </span>
                    </div>}</>}
                  </FormGroup>
              </Col>
            </Row>
          </Form>
        </div>}

      {data && sodraData?.length > 0 &&
        <Alert color={compact ? 'warning' : 'light'} className={compact ? '' : 'p-0'}>
          <Table className="mb-0" striped borderless>
            <tbody>
              {sodraData
                .map((note: any) => (
                  <tr
                    key={note.id}
                    className={note.is_relevant && !note.deleted_at ? '' : 'text-muted'}
                  >
                    <td>
                      <ReactJson
                        src={JSON.parse(note.content)}
                        collapsed={1}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        collapseStringsAfterLength={false}
                        enableClipboard={false}
                      />
                    </td>

                    <td className="text-center">
                      <small>{note.created_by.first_name}</small>
                      <br />
                      <FormatDateSplit date={note.created_at} />
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </Alert>}

      {!compact && data && !sodraData?.length && <div className="display-4 py-5 text-center">SoDra duomenų nėra</div>}
    </LoadableContent>
  )
}

export default SodraData