import React, { ReactElement } from "react"
import { MessageTemplateCategory, MessageTemplateCategoryType } from "./enums"

export const getTemplateCategoryNicename = (category: MessageTemplateCategoryType): string => {
  switch (category) {
    case MessageTemplateCategory.LOAN_APPLICATION:
      return 'Paskolos paraiškos'
    case MessageTemplateCategory.INSTALLMENT:
      return 'Paskolos įmokos'
    case MessageTemplateCategory.LOAN:
      return 'Paskolos'
    case MessageTemplateCategory.CUSTOMER:
      return 'Vartotojai'
    default:
      return ''
  }
}

export const cleanUpMessage = (content: string): string => {
  return content.replaceAll('<br><br>',' ').replaceAll('<br>',' ').trim()
}

export const trimMessage = (content: string, max: number = 60): ReactElement => {
  const allNiceAndClean = cleanUpMessage(content)
  
  return React.createElement('span', null, [
    allNiceAndClean.substr(0, max),
    (allNiceAndClean.length > max && '\u2026') // &hellip;
  ])
}