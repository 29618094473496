import React from 'react'
import CurrencyDisplay from 'shared/components/CurrencyDisplay'

interface IProps {
  value: number,
  currency?: boolean,
}

const InterestDisplay: React.FC<IProps> = ({ value, currency = false }) => {

  return <CurrencyDisplay value={value} decimalScale={0} suffix={currency} />
}

export default InterestDisplay
