import React from 'react'
import { Table } from 'reactstrap'

import AttachmentListHeader from './components/AttachmentListHeader'
import AttachmentListBody from './components/AttachmentListBody'

interface Props {
  attachments: any[],
  columns?: {[key:string]: boolean},
}

const AttachmentList: React.FC<Props> = ({ attachments, columns }) => {
  columns = {
    description: true,
    subject: true,
    filename: true,
    download: true,
    ...columns,
  }

  return (
    <Table striped hover size="sm">
      <AttachmentListHeader columns={columns} />
      <AttachmentListBody
        columns={columns}
        attachments={attachments}
      />
    </Table>
  )
}

export default AttachmentList
