import React, { useState, useEffect } from 'react'
import { useMutation, useLazyQuery } from '@apollo/client'
import { Modal, ModalBody, Form, FormGroup, Label, Input, Button, FormFeedback, Spinner } from 'reactstrap'
import { parse } from 'date-fns'
import { useForm } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import toaster from 'toasted-notes'

import { UserPaymentType } from 'shared/models/UserPayment/enums'
import { validatePaymentDateRange } from 'shared/models/UserPayment/helpers'
import { currencyInputPattern, datetimeInputPattern } from 'shared/config/format'
import { GET_LOAN_BY_AGREEMENT_NO } from 'components/loans/queries'
import { EDIT_USER_PAYMENT } from '../../queries'

interface Props {
  isOpen: boolean,
  payment: any,
  onAction: (action: string, payload?: any) => void,
}

const UserPaymentEditModal: React.FC<Props> = ({ isOpen, payment, onAction }) => {
  const [ loan, setLoan ] = useState<any>()
  const [ paidAt, setPaidAt ] = useState<Date|null>()

  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      type    : payment.type,
      purpose : payment.purpose,
      amount  : String(parseInt(payment.amount) / 100),
      paidAt  : payment.paid_at,
    },
  })

  const [ findLoanByAgreementNo, { data: loanResponse } ] = useLazyQuery(GET_LOAN_BY_AGREEMENT_NO, { fetchPolicy: 'no-cache' })

  const [ editPayment, {
    loading: editingPayment, data: editPaymentResponse, error: editPaymentError,
  } ] = useMutation(EDIT_USER_PAYMENT, {
    errorPolicy: 'all',
  })

  useEffect(() => {
    if (payment) {
      findLoanByAgreementNo({ variables: {agreementNo: payment.purpose}})
      setPaidAt(parse(payment.paid_at, 'yyyy-MM-dd HH:mm:ss', new Date()))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment])

  useEffect(() => {
    if (loanResponse?.loanByAgreementNo) setLoan(loanResponse.loanByAgreementNo)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanResponse])

  const onSubmit = async (data: any) => {
    try {
      editPayment({variables: {id: payment.id, data}})
        .then((response: any) => {
          if (response.errors?.length) {
            toaster.notify('Klaida redaugojant įmoką: ' + response.errors[0].message, { duration: 5000 })
          } else {
            toaster.notify('Įmoka pakoreguota, mokėjimo grafikas atnaujintas.', { duration: 5000 })
            onAction && onAction('editSuccessful', response.data.editUserPayment)
          }
        })
    } catch (e) {
      // ...
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={() => onAction('closeModal')} size="md">
      <ModalBody>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          className="py-4"
          autoComplete="off"
        >
          <FormGroup row>
            <Label
              for="type"
              className="col-form-label col-4 text-right"
              aria-required
            >Tipas</Label>
            <div className="col">
              <Input
                id="type" name="type"
                type="select"
                defaultValue={UserPaymentType.INSTALLMENT}
                bsSize="sm"
                innerRef={register({ required: true })}
              >
                <option value={UserPaymentType.INSTALLMENT}>įmoka</option>
                <option value={UserPaymentType.EARLY_REPAYMENT}>išankstinis grąžinimas</option>
                <option value={UserPaymentType.REGISTRATION}>registracija</option>
              </Input>
            </div>
            <div className="col-1"></div>
          </FormGroup>

          <FormGroup row>
            <Label
              for="purpose"
              className="col-form-label col-4 text-right"
              aria-required
            >Mokėjimo paskirtis</Label>
            <div className="col">
              <Input
                id="purpose" name="purpose"
                className={errors.purpose ? 'is-invalid':''}
                type="text"
                bsSize="sm"
                innerRef={register({
                  required: true,
                })}
              />
              <FormFeedback valid={!(errors.purpose?.type === 'required')}>Įveskite mokėjimo paskirtį</FormFeedback>
              <FormFeedback valid={!(errors.purpose?.type === 'format')}>Neteisinga mokėjimo paskirtis</FormFeedback>
              <FormFeedback valid={!(errors.purpose?.type === 'notFound')}>Paskolos pagal sutarties numerį rasti nepavyko</FormFeedback>
              <FormFeedback valid={!(errors.purpose?.type === 'loanClosed')}>Paskola uždaryta, įmokos nebepriimamos</FormFeedback>
            </div>
            <div className="col-1 px-0 align-self-center" />
          </FormGroup>

          <FormGroup row>
            <Label
              for="amount"
              className="col-form-label col-4 text-right"
              aria-required
            >Suma</Label>
            <div className="col">
              <Input
                id="amount" name="amount"
                className={errors.amount ? 'is-invalid':''}
                type="text"
                bsSize="sm"
                innerRef={register({
                  required: true,
                  pattern: currencyInputPattern,
                })}
              />
              <FormFeedback valid={!(errors.amount?.type === 'required')}>Įveskite sumą</FormFeedback>
              <FormFeedback valid={!(errors.amount?.type === 'pattern')}>Patikrinkite sumos formatą (pvz. <b>100,50</b>)</FormFeedback>
              {/* {loan && paymentAmount >= getCurrentDebt(loan) &&
                <FormText color="success">Paskutinė įmoka, paskola bus uždaryta</FormText>}
              {loan && paymentAmount > getCurrentDebt(loan) &&
                <FormText color="warning">
                  Paskutinės įmokos permoka: <CurrencyDisplay value={paymentAmount - getCurrentDebt(loan)} suffix />
                </FormText>} */}
            </div>
            <div className="col-1"></div>
          </FormGroup>

          <FormGroup row>
            <Label
              for="paidAt"
              className="col-form-label col-4 text-right"
              aria-required
            >Data</Label>
            <div className="col">
              <div className={errors.paidAt ? 'is-invalid' : ''}>
                <DatePicker
                  name="paidAt"
                  className={errors.paidAt ? 'is-invalid' : ''}
                  selected={paidAt}
                  dateFormat="yyyy-MM-dd HH:mm:ss"
                  todayButton="Šiandien"
                  onChange={(value: Date|null) => value && setPaidAt(value)}
                  wrapperClassName="w-100"
                  withPortal
                  showTimeInput
                  customInput={(
                    <Input
                      id="paidAt"
                      type="text"
                      bsSize="sm"
                      innerRef={register({
                        required: true,
                        pattern: datetimeInputPattern,
                        validate: {
                          dateRange: value => loan && validatePaymentDateRange(loan, value),
                        },
                      })}
                    />
                  )}
                />
              </div>
              <FormFeedback valid={!(errors.paidAt?.type === 'required')}>Įvesikte datą ir laiką</FormFeedback>
              <FormFeedback valid={!(errors.paidAt?.type === 'pattern')}>Patikrinkite datos ir laiko formatą (pvz. <b>2020-01-01 15:43:11</b>)</FormFeedback>
              <FormFeedback valid={!(errors.paidAt?.type === 'dateRange')}>Patikrinkite įmokos datą. Ar data vėlesnė už paraiškos patvirtinimo datą ir ne vėlesnė nei šiandiena?</FormFeedback>
            </div>
            <div className="col-1"></div>
          </FormGroup>

          <FormGroup row>
            <Label for="date" className="col-4 text-right align-self-center">
              <Spinner size="sm" color="primary" className={editingPayment ? 'visible' : 'invisible'} />
              </Label>
            <div className="col-md-auto">
              <Button
                color="outline-primary"
                type="submit"
                className="w-100 w-md-auto"
                disabled={editingPayment}
              >Išsaugoti</Button>
            </div>
            <div className="col align-self-center">
              {!editPaymentError && editPaymentResponse &&
                <div className="text-success">Įmoka pakoreguota</div>}
              {editPaymentError &&
                <div className="text-danger">Nenumatyta klaida</div>}
            </div>
          </FormGroup>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default UserPaymentEditModal