import React, { useState } from 'react'
import { Form, FormGroup, Label, Input, Button } from 'reactstrap'

import { LoanStatus } from 'shared/models/Loan/enums'
import { CustomerListFilterProps } from '../index'
import { customerClassificatorOptions } from 'components/customers/CustomerClassificatorBadge'
import { customerScoreOptions } from 'components/customers/CustomerScoreBadge'

interface Props {
  filters: CustomerListFilterProps,
  updateFilters: (filters: CustomerListFilterProps) => void,
}

const CustomerListFilter: React.FC<Props> = ({ filters, updateFilters }) => {
  const [ search, setSearchString ] = useState<string|undefined>(filters.search)

  return (
    <Form
      onSubmit={(e: any) => e.preventDefault()}
      className="pb-2 justify-content-end"
      autoComplete="off"
      inline
    >
      <FormGroup className="mr-auto">
        <Label for="search" className="mr-1">Paieška:</Label>
        <Input
          id="search" name="search"
          type="text"
          bsSize="sm"
          value={search}
          onChange={(e: any) => setSearchString(e.target.value)}
          onBlur={() => updateFilters({...filters, search})}
        />
        <Button size='sm' color='dark' className='ml-1' outline>Ieškoti</Button>
      </FormGroup>

      <FormGroup>
        <Label for="has-score" className="mr-1">Pagal klasifikaciją:</Label>
        <Input
          id="has-score" name="has-score"
          type="select"
          className="mr-2"
          bsSize="sm"
          value={filters.score}
          onChange={(e: any) => updateFilters({...filters, score: e.target.value})}
        >
          <option></option>
          {customerScoreOptions.map(score => (
            <option key={score.value} value={score.value} className={`text-${score.color}`}>{score.label}</option>)
          )}
        </Input>
        <Input
          id="has-classificator" name="has-classificator"
          type="select"
          bsSize="sm"
          value={filters.classificator}
          onChange={(e: any) => updateFilters({...filters, classificator: e.target.value})}
        >
        <option></option>
          {customerClassificatorOptions.map(classificator => (
            <option key={classificator.value} className={`text-${classificator.color}`}>{classificator.value}</option>)
          )}
        </Input>
      </FormGroup>

      <FormGroup>
        <Label for="has-loans-with-status" className="ml-3 mr-1">Pagal kredito statusą:</Label>
        <Input
          id="has-loans-with-status" name="has-loans-with-status"
          type="select"
          bsSize="sm"
          value={filters.loanStatus}
          onChange={(e: any) => updateFilters({...filters, loanStatus: e.target.value})}
        >
          <option value={LoanStatus.ANY}>visi</option>
          <option value={LoanStatus.UNAUTHORIZED}>turi neautorizuotų</option>
          <option value={LoanStatus.PENDING}>turi nepatvirtintų</option>
          <option value={LoanStatus.ACTIVE}>turi aktyvių</option>
          <option value={LoanStatus.CLOSED}>turi uždarytų</option>
          <option value={LoanStatus.REJECTED}>turi atmestų</option>
        </Input>
      </FormGroup>
    </Form>
  )
}

export default CustomerListFilter
