import React from 'react'

const UserListTableHeader: React.FC = () => (
  <>
    <thead className="thead-dark">
      <tr>
        <th scope="col">Vardas</th>
        <th scope="col">El. paštas</th>
      </tr>
    </thead>
  </>
)

export default UserListTableHeader
