import React from 'react'
import { Route, Switch, RouteComponentProps } from 'react-router-dom'

import Customers from './customers'
import Lenders from './lenders'
import System from './system'

const Users: React.FC<RouteComponentProps<{id: string}>> = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/customers`} component={Customers} />
    <Route path={`${match.path}/lenders`} component={Lenders} />
    <Route path={`${match.path}/system`} component={System} />
  </Switch>
)

export default Users
