import React from 'react'
import NumberFormat from 'react-number-format'

interface IProps {
  value: number,
}

const AprDisplay: React.FC<IProps> = ({ value }) => {

  return (
    <NumberFormat
      value={value/100}
      displayType="text"
      decimalScale={2}
      fixedDecimalScale={true}
      suffix="&#8202;%" />
  )
}

export default AprDisplay
