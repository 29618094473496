import React, { useState, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { NetworkStatus, useQuery } from '@apollo/client'

import history from 'shared/config/history'
import Pagination from 'shared/components/pagination'
import { LoanStatusType, LoanStatus } from 'shared/models/Loan/enums'
import LoadableContent from 'shared/components/LoadableContent'
import { GET_CUSTOMERS } from '../queries'
import CustomerList from '../CustomerList'
import CustomerListFilter from './components/CustomerListFilter'

export interface CustomerListFilterProps {
  search?: string,
  loanStatus: LoanStatusType,
  score?: string,
  classificator?: string,
}

interface Props {
  withFilter: boolean,
  initialStatus?: LoanStatusType,
  score?: string,
  classificator?: string,
}

const defaultFilterProps = {
  search: '',
  loanStatus: LoanStatus.ANY,
  score: undefined,
  classificator: undefined,
}

const FilteredCustomerList: React.FC<Props> = ({
  withFilter = false,
  initialStatus = LoanStatus.ANY,
}) => {
  const match = useRouteMatch<any>()

  const [ filters, setFilters ] = useState<CustomerListFilterProps>({
    ...defaultFilterProps,
    loanStatus: initialStatus,
  })

  const [ customers, setCustomers ] = useState<any>()

  const { data, loading, fetchMore, networkStatus } = useQuery(GET_CUSTOMERS, {
    variables: {
      page: parseInt(match?.params?.page) || 1,
      search: filters.search,
      hasLoansWithStatus: filters.loanStatus,
      score: (filters.score && parseInt(filters.score)) || undefined,
      classificator: (filters.classificator && parseInt(filters.classificator)) || undefined,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (data?.customers) setCustomers(data.customers)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  
  useEffect(() => {
    if (JSON.stringify(filters) === JSON.stringify(defaultFilterProps)) return
    // if (page && page === '1') return

    history.push(
      match?.params?.page
        ? match.path.replace(':page', '1')
        : match.path + '/1'
    )

    if (networkStatus === NetworkStatus.ready)
      fetchMore({ variables: {
        page: parseInt(match?.params?.page),
        search: filters.search,
        hasLoansWithStatus: filters.loanStatus,
        score: (filters.score && parseInt(filters.score)) || undefined,
        classificator: (filters.classificator && parseInt(filters.classificator)) || undefined,
      }})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  return (
    <LoadableContent ns={networkStatus} loading={loading}>
      {withFilter && <CustomerListFilter filters={filters} updateFilters={setFilters} />}
      {customers && (customers.paginatorInfo?.count
        &&  <>
              <CustomerList customers={customers.data} />
              <Pagination paginator={customers.paginatorInfo} urlBased />
            </>
        || <h2 className="py-5 text-center">Vartotojų rasti nepavyko</h2>)}
    </LoadableContent>
  )
}

export default FilteredCustomerList
