import React, { useState, useEffect } from 'react'
import { Button, Nav, NavItem, NavLink } from 'reactstrap'
import { useQuery } from '@apollo/client'

import Pagination from 'shared/components/pagination'
import QueryableContent from 'shared/components/QueryableContent'
import MessageLog from 'components/messaging/MessageLog'
import { GET_LOAN_MESSAGE_LOG } from '../../queries'

interface Props {
  id: any,
}

const Messaging: React.FC<Props> = ({ id }) => {
  const [ messages, setMessages ] = useState<any>([])
  const [ page, setPage ] = useState<number>(1)

  const query = useQuery(GET_LOAN_MESSAGE_LOG, {
    variables: { id, page },
    notifyOnNetworkStatusChange: true,
  }), { data, fetchMore } = query

  useEffect(() => {
    data?.loan?.message_log && setMessages(data.loan.message_log)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    fetchMore({variables: { page }})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  return (<>
    <div className="pb-1 mb-3 border-bottom justify-content-center">
      <Nav pills className="justify-content-end">
        <NavItem>
          <NavLink
            tag={Button} color="link" className="btn-sm text-success"
            onClick={() => null}
          >Siųsti pranešimą</NavLink>
        </NavItem>
      </Nav>
    </div>

    <QueryableContent
      query={query}
      data={messages}
      noResultsPlaceholder={<div className="display-4 py-5 text-center">Siųstų pranešimų nėra</div>}
      paginated
    >
      <MessageLog messages={messages.data} columns={{ loan: false }} />
      <Pagination paginator={messages.paginatorInfo} paginatorCallback={p => setPage(p)} />
    </QueryableContent>
  </>)
}

export default Messaging