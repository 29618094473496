import { useMutation } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Button, Form, FormFeedback, FormGroup, Input, Label, Spinner } from 'reactstrap'
import toaster from 'toasted-notes'
import { animated, useSpring } from 'react-spring'

import CurrencyDisplay from 'shared/components/CurrencyDisplay'
import { parseCurrencyInput } from 'shared/components/CurrencyHelpers'
import { currencyInputPattern } from 'shared/config/format'
import { getCurrentPayments, getRepaymentStatus, getStartingDebt } from 'shared/models/Installment/helpers'

import { EDIT_LOAN_INSTALLMENT } from '../queries'

interface Props {
  installment: any,
  columns: {[key:string]: boolean},
}

const LoanScheduleEditRow: React.FC<Props> = ({ installment, columns }) => {
  const [ init, setInit ] = useState<boolean>(false)
  const [ transferedPayments, setTransferedPayments ] = useState<number>(0)

  const fadeInOnInit = useSpring({opacity: init ? 1 : 0})

  const [ edit, {loading} ] = useMutation(EDIT_LOAN_INSTALLMENT, {
    errorPolicy: 'all',
  })

  const { register, handleSubmit, control, setValue, setError, clearErrors, errors } = useForm({
    defaultValues: {
      totalDiscount     : String((installment.principal_discount + installment.interest_discount) / 100),
      principalDiscount : String(installment.principal_discount / 100),
      interestDiscount  : String(installment.interest_discount / 100),
    },
  })

  const watcher = useWatch({
    control,
    name: ['principalDiscount', 'interestDiscount'],
    defaultValue: {
      principalDiscount : String(installment.principal_discount / 100),
      interestDiscount  : String(installment.interest_discount / 100),
    },
  })


  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setInit(true), [])

  useEffect(() => {
    const principalDiscount = parseCurrencyInput(watcher.principalDiscount)
    const interestDiscount  = parseCurrencyInput(watcher.interestDiscount)
    const totalDiscount     = principalDiscount + interestDiscount

    if (principalDiscount > installment.principal) {
      setError('principalDiscount', { type: 'outOfBounds' })
      return
    } else if (errors.principalDiscount) {
      clearErrors('principalDiscount')
    }

    if (interestDiscount > installment.interest) {
      setError('interestDiscount', { type: 'outOfBounds' })
      return
    } else if (errors.interestDiscount) {
      clearErrors('interestDiscount')
    }

    const overpay = Math.max(0, (totalDiscount) + getCurrentPayments(installment) - getStartingDebt(installment))
    setTransferedPayments(overpay)

    setValue('totalDiscount', String(totalDiscount/100))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watcher])

  const onSubmit = (data: any) => {
    try {
      edit({variables: {id: installment.id, ...data}})
        .then((response: any) => {
          if (response.errors?.length) {
            toaster.notify('Klaida redaugojant grafiką: ' + response.errors[0].message, {duration: 5000})
          } else {
            toaster.notify('Grafikas pakoreguotas ir perskaičiuotas.', {duration: 5000})
          }
        })
    } catch (e) {
      // ...
    }
  }

  return (<>
    <tr />{/* required to preserve striped table styles */}
    <animated.tr className={'repayment-status-' + getRepaymentStatus(installment).toLowerCase()} style={fadeInOnInit}>
      <td />
      <td className="pl-2 pt-3 pb-2" colSpan={Object.values(columns).filter(visible => !!visible).length - 1}>
        <Form
          className="row"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <FormGroup className="col">
            <div className="flex-column">
              <Label for="principalDiscount" aria-required>Kūno nuolaida</Label>
              <Input
                id="principalDiscount" name="principalDiscount" bsSize="sm"
                className={errors.principalDiscount ? 'is-invalid ' : ''}
                type="text"
                innerRef={register({
                  required: true,
                  pattern: currencyInputPattern,
                })}
              />
              <FormFeedback valid={!(errors.principalDiscount?.type === 'outOfBounds')}>Nuolaida viršija įmokos kūną</FormFeedback>
            </div>
          </FormGroup>

          <FormGroup className="col">
            <div className="flex-column">
              <Label for="interestDiscount" aria-required>Palūkanų nuolaida</Label>
              <Input
                id="interestDiscount" name="interestDiscount" bsSize="sm"
                className={errors.interestDiscount ? 'is-invalid ' : ''}
                type="text"
                innerRef={register({
                  required: true,
                  pattern: currencyInputPattern,
                })}
              />
              <FormFeedback valid={!(errors.interestDiscount?.type === 'outOfBounds')}>Nuolaida viršija įmokos palūkanas</FormFeedback>
            </div>
          </FormGroup>

          <FormGroup className="col">
            <div className="flex-column">
              <Label for="totalDiscount">Bendra nuolaida</Label>
              <Input
                id="totalDiscount" name="totalDiscount" bsSize="sm"
                type="text"
                innerRef={register}
                disabled
              />
            </div>
          </FormGroup>

          <FormGroup className="col">
            <div className="flex-column">
              <Label>&nbsp;</Label>
              <Button
                color="light" size="sm" type="submit"
                className="w-100"
              >
                {!loading && <span>Išsaugoti</span>}
                {loading && <span>Saugoma <Spinner size="sm" color="primary" /></span>}
              </Button>
            </div>
          </FormGroup>

          <div className="col-12">
            {transferedPayments > 0 &&
              <div className="text-primary">
                Kitoms įmokoms perkeliama permoka po nuolaidos pritaikymo:{' '}
                <b><CurrencyDisplay value={transferedPayments} suffix /></b>
              </div>}
          </div>
        </Form>
      </td>
    </animated.tr>
  </>)
}

export default LoanScheduleEditRow