import { gql } from '@apollo/client'

const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(
      input: {
        username: $username
        password: $password
      }
    ) {
      access_token
      refresh_token
      expires_in
      token_type
      user {
        id
        email
        first_name
        role
      }
    }
  }
`

const GET_CURRENT_USER = gql`
  query GetCurrentUser {

    me {
      id
      email
      first_name
      role
    }
  }
`

export {
  LOGIN,
  GET_CURRENT_USER
}
