import React, { useState, useEffect } from 'react'
import { Button, ButtonProps, Tooltip, Spinner } from 'reactstrap'
import cn from 'classnames'

interface Props extends ButtonProps {
  handleClick: any,
  buttonLabel: string|React.ReactNode,
  confirmationTooltip: string,
  displayLoader?: boolean,
  requireConfirmation?: boolean,
}

/* eslint-disable-next-line no-undef */
const ButtonWithConfirmation: React.FC<Exclude<Props, 'id'|'onClick'|'outline'>> = (props) => {
  const {
    className,
    handleClick,
    buttonLabel,
    confirmationTooltip,
    displayLoader = false,
    requireConfirmation = true,
    ...buttonProps
  } = props
  const [ shouldConfirm, setShouldConfirm ] = useState<boolean>(false)

  const onClick = () => {
    if (!requireConfirmation || shouldConfirm) {
      handleClick()
      setShouldConfirm(false)
    } else {
      setShouldConfirm(true)
    }
  }

  useEffect(() => {
    const t = shouldConfirm && setTimeout(() => setShouldConfirm(false), 8000)
    return () => { t && clearTimeout(t) }
  }, [shouldConfirm])

  return (<>
    <Button
      id="rnd"
      className={cn('btn-w-confirmation', className)}
      onClick={onClick}
      color={shouldConfirm ? 'danger' : (props.color ? props.color : 'link')}
      outline={shouldConfirm}
      {...buttonProps}
    >
      {displayLoader && <Spinner size="sm" color="white" />}
      <span className="inner-label">{buttonLabel}</span>
    </Button>
    <Tooltip
      placement="bottom"
      isOpen={shouldConfirm}
      target="rnd"
    >{confirmationTooltip}</Tooltip>
  </>)
}

export default ButtonWithConfirmation