import React from 'react'
import { useHistory } from 'react-router-dom'
import { Modal, ModalBody } from 'reactstrap'

import LoanDetails from './LoanDetails'

interface IProps {
  id: any,
  toggle: () => void,
}

const LoanDetailsModal: React.FC<IProps> = ({ id, toggle }) => {
  const history = useHistory()
  const toggleAndGoBack = () => {
    history.goBack()
    toggle()
  }

  return (
    <Modal isOpen={!!id} toggle={toggleAndGoBack} size="lg">
      <ModalBody className="p-0">
        <LoanDetails id={id} />
      </ModalBody>
    </Modal>
  )
}
export default LoanDetailsModal