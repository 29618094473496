import React from 'react'
import { Form, FormGroup, Label, Input } from 'reactstrap'
import DatePicker from 'react-datepicker'

import { UserPaymentType, UserPaymentTypeType } from 'shared/models/UserPayment/enums'

interface FilterProps {
  dateAfter: any,
  dateBefore: any,
  paymentType: UserPaymentTypeType,
}

interface Props {
  filters: FilterProps,
  updateFilters: (filters: FilterProps) => void,
}

const UserPaymentListFilter: React.FC<Props> = ({ filters, updateFilters }) => (
  <Form
    onSubmit={e => e.preventDefault()}
    className="pb-2 justify-content-end"
    autoComplete="off"
    inline
  >
    <FormGroup className="mr-4">
      <Label for="user-payment-type" className="mr-1">Tipas:</Label>
      <Input
        id="user-payment-type" name="user-payment-type"
        type="select"
        bsSize="sm"
        value={filters.paymentType}
        onChange={(e: any) => updateFilters({...filters, paymentType: e.target.value})}
      >
        <option value={UserPaymentType.ANY}>visos</option>
        <option value={UserPaymentType.INSTALLMENT}>įmokos</option>
        <option value={UserPaymentType.EARLY_REPAYMENT}>išankstiniai grąžinimai</option>
      </Input>
    </FormGroup>

    <FormGroup className="mr-2">
      <Label for="date-from" className="mx-1">Apmokėta nuo:</Label>
      <DatePicker
        dateFormat="yyyy-MM-dd"
        selected={filters.dateAfter}
        onChange={(value: Date) => updateFilters({...filters, dateAfter: value})}
        customInput={(
          <Input
            id="date-from" name="date-from"
            type="text"
            bsSize="sm"
          />
        )}
      />
    </FormGroup>
    
    <FormGroup>
      <Label for="date-to" className="mr-1">iki:</Label>
      <DatePicker
        dateFormat="yyyy-MM-dd"
        selected={filters.dateBefore}
        onChange={(value: Date) => updateFilters({...filters, dateBefore: value})}
        customInput={(
          <Input
            id="date-to" name="date-to"
            type="text"
            bsSize="sm"
          />
        )}
      />
    </FormGroup>
  </Form>
)

export default UserPaymentListFilter