import React, { useContext } from 'react'

import { modalContext } from 'components/modals/context'
import CustomerDetailsModal from './CustomerDetailsModal'

const useCustomerModal = () => {
  const { pushModal, popModal } = useContext(modalContext)

  const displayCustomerModal = (id: string) =>
    pushModal(
      <CustomerDetailsModal
        key={id}
        id={id}
        toggle={() => popModal(id)}
      />
    )

  return { displayCustomerModal }
}

export default useCustomerModal