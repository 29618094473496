import React, { useEffect, useState } from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import { useQuery, NetworkStatus } from '@apollo/client'
import { format } from 'date-fns'

import history from 'shared/config/history'
import Pagination from 'shared/components/pagination'
import { GET_MESSAGE_LOG } from '../queries'
import MessageLog from '../MessageLog'
import MessageLogFilter from './components/MessageLogFilter'
import LoadableContent from 'shared/components/LoadableContent'

export interface MessageLogFilterProps {
  dateAfter: any,
  dateBefore: any,
  template: any,
}

interface Props {
  withFilter?: boolean,
}

const defaultFilterProps = {
  dateAfter: undefined,
  dateBefore: undefined,
  template: undefined,
}

const FilteredMessageLog: React.FC<Props> = ({ withFilter }) => {
  const match = useRouteMatch<any>()
  const { page } = useParams<any>()

  const [ filters, setFilters ] = useState<MessageLogFilterProps>(defaultFilterProps)
  const [ messages, setMessages ] = useState<any>()
  
  const { data, loading, refetch, networkStatus } = useQuery(GET_MESSAGE_LOG, {
    variables: {
      page,
      dateAfter: filters.dateAfter && format(filters.dateAfter, 'yyyy-MM-dd'),
      dateBefore: filters.dateBefore && format(filters.dateBefore, 'yyyy-MM-dd'),
      template: filters.template === '' ? undefined : filters.template,
    },
    notifyOnNetworkStatusChange: true,
  })
  
  useEffect(() => {
    if (JSON.stringify(filters) === JSON.stringify(defaultFilterProps)) return
    if (page && page === '1') return
    
    history.push(
      match?.params?.page
        ? match.path.replace(':page', '1')
        : match.path + '/1'
    )
    
    if (networkStatus === NetworkStatus.ready) refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  // useEffect(() => {
  //   fetchMore({variables: { page }})
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [page])

  useEffect(() => {
    if (data?.messageLog) setMessages(data.messageLog)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <LoadableContent ns={networkStatus} loading={loading}>
      {withFilter && <MessageLogFilter filters={filters} updateFilters={setFilters} />}
      {messages && (messages.paginatorInfo?.count
        &&  <>
              <MessageLog messages={messages.data} />
              <Pagination paginator={messages.paginatorInfo} urlBased />
            </>
        ||  <h2 className="py-5 text-center">Siųstų pranešimų nėra</h2>)}
    </LoadableContent>
  )
}

export default FilteredMessageLog