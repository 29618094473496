import React, { useRef, useState, useEffect } from "react"
import { Tooltip } from "reactstrap"
import { ClipboardPlus } from "react-bootstrap-icons"

interface Props {
  children: any,
  data: any,
}

const CopyToClipboard: React.FC<Props> = ({ children, data }) => {
  const childrenRef = useRef(null)

  const [ content, setContent ] = useState<string>('')
  const [ hovering, setHovering ] = useState(false);
  const [ copied, setCopied ] = useState<boolean>(false)

  const copyToClipboard = () => {
    setCopied(true)
    navigator.clipboard.writeText(content)
    const t = setTimeout(() => setCopied(false), 2000)
    
    return () => { t && clearTimeout(t) }
  }

  useEffect(() => {
    const val = Array.isArray(data)
      ? !!data.join('').trim() && data.join('')
      : data
    setContent(val)
  }, [data])

  return (
    <div
      role={content ? 'button' : 'rowheader'}
      onClick={() => !!content && copyToClipboard()}
      onMouseEnter={() => !!content && setHovering(true)}
      onMouseLeave={() => !!content && setHovering(false)}
    >
      <span ref={childrenRef}>
        <ClipboardPlus size="13" className={'text-info mr-1 ' + (hovering ? '' : 'invisible')} />
        {children}
      </span>
      <Tooltip target={childrenRef} isOpen={copied} placement="left">Nukopijuota!</Tooltip>
    </div>
  )
}

export default CopyToClipboard