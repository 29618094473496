import { gql } from '@apollo/client'
import { ATTACHMENTS_PAGINATOR, ATTACHMENT_DETAILS_FRAGMENT } from 'shared/models/Attachment/fragments'
import { LOAN_ATTACHMENTS_FRAGMENT } from 'shared/models/Loan/fragments'
import { USER_ATTACHMENTS_FRAGMENT } from 'shared/models/User/fragments'

const GET_ATTACHMENTS = gql`
  query GetAttachments(
    $first: Int = 30,
    $page: Int = 1,
    $dateAfter: Date,
    $dateBefore: Date
  ) {
    attachments(
      first: $first,
      page: $page,
      dateAfter: $dateAfter,
      dateBefore: $dateBefore
    ) {
      ...attachmentsPaginator
      data {
        id
        ...attachmentDetails
      }
    }
  }
  ${ATTACHMENTS_PAGINATOR}
  ${ATTACHMENT_DETAILS_FRAGMENT}
`

const UPLOAD_ATTACHMENT = gql`
  mutation UploadAttachment(
    $subjectId: ID!,
    $subjectType: String!,
    $files: [Upload!]!,
    $description: String!
  ) {
    uploadAttachment(
      subjectId: $subjectId,
      subjectType: $subjectType,
      files: $files,
      description: $description
    ) {
      ... on User {
        id
        ...userAttachments
      }

      ... on Loan {
        id
        ...loanAttachments
      }
    }
  }
  ${USER_ATTACHMENTS_FRAGMENT}
  ${LOAN_ATTACHMENTS_FRAGMENT}
`

const GET_ATTACHMENT_DOWNLOAD_LINK = gql`
  query DownloadAttachment($id: ID!) {
    downloadAttachment(id: $id) {
      url
      validUntil
    }
  }
`

export {
  GET_ATTACHMENTS,
  UPLOAD_ATTACHMENT,
  GET_ATTACHMENT_DOWNLOAD_LINK,
}
