import React, { useEffect, useState } from 'react'
import { Button, Nav, NavItem, NavLink } from 'reactstrap'
import { useQuery } from '@apollo/client'

import QueryableContent from 'shared/components/QueryableContent'
import UploadAttachment from 'components/attachments/UploadAttachment'

import { GET_USER_ATTACHMENTS } from 'components/users/queries'
import AttachmentList from 'components/attachments/AttachmentList'

interface Props {
  id: string,
}

const Documents: React.FC<Props> = ({ id }) => {
  const [ attachments, setAttachments ] = useState<any>([])
  const [ displayUploadForm, setDisplayUploadForm ] = useState<boolean>(false)

  const query = useQuery(GET_USER_ATTACHMENTS, {
    variables: { id },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  }), { data } = query

  useEffect(() => {
    data?.user?.attachments && setAttachments(data.user.attachments)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (<>
    <div className="pb-1 mb-3 border-bottom justify-content-center">
      <Nav pills className="justify-content-end">
        <NavItem>
          <NavLink
            tag={Button} color="link" className="btn-sm text-success"
            onClick={() => setDisplayUploadForm(true)}
          >Įkelti dokumentą</NavLink>
        </NavItem>
      </Nav>
    </div>
    {displayUploadForm && <UploadAttachment subjectId={id} subjectType={'user'} />}

    <QueryableContent
      query={query}
      data={attachments}
      noResultsPlaceholder={<div className="display-4 py-5 text-center">Įkeltų dokumentų nėra</div>}
    >
      <AttachmentList attachments={attachments} columns={{ subject: false }} />
    </QueryableContent>
  </>)
}

export default Documents