import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'

import { GET_CUSTOMER_PAYMENTS } from 'components/customers/queries'
import UserPaymentList from 'components/user-payments/UserPaymentList'
import LoadableContent from 'shared/components/LoadableContent'

interface Props {
  id: any,
}

const Payments: React.FC<Props> = ({ id }) => {
  const [ payments, setPayments ] = useState<any>()

  const { data, loading, networkStatus } = useQuery(GET_CUSTOMER_PAYMENTS, {
    variables: { id },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    data?.customer?.payments && setPayments(data.customer.payments)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <LoadableContent ns={networkStatus} loading={loading}>
      {payments && (payments.length
        &&  <UserPaymentList columns={{payer: false}} payments={payments} />
        ||  <div className="display-4 py-5 text-center text-muted">Įmokų nėra</div>)}
    </LoadableContent>
  )
}

export default Payments
