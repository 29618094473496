import React from 'react'

import AllUsers from 'components/users/all-users'

const List: React.FC = () => (
  <>
    <h2>Sistemos vartotojai</h2>
    <AllUsers />
  </>
)

export default List
