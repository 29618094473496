import { gql } from '@apollo/client'
import { MESSAGE_LOG_PAGINATOR, MESSAGE_LOG_ENTRY_ATTRIBUTES } from 'shared/models/MessageLog/fragments'

const GET_MESSAGE_LOG = gql`
  query GetMessageLog(
    $first: Int = 30,
    $page: Int = 1,
    $template: ID,
    $dateAfter: Date,
    $dateBefore: Date
  ) {
    messageLog(
      first: $first,
      page: $page,
      template: $template,
      dateAfter: $dateAfter,
      dateBefore: $dateBefore
    ) {
      ...messageLogPaginator
      data {
        id
        ...messageLogEntryAttributes
      }
    }
  }
  ${MESSAGE_LOG_PAGINATOR}
  ${MESSAGE_LOG_ENTRY_ATTRIBUTES}
`

export {
  GET_MESSAGE_LOG,
}
