import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { NavLink as Link, Route, Switch, Redirect, HashRouter } from 'react-router-dom'
import { Nav, NavItem, NavLink, Navbar } from 'reactstrap'

import { GET_USER } from '../queries'
import Profile from './components/Profile'
import Questionnaire from './components/Questionnaire'
import UserNotes from './components/UserNotes'
import Payments from './components/Payments'
import Messaging from './components/Messaging'
import CustomerLoans from './components/CustomerLoans'
import Documents from './components/Documents'
import Settings from './components/Settings'
import LoadableContent from 'shared/components/LoadableContent'
import SodraData from './components/SodraData'

interface Props {
  id: any,
}

const CustomerDetails: React.FC<Props> = ({ id }) => {
  const [ user, setUser ] = useState<any>()

  const { loading, data, networkStatus } = useQuery(GET_USER, {
    variables: { id },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (data?.user) setUser(data.user)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <LoadableContent ns={networkStatus} loading={loading}>
      {user && (user.customer
        &&  <HashRouter basename={`/customer/${id}`}>
              <Navbar color="dark" dark expand className="justify-content-center">
                <Nav navbar>
                  <NavItem>
                    <NavLink tag={Link} replace activeClassName="active" to={`/profile`}>
                      Paskyra
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink tag={Link} replace activeClassName="active" to={`/questionnaire`}>
                      Anketa
                    </NavLink>
                  </NavItem> */}
                  <NavItem>
                    <NavLink tag={Link} replace activeClassName="active" to={`/notes`}>
                      Pastabos
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} replace activeClassName="active" to={`/sodra`}>
                      SoDra
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} replace activeClassName="active" to={`/loans`}>
                      Paskolos
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} replace activeClassName="active" to={`/payments`}>
                      Mokėjimai
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} replace activeClassName="active" to={`/messaging`}>
                      Pranešimai
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} replace activeClassName="active" to={`/documents`}>
                      Dokumentai
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} replace activeClassName="active" to={`/settings`}>
                      Nustatymai
                    </NavLink>
                  </NavItem>
                </Nav>
              </Navbar>

              <div className="p-2">
                <Switch>
                  <Route path="/profile">
                    <Profile user={user} customer={user.customer} />
                  </Route>
                  <Route path="/questionnaire">
                    <Questionnaire user={user} customer={user.customer} />
                  </Route>
                  <Route path="/notes">
                    <UserNotes id={user.id} />
                  </Route>
                  <Route path="/sodra">
                    <SodraData id={user.id} customer={user.customer} />
                  </Route>
                  <Route path="/payments">
                    <Payments id={user.customer.id} />
                  </Route>
                  <Route path="/messaging">
                    <Messaging id={user.id} />
                  </Route>
                  <Route path="/loans">
                    <CustomerLoans id={user.customer.id} />
                  </Route>
                  <Route path="/documents">
                    <Documents id={user.id} />
                  </Route>
                  <Route path="/settings">
                    <Settings id={user.customer.id} />
                  </Route>
                  
                  <Redirect push to={`/profile`} from="/" strict exact />
                </Switch>
              </div>
            </HashRouter>
        ||  <h2 className="py-5 text-center">Klaida gaunant vartotojo duomenis</h2>)
      }
    </LoadableContent>
  )
}

export default CustomerDetails