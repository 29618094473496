import React, { useEffect, useReducer, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Form, FormGroup, Label, Input, Spinner, Badge } from 'reactstrap'

import ButtonWithConfirmation from 'shared/components/ButtonWithConfirmation'
import { EDIT_CUSTOMER, GET_CUSTOMER } from '../../queries'
import { useMutation, useQuery } from '@apollo/client'
import toaster from 'toasted-notes'
import LoadableContent from 'shared/components/LoadableContent'

interface Props {
  id: string,
}

const Settings: React.FC<Props> = ({ id }) => {
  const [ customer, setCustomer ] = useState<any>()

  const { data, loading, networkStatus } = useQuery(GET_CUSTOMER, {
    variables: { id },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })

  const { register, formState, control, setValue } = useForm({ mode: 'onBlur' })

  const [ patchCustomer ] = useMutation(EDIT_CUSTOMER, { errorPolicy: 'all' })
  
  const [ nowUpdating, setNowUpdating ] = useReducer((state: any[], action: any) => {
    switch (action.type) {
      case 'push':
        return [...state, action.key]
      case 'pop':
        return state.filter(element => element !== action.key)
      case 'reset':
        return []
      default:
        throw new Error()
    }
  }, [])

  const watcher = useWatch({ control })

  useEffect(() => {
    if (!data?.customer) return

    setCustomer(data.customer)
    setValue('isMessagingEnabled', data.customer.is_messaging_enabled)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (!customer || !watcher || !Object.keys(watcher).length) return

    const needsUpdate = Object.keys(watcher).filter((key) => customer[key.replace(/[A-Z]/g, m => `_${m.toLowerCase()}`)] !== watcher[key])
    if (!needsUpdate.length) return

    needsUpdate.forEach((key: any) => setNowUpdating({ type: 'push', key }))

    patchCustomer({variables: {id, ...watcher}})
      .then((response: any) => {
        if (response.errors?.length > 0) {
          toaster.notify('Nenumatyta klaida išsaugant vartotojo nustatymus.', { duration: 5000 })
        }
        needsUpdate.forEach((key: any) => setNowUpdating({ type: 'pop', key }))
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watcher])

  return (
    <LoadableContent ns={networkStatus} loading={loading}>
      <Form autoComplete="off" className="pt-3">
        <FormGroup row>
          <Label
            for="isMessagingEnabled"
            className="col-form-label col-4 text-right"
          >Pranešimai siunčiami:</Label>
          <div className="col">
            <Input
              id="isMessagingEnabled" name="isMessagingEnabled"
              className="ml-0"
              type="checkbox"
              bsSize="sm"
              innerRef={register}
            />
          </div>
          <div className="col-1 px-0 align-self-center">
            <Spinner size="sm" color="primary" className={~nowUpdating.indexOf('isMessagingEnabled') ? 'visible' : 'invisible'} />
          </div>
        </FormGroup>

        <FormGroup row>
          <Label
            for="status"
            className="col-form-label col-4 text-right"
          >Statusas:</Label>
          <div className="col align-self-center">
            <Badge pill color="success" className="mr-3">AKTYVUS</Badge>
            <ButtonWithConfirmation
              color="inline"
              className="text-danger"
              buttonLabel="Blokuoti"
              confirmationTooltip="Tikrai norite blokuoti vartotoją?"
              handleClick={() => null}
              />
            {/* <Badge pill color="danger" className="mr-3">blokuotas</Badge> */}
            {/* <ButtonWithConfirmation
              color="inline"
              className="text-warning"
              buttonLabel="Aktyvuoti"
              confirmationTooltip="Tikrai norite aktyvuoti blokuotą vartotoją?"
              handleClick={() => null}
            /> */}
          </div>
          <div className="col-1 px-0 align-self-center">
            <Spinner size="sm" color="primary" className={formState.isSubmitting ? 'visible' : 'invisible'} />
          </div>
        </FormGroup>
      </Form>
    </LoadableContent>
  )
}

export default Settings