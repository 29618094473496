import React, { useState, createContext, useEffect } from 'react'
import { setDefaultLocale, registerLocale } from  'react-datepicker'
import { lt } from 'date-fns/locale'

registerLocale('lt', lt)

interface ProviderProps {
  locale: string,
  setLocale: (locale: string) => void,
}

interface ContextProps {
  children: React.ReactNode,
}

export const localeContext = createContext<ProviderProps>({
  locale: 'lt',
  setLocale: () => undefined,
})

const { Provider } = localeContext

const LocaleContextProvider: React.FC<ContextProps> = ({ children }) => {
  const [ locale, setLocale ] = useState('lt')

  useEffect(() => {
    setDefaultLocale('lt')
  }, [locale])

  return (
    <Provider value={{ locale, setLocale }}>
      {children}
    </Provider>
  )
}

export default LocaleContextProvider
