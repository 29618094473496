import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { CloudArrowDownFill, InfoCircle } from 'react-bootstrap-icons'
import { Table, UncontrolledTooltip } from 'reactstrap'
import { FormatDateSplit } from 'shared/components/FormatDate'
import LoadableContent from 'shared/components/LoadableContent'

import { GET_DOCUMENT_TEMPLATES } from './queries'

const DocumentTemplates: React.FC = () => {
  const [ templates, setTemplates ] = useState<any[]>([])

  const { data, loading, networkStatus } = useQuery(GET_DOCUMENT_TEMPLATES, {
    notifyOnNetworkStatusChange: true,
  })
  
  useEffect(() => {
    data?.documentTemplates && setTemplates(data.documentTemplates)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  
  
  return (
    <LoadableContent ns={networkStatus} loading={loading}>
      {data && templates?.length > 0 &&
        <Table>
          <thead className="thead-dark">
            <tr>
              <th scope="col">Šablono tipas</th>
              <th scope="col">Pavadinimas</th>
              <th scope="col" className="text-center">
                <span className="mr-3">Sukurta</span>
              </th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
            {templates.map((template: any) => (
              <tr key={template.id}>
                <td>{template.type}</td>
                <td>{template.title}</td>
                <td className="text-center">
                  <div className="d-inline-flex align-items-center">
                    <div className="flex-shrink-1 mr-1">
                      <FormatDateSplit date={template.created_at} />
                    </div>
                    <span className="align-text-bottom">
                      <InfoCircle size="12" className="text-info ml-1" id={'created_by_tooltip-' + template.id} />
                    </span>
                  </div>
                  <UncontrolledTooltip target={'created_by_tooltip-' + template.id}>
                    {template.created_by.first_name + ' ' + template.created_by.last_name}
                  </UncontrolledTooltip>
                </td>
                <td className="text-right">
                  <a href={template.download} className="btn-inline" target="_blank" rel="noreferrer">
                    <CloudArrowDownFill className="text-primary" size="20" />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>}
    </LoadableContent>
  )
}

export default DocumentTemplates