import React from 'react'
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom'
import { Container } from 'reactstrap'

import Monthly from './monthly'
import Reports from './reports'
import Statistics from './statistics'

const Accounting: React.FC<RouteComponentProps<{id: string}>> = ({ match }) => (
  <Container>
    <Switch>
      <Route exact path={`${match.path}/monthly`} component={Monthly} />
      <Route exact path={`${match.path}/reports`} component={Reports} />
      <Route exact path={`${match.path}/statistics`} component={Statistics} />
      
      <Redirect to="/dashboard" />
    </Switch>
  </Container>
)

export default Accounting
