
import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'

import { FormatDateSplit } from 'shared/components/FormatDate'
import { cleanUpMessage, trimMessage } from 'shared/models/MessageLog/helpers'

import CustomerLink from 'components/customers/CustomerLink'
import LoanLink from 'components/loans/LoanLink'

interface Props {
  messages: any[],
  columns: {[key: string]: boolean},
}

const MessageLogBody: React.FC<Props> = ({ messages, columns }) => (
  <tbody>
    {messages.map((message: any) => (
      <tr key={message.id}>
        <td className="text-center">
          {message.created_at && <FormatDateSplit date={message.created_at} />}
        </td>
        <td className="text-center">
          {message.delivered_at && <FormatDateSplit date={message.delivered_at} />}
        </td>
        {columns.description &&
          <td className="text-center">{message.message_template?.description}</td>}
        {columns.loan &&
          <td className="text-center">
            {message.loan && <LoanLink loanId={message.loan.id} />}
          </td>}
        {columns.recipient &&
          <td className="text-center">
            <CustomerLink subject={message.recipient} opensModal />
          </td>}
        {columns.content && 
          <td className="text-left">
            <span id={'message_content_tooltip-' + message.id}>{trimMessage(message.content)}</span>
            <UncontrolledTooltip target={'message_content_tooltip-' + message.id}>
              {cleanUpMessage(message.content)}
            </UncontrolledTooltip>
          </td>}
      </tr>
    ))}
  </tbody>
)

export default MessageLogBody