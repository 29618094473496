import React from 'react'
import cn from 'classnames'
import { useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { Form, FormGroup, Label, Input, Row, Button, FormFeedback, Col } from 'reactstrap'

import { UPLOAD_ATTACHMENT } from './queries'

interface Props {
  subjectId: string,
  subjectType: string,
}

const UploadAttachment: React.FC<Props> = ({ subjectId, subjectType }) => {
  const { register, handleSubmit, errors, formState, reset } = useForm({ mode: 'onBlur', reValidateMode: 'onBlur' })

  const [ uploadAttachment, { loading, error } ] = useMutation(UPLOAD_ATTACHMENT, {
    variables: {
      subjectId,
      subjectType,
    },
    errorPolicy: 'all',
  })

  const onSubmit = async (data: any) => {
    if (errors.length) return

    try {
      await uploadAttachment({variables: {
        subjectId,
        subjectType,
        ...data,
      }})
    } catch (e) {
      // ...
    }
  }

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      className="pb-2"
      autoComplete="off"
    >
      <Row form>
        <Col>
          <FormGroup>
            <Label for="description" aria-required>Aprašymas</Label>
            <Input
              id="description" name="description"
              className={errors.description ? 'is-invalid':''}
              type="text"
              bsSize="sm"
              innerRef={register({ required: true })}
            />
            <FormFeedback valid={!(errors.description?.type === 'required')}>Įveskite dokumento aprašymą</FormFeedback>
          </FormGroup>
        </Col>

        <Col>
          <FormGroup>
            <Label for="files" aria-required>Dokumentas</Label>
            <Input
              id="files" name="files"
              className={cn(
                'text-truncate',
                {'is-invalid': errors.files}
              )}
              type="file"
              bsSize="sm"
              innerRef={register({ required: true })}
            />
            <FormFeedback valid={!(errors.files?.type === 'required')}>Pasirinkite dokumentą</FormFeedback>
          </FormGroup>
        </Col>
        
        <Col md="auto">
          <FormGroup>
            <Label for="files">&nbsp;</Label>
            <div>
              <Button
                color="outline-primary"
                size="sm"
                type="submit"
                disabled={formState.isSubmitted && !error && !loading}
              >Įkelti</Button>
              <Button
                className="ml-1"
                color="outline-primary"
                size="sm"
                type="button"
                onClick={() => reset()}
                disabled={loading || !formState.isDirty}
              >
                {formState.isSubmitSuccessful && 'Įkelti kitą'}
                {!formState.isSubmitSuccessful && 'Atšaukti'}
              </Button>
            </div>
            {formState.isSubmitted && <>
              {!error && !loading && formState.isSubmitSuccessful &&
                <div className="text-success">Dokumentas sėkmingai įkeltas</div>}
              {error?.graphQLErrors?.length && <div>
                <span className="text-danger font-weight-bold mr-1">{error.graphQLErrors[0]?.message}</span>
                <span className="text-muted">
                  {error.graphQLErrors[0]?.extensions?.reason}
                </span>
              </div>}</>}
          </FormGroup>
        </Col>
      </Row>
    </Form>
  )
}

export default UploadAttachment