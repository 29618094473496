import React, { useState, useContext } from 'react'
import { NavLink as Link, useHistory } from 'react-router-dom'
import { Navbar, Nav, NavbarBrand, NavItem, NavLink, NavbarToggler, Collapse, Button } from 'reactstrap'
import { ArrowLeftSquare } from 'react-bootstrap-icons';

import { authContext } from 'shared/components/auth/context'
import { AdminLayoutContext } from './AdminLayoutContext'

const Header: React.FC = () => {
  const auth: any = useContext(authContext)
  const [ collapsed, setCollapsed ] = useState(true)
  const { goBack } = useHistory()
  const { compactHeader } = useContext(AdminLayoutContext)

  return (
    <header>
      <Navbar light expand>
        <NavbarBrand href="/dashboard" className="mx-2">
          <small>🔥</small>
        </NavbarBrand>
        
        <Nav navbar className="ml-auto">
          <NavItem>
            <NavLink tabIndex={-1}>
              <b>{auth.data && auth.data.me && auth.data.me.first_name}</b>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#" onClick={auth.logout}>Atsijungti</NavLink>
          </NavItem>
        </Nav>
      </Navbar>

      {compactHeader &&
        <Button color="link" onClick={() => goBack()}>
          <ArrowLeftSquare size={16} /> Atgal
        </Button>}

      {!compactHeader &&
        <Navbar light expand="md" className="pb-2">
          <NavbarToggler onClick={() => setCollapsed(!collapsed)} className="mr-2" />

          <Collapse navbar isOpen={!collapsed}>
            <Nav navbar>
              <NavItem>
                <NavLink tabIndex={-1} disabled>Valdymas</NavLink>
                <NavLink tag={Link} activeClassName="active" to="/dashboard">Valdymo centras</NavLink>
                <NavLink tag={Link} activeClassName="active" to="/user-payments/create">Įmokos įvedimas</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tabIndex={-1} disabled>Paskolos</NavLink>
                <NavLink tag={Link} activeClassName="active" to="/loans/list">Paskolos</NavLink>
                <NavLink tag={Link} activeClassName="active" to="/users/customers/list">Klientai</NavLink>
                <NavLink tag={Link} activeClassName="active" to="/user-payments/list">Įmokos</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tabIndex={-1} disabled>Statistika</NavLink>
                <NavLink tag={Link} className="text-inactive" activeClassName="active" to="/accounting/statistics">Statistika</NavLink>
                <NavLink tag={Link} className="text-inactive" activeClassName="active" to="/accounting/monthly">Buhalterija</NavLink>
                <NavLink tag={Link} className="text-inactive" activeClassName="active" to="/accounting/reports">Ataskaitos</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tabIndex={-1} disabled>Įrankiai</NavLink>
                <NavLink tag={Link} activeClassName="active" to="/tools/calculator">Skaičiuoklė</NavLink>
                <NavLink tag={Link} className="text-inactive" activeClassName="active" to="/tools/sodra">SoDra</NavLink>
                <NavLink tag={Link} className="text-inactive" activeClassName="active" to="/tools/creditinfo">CreditInfo</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tabIndex={-1} disabled>Nustatymai</NavLink>
                <NavLink tag={Link} activeClassName="active" to="/settings/loans">Sistemos nustatymai</NavLink>
                <NavLink tag={Link} activeClassName="active" to="/users/system/list">Vartotojai</NavLink>
                <NavLink tag={Link} className="text-inactive" activeClassName="active" to="/settings/companies">Įmonės</NavLink>
              </NavItem>
              <NavItem>
                <NavLink tabIndex={-1} disabled>Žurnalas</NavLink>
                <NavLink tag={Link} activeClassName="active" to="/activity/message-log">Pranešimai</NavLink>
                <NavLink tag={Link} activeClassName="active" to="/activity/users/list">Vartotojų veiksmai</NavLink>
                <NavLink tag={Link} className="text-inactive" activeClassName="active" to="/activity/customers/list">Klientų veiksmai</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
      </Navbar>}
    </header>
  )
}

export default Header