import React, { ReactNode, useEffect, useState } from 'react'
import { QueryResult, NetworkStatus } from '@apollo/client'
import { animated, useSpring } from 'react-spring'

interface Props {
  query: QueryResult<any, any>,
  data: {[key: string]: any},
  noResultsPlaceholder?: ReactNode,
  errorPlaceholder?: ReactNode,
  paginated?: boolean,
}

const QueryableContent: React.FC<Props> = ({
  children,
  query,
  data,
  noResultsPlaceholder = undefined,
  errorPlaceholder = undefined,
  paginated = false,
}) => {
  const { error, loading, networkStatus } = query
  const spinnerStyle = useSpring({opacity: loading ? 1 : 0})
  const [ displayResults, setDisplayResults ] = useState<boolean>(false)
  const [ displaySpinner, setDisplaySpinner ] = useState<boolean>(false)

  useEffect(() => {
    setDisplayResults(
      data &&
      (paginated
        ? data.paginatorInfo?.count > 0
        : data.length > 0)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, data])

  useEffect(() => {
    setDisplaySpinner(loading &&
      networkStatus !== NetworkStatus.refetch &&
      networkStatus !== NetworkStatus.poll)
  }, [loading, networkStatus])

  return (
    <div className={displaySpinner ? 'loadable-content loading' : 'loadable-content'}>
      {networkStatus === NetworkStatus.error && (error || <p>ERROR</p>)}
      {displaySpinner &&
        <animated.div className="loading-overlay" style={spinnerStyle}>
          <div data-loader="spinner"></div>
        </animated.div>}
      {!error && displayResults && children}
      {!error && !displayResults && !displaySpinner && noResultsPlaceholder}
      {error && (errorPlaceholder || <>ERROR</>)}
    </div>
  )
}

export default QueryableContent