import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Form, FormGroup, Label, Input, Row, Button, FormFeedback, FormText, Spinner } from 'reactstrap'
import { InfoCircleFill } from 'react-bootstrap-icons'
import { useForm } from 'react-hook-form'

import { datetimeInputPattern, currencyInputPattern } from 'shared/config/format'
import { UserPaymentType } from 'shared/models/UserPayment/enums'
import { getCurrentDebt } from 'shared/models/Loan/helpers'
import { validatePaymentDateRange } from 'shared/models/UserPayment/helpers'
import CurrencyDisplay from 'shared/components/CurrencyDisplay'
import { parseCurrencyInput } from 'shared/components/CurrencyHelpers'

import { GET_LOAN_BY_AGREEMENT_NO } from 'components/loans/queries'
import { SAVE_USER_PAYMENT } from './queries'
import LoanLink from 'components/loans/LoanLink'
import CustomerLink from 'components/customers/CustomerLink'

interface Props { }

const UserPaymentInput: React.FC<Props> = () => {
  const { register, handleSubmit, setError, clearErrors, errors, formState, setValue, watch, reset: resetForm } = useForm({ mode: 'onBlur', reValidateMode: 'onBlur' })
  const watchAmount: string|undefined = watch('amount', 0)

  const [ paymentAmount, setPaymentAmount ] = useState<number>(0)
  const [ force, setForce ] = useState<boolean>(false)
  const [ loan, setLoan ] = useState<any>()

  const [ findLoanByAgreementNo, {
    loading: loadingLoan, data: loanResponse, called: loanQueryCalled,
  } ] = useLazyQuery(GET_LOAN_BY_AGREEMENT_NO, { fetchPolicy: 'no-cache' })

  const [ addPayment, {
    loading: addingPayment, data: addPaymentResponse, error: addPaymentError,
  } ] = useMutation(SAVE_USER_PAYMENT, { errorPolicy: 'all' })

  const parsePurpose = (purpose: string): boolean => {
    findLoanByAgreementNo({ variables: {agreementNo: purpose}})
    return true
  }

  const onSubmit = async (data: any) => {
    if (errors.length) return

    try {
      await addPayment({variables: {force, ...data}})
    } catch (e) {
      // ...
    }
  }

  const reset = () => {
    resetForm()
    setForce(false)
    setLoan(undefined)
    setPaymentAmount(0)
  }

  useEffect(() => {
    if (!loanQueryCalled || loadingLoan) return

    if (loanResponse?.loanByAgreementNo) {
      setLoan(loanResponse.loanByAgreementNo)
      setValue('loan', loanResponse.loanByAgreementNo.id)
      clearErrors('purpose')

    } else {
      setLoan(undefined)
      setValue('loan', undefined)
      setError('purpose', { type: 'notFound' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanResponse])

  useEffect(() => {
    if (!watchAmount) {
      setPaymentAmount(0)
      return
    }
    setPaymentAmount(parseCurrencyInput(watchAmount))
  }, [watchAmount])

  return (
    <Row className="justify-content-center">
      <h1 className="col-12 text-center">Įmokos įvedimas</h1>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className="col-md-8 col-lg-6 col-xl-5 py-4"
        autoComplete="off"
      >
        <FormGroup row>
          <Label
            for="type"
            className="col-form-label col-4 text-right"
            aria-required
          >Tipas</Label>
          <div className="col">
            <Input
              id="type" name="type"
              type="select"
              defaultValue={UserPaymentType.INSTALLMENT}
              bsSize="sm"
              innerRef={register({ required: true })}
            >
              <option value={UserPaymentType.INSTALLMENT}>įmoka</option>
              <option value={UserPaymentType.EARLY_REPAYMENT}>išankstinis grąžinimas</option>
              <option value={UserPaymentType.REGISTRATION}>registracija</option>
            </Input>
          </div>
          <div className="col-1"></div>
        </FormGroup>

        <FormGroup row>
          <Label
            for="purpose"
            className="col-form-label col-4 text-right"
            aria-required
          >Mokėjimo paskirtis</Label>
          <div className="col">
            <Input
              id="purpose" name="purpose"
              className={errors.purpose ? 'is-invalid':''}
              type="text"
              bsSize="sm"
              innerRef={register({
                required: true,
                validate: {
                  format: value => parsePurpose(value),
                },
              })}
            />
            <FormFeedback valid={!(errors.purpose?.type === 'required')}>Įveskite mokėjimo paskirtį</FormFeedback>
            <FormFeedback valid={!(errors.purpose?.type === 'format')}>Neteisinga mokėjimo paskirtis</FormFeedback>
            <FormFeedback valid={!(errors.purpose?.type === 'notFound')}>Paskolos pagal sutarties numerį rasti nepavyko</FormFeedback>
            <FormFeedback valid={!(errors.purpose?.type === 'loanClosed')}>Paskola uždaryta, įmokos nebepriimamos</FormFeedback>
          </div>
          <div className="col-1 px-0 align-self-center">
            <Spinner size="sm" color="primary" className={loadingLoan ? 'visible' : 'invisible'} />
          </div>
        </FormGroup>

        <FormGroup row>
          <Label
            for="amount"
            className="col-form-label col-4 text-right"
            aria-required
          >Suma</Label>
          <div className="col">
            <Input
              id="amount" name="amount"
              className={errors.amount ? 'is-invalid':''}
              type="text"
              placeholder="100,50"
              bsSize="sm"
              innerRef={register({
                required: true,
                pattern: currencyInputPattern,
              })}
              disabled={!loan}
            />
            <FormFeedback valid={!(errors.amount?.type === 'required')}>Įveskite sumą</FormFeedback>
            <FormFeedback valid={!(errors.amount?.type === 'pattern')}>Patikrinkite sumos formatą (pvz. <b>100,50</b>)</FormFeedback>
            {loan && paymentAmount >= getCurrentDebt(loan) &&
              <FormText color="success">Paskutinė įmoka, paskola bus uždaryta</FormText>}
            {loan && paymentAmount > getCurrentDebt(loan) &&
              <FormText color="warning">
                Paskutinės įmokos permoka: <CurrencyDisplay value={paymentAmount - getCurrentDebt(loan)} suffix />
              </FormText>}
          </div>
          <div className="col-1"></div>
        </FormGroup>

        <FormGroup row>
          <Label
            for="paidAt"
            className="col-form-label col-4 text-right"
            aria-required
          >Data</Label>
          <div className="col">
            <Input
              id="paidAt" name="paidAt"
              type="text"
              className={errors.paidAt ? 'is-invalid':''}
              placeholder="2020-02-02 12:00:00"
              bsSize="sm"
              innerRef={register({
                required: true,
                pattern: datetimeInputPattern,
                validate: {
                  dateRange: (value: any) => loan ? validatePaymentDateRange(loan, value) : false,
                },
              })}
              disabled={!loan}
            />
            <FormFeedback valid={!(errors.paidAt?.type === 'required')}>Įvesikte datą ir laiką</FormFeedback>
            <FormFeedback valid={!(errors.paidAt?.type === 'pattern')}>Patikrinkite datos ir laiko formatą (pvz. <b>2020-01-01 15:43:11</b>)</FormFeedback>
            <FormFeedback valid={!(errors.paidAt?.type === 'dateRange')}>Patikrinkite įmokos datą. Ar data vėlesnė už paraiškos patvirtinimo datą ir ne vėlesnė nei šiandiena?</FormFeedback>
          </div>
          <div className="col-1"></div>
        </FormGroup>
        
        <FormGroup row>
          <Label for="payerFirstname" className="col-form-label col-4 text-right">Mokėtojas</Label>
          <div className="col">
            <Input
              id="payerFirstname" name="payerFirstname"
              type="text"
              className={cn(
                'my-1',
                {'is-invalid': errors.payerFirstname},
              )}
              placeholder="vardas"
              bsSize="sm"
              innerRef={register}
              disabled={!loan}
            />
            <Input
              id="payerLastName" name="payerLastName"
              type="text"
              placeholder="pavardė"
              bsSize="sm"
              innerRef={register}
              disabled={!loan}
            />
            <FormText>Nurodoma tik, tuo atveju kai mokėtojas nesutampa su skolininku.</FormText>
            <FormFeedback valid={!(errors.payerFirstname?.type === 'required')}>Įvesikte mokėtojo asmens kodą</FormFeedback>
            <FormFeedback valid={!(errors.payerFirstname?.type === 'pattern')}>Patikrinkite asmens kodą</FormFeedback>
            <FormFeedback valid={!(errors.payerFirstname?.type === 'notFound')}>Kliento pagal asmens kodą rasti nepavyko</FormFeedback>
          </div>
          <div className="col-1"></div>
        </FormGroup>

        <FormGroup row>
          <Label
            for="borrowerFullname"
            className="col-form-label col-4 text-right"
          >Skolininkas</Label>
          <div className="col">
            <Input
              id="borrowerFullname" name="borrowerFullname"
              type="text"
              bsSize="sm"
              value={loan ? loan.borrower.first_name + ' ' + loan.borrower.last_name : ''}
              readOnly
            />
          </div>
          <div className="col-1 px-0 align-self-center">
            {loan?.borrower &&
              <CustomerLink className="d-flex align-items-center" subject={loan.borrower} opensModal>
                <InfoCircleFill size={15} />
              </CustomerLink>}
          </div>
        </FormGroup>

        <FormGroup row>
          <Label for="loan" className="col-form-label col-4 text-right">Paskola</Label>
          <div className="col">
            <Input
              id="loan" name="loan"
              type="text"
              bsSize="sm"
              innerRef={register}
              readOnly
            />
          </div>
          <div className="col-1 px-0 align-self-center">
            {loan && <LoanLink className="d-flex align-items-center" loanId={loan.id} />}
          </div>
        </FormGroup>

        <FormGroup row className="align-items-center">
          <Label for="date" className="col-4 text-right align-self-center">
            <Spinner size="sm" color="primary" className={addingPayment ? 'visible' : 'invisible'} />
          </Label>
          <div className="col-md-auto">
            <Button
              color="outline-primary"
              type="submit"
              className={cn(
                'w-100 w-md-auto',
                {'d-none': formState.isSubmitted && !addPaymentError && !addingPayment},
              )}
              disabled={addingPayment || !loan || errors.purpose}
            >Tvirtinti</Button>
            <Button
              color="outline-warning"
              type="button"
              className={cn(
                'w-100 w-md-auto',
                {'d-none': !formState.isSubmitted || addingPayment || addPaymentError},
              )}
              onClick={reset}
            >Iš naujo</Button>
          </div>

          {formState.isSubmitted &&
            <div className="col align-self-center">
              {!addPaymentError && addPaymentResponse &&
                <div className="text-success">Įmoka išsaugota</div>}
              {addPaymentError?.graphQLErrors?.length && <div>
                <span className="text-danger font-weight-bold mr-1">{addPaymentError.graphQLErrors[0]?.message}</span>
                <span className="text-muted">
                  {addPaymentError.graphQLErrors[0]?.extensions?.reason}
                  {addPaymentError.graphQLErrors[0]?.extensions?.canForce &&
                    <button
                      type="submit"
                      className="btn btn-inline btn-link font-weight-bold ml-1"
                      onClick={() => setForce(true)}
                    >Visvien išsaugoti.</button>}
                </span>
              </div>}
            </div>}
        </FormGroup>
      </Form>
    </Row>
  )
}

export default UserPaymentInput