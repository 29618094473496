import { gql } from '@apollo/client'

const ACTIVITY_DETAILS_FRAGMENT = gql`
  fragment activityDetails on Activity {
    # log_name
    description
    properties
    created_at

    causer {
      id
      first_name
      last_name
    }

    subject {
      ... on Loan {
        id
      }

      ... on LoanNote {
        id
        loan {
          id
        }
      }

      ... on LoanInstallment {
        id
        loan {
          id
        }
      }

      ... on User {
        id
      }

      ... on UserNote {
        id
        user {
          id
        }
      }

      ... on Customer {
        id
        user {
          id
        }
      }

      ... on UserPayment {
        id
        loan {
          id
        }
      }

      ... on DocumentTemplate {
        id
      }
    }
  }
`

export {
  ACTIVITY_DETAILS_FRAGMENT,
}