import { gql } from '@apollo/client'

const GET_DOCUMENT_TEMPLATES = gql`
  query GetDocumentTemplates {
    documentTemplates {
      id
      type
      title
      nicename
      description
      download

      created_at
      created_by {
        id
        first_name
        last_name
      }

      deleted_at
      deleted_by {
        id
        first_name
        last_name
      }
    }
  }
`

export {
  GET_DOCUMENT_TEMPLATES,
}
