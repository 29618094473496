import React from 'react'
import { Route, Switch, RouteComponentProps } from 'react-router-dom'

import AprCalculatorComponent from 'components/AprCalculator'

const Tools: React.FC<RouteComponentProps<{id: string}>> = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/calculator`}>
      <AprCalculatorComponent />
    </Route>
  </Switch>
)

export default Tools
