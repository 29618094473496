import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'

import LoanList from 'components/loans/LoanList'
import LoadableContent from 'shared/components/LoadableContent'
import { GET_CUSTOMER_LOANS } from '../../queries'

interface Props {
  id: any,
}

const CustomerLoans: React.FC <Props> = ({ id }) => {
  const [ loans, setLoans ] = useState<any>()

  const { data, loading, networkStatus } = useQuery(GET_CUSTOMER_LOANS, {
    variables: { id },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (data?.customer?.loans) setLoans(data.customer.loans)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <LoadableContent ns={networkStatus} loading={loading}>
      {loans && (loans.length
        &&  <LoanList loans={loans} status={'ANY'} columns={{customer: false}} />
        ||  <div className="display-4 py-5 text-center text-muted">Paskolų nėra</div>)}
    </LoadableContent>
  )
}

export default CustomerLoans
