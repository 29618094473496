import React from 'react'

interface Props {
  columns: {[key:string]: boolean},
}

const UserPaymentListHeader: React.FC<Props> = ({ columns }) => (
  <thead className="thead-dark">
    <tr>
      <th scope="col" className="text-center">Gauta</th>
      <th scope="col" className="text-right">Suma</th>
      <th scope="col" className="text-center">Tipas</th>
      {columns.payer && <th scope="col" className="text-center">Mokėtojas</th>}
      {columns.loan && <th scope="col" className="text-center">Paskola</th>}
      <th scope="col" className="text-center"><span className="mr-3">Įvesta</span></th>
      {columns.actions && <th scope="col" />}
    </tr>
  </thead>
)

export default UserPaymentListHeader
