import React, { useState } from 'react'
import { useMutation, gql } from '@apollo/client'
import { Modal, ModalBody, Form, FormGroup, Label, Input, Button, FormFeedback, FormText, Spinner } from 'reactstrap'
import { useForm } from 'react-hook-form'
import DatePicker from 'react-datepicker'

import { SAVE_LOAN_NOTE } from '../../queries'
import { cache } from 'shared/config/graphql'
import { format } from 'date-fns'

interface Props {
  isOpen: boolean,
  loan: any,
  toggle(): void,
}

const LoanNoteModal: React.FC<Props> = ({ isOpen, loan, toggle }) => {
  const [ relevantUntil, setRelevantUntil ] = useState<Date|undefined>()

  const { register, handleSubmit, errors, reset } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      content: '',
      importance: 'NOTE',
      relevantUntil: null,
    }
  })
  
  const [ saveNote, {
    loading: savingNote, data: saveNoteResponse, error: saveNoteError,
  } ] = useMutation(SAVE_LOAN_NOTE, {
    errorPolicy: 'all',
  })

  const onSubmit = (data: any) => {
    try {
      saveNote({variables: {
        loan,
        ...data,
        relevantUntil: (relevantUntil ? format(relevantUntil, 'yyyy-MM-dd HH:mm:ss') : undefined)
      }}).then(
        (response: any) => {
          cache.modify({
            id: `Loan:{"id":"${loan}"}`,
            fields: {
              notes(existing = [], { readField }) {
                const notes = response.data.saveLoanNote.notes;
                const data = notes[notes.length - 1]

                if (existing.filter((ref: any) => data.id === readField('id', ref)).length) {
                  return existing
                }

                const newRef = cache.writeFragment({
                  id: `LoanNote:{"id":"${data.id}"}`,
                  data,
                  fragment: gql`
                    fragment LoanNote on Loan {
                      id
                      content
                      is_relevant
                      relevant_until
                      importance
    
                      created_by {
                        id
                        first_name
                        last_name
                      }
                      created_at
                    }
                  `
                })
    
                return [ ...existing, newRef ]
              }
            }
          })
        }
      )

      reset()
    } catch (e) {
      // ...
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md">
      <ModalBody>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          className="py-4"
          autoComplete="off"
        >
          <FormGroup row>
            <Label
              for="relevantUntil"
              className="col-form-label col-4 text-right"
            >Galiojimas</Label>
            <div className="col">
              <DatePicker
                name="relevantUntil"
                className={errors.relevantUntil ? 'is-invalid' : ''}
                selected={relevantUntil}
                dateFormat="yyyy-MM-dd"
                onChange={(value: Date|null) => value && setRelevantUntil(value)}
                wrapperClassName="w-100"
                withPortal
                customInput={(
                  <Input
                    id="relevantUntil"
                    type="text"
                    bsSize="sm"
                    innerRef={register}
                  />
                )}
              />
              <FormText color="muted">Praėjus galiojimo datai, aktyvaus pranešimo pastaba nebus rodoma paskolų sąraše. Nenurodžius galiojimo datos, pranešimas rodomas visuomet.</FormText>
            </div>
            <div className="col-1 px-0"></div>
          </FormGroup>

          <FormGroup row>
            <Label
              for="importance"
              className="col-form-label col-4 text-right"
            >Svarba:</Label>
            <div className="col">
              <Input
                id="importance" name="importance"
                type="select"
                bsSize="sm"
                innerRef={register({ required: true })}
              >
                <option value={'NOTE'}>įprasta</option>
                <option value={'IMPORTANT'}>didelė</option>
              </Input>
            </div>
            <div className="col-1 px-0"></div>
          </FormGroup>
          
          <FormGroup row>
            <Label
              for="content"
              className="col-form-label col-4 text-right"
              aria-required
            >Pastaba</Label>
            <div className="col">
              <Input
                id="content" name="content"
                className={errors.content ? 'is-invalid':''}
                type="textarea"
                bsSize="sm"
                innerRef={register({ required: true })}
              />
              <FormFeedback valid={!(errors.content?.type === 'required')}>Įveskite pastabą</FormFeedback>
            </div>
            <div className="col-1 px-0"></div>
          </FormGroup>

          <FormGroup row>
            <Label for="date" className="col-4 text-right align-self-center">
              <Spinner size="sm" color="primary" className={savingNote ? 'visible' : 'invisible'} />
              </Label>
            <div className="col-md-auto">
              <Button
                color="outline-primary"
                type="submit"
                className="w-100 w-md-auto"
                disabled={savingNote}
              >
                Išsaugoti
              </Button>
            </div>
            <div className="col align-self-center">
              {!saveNoteError && saveNoteResponse &&
                <div className="text-success">Pastaba išsaugota</div>}
              {saveNoteError &&
                <div className="text-danger">Nenumatyta klaida</div>}
            </div>
          </FormGroup>
        </Form>
      </ModalBody>
    </Modal>
  )
}


export default LoanNoteModal