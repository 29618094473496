import { gql } from '@apollo/client'
 
import {
  LOAN_PARAMETERS_FRAGMENT,
  LOAN_EXTENDED_PARAMETERS_FRAGMENT,
  LOAN_NOTES_FRAGMENT,
  LOAN_INSTALLMENTS_FRAGMENT,
  LOAN_EXPECTED_INSTALLMENTS_FRAGMENT,
  LOAN_NOTE_ATTRIBUTES_FRAGMENT,
  LOAN_USER_PAYMENTS_FRAGMENT,
  LOAN_MESSAGE_LOG_FRAGMENT,
  LOAN_ATTACHMENTS_FRAGMENT,
} from 'shared/models/Loan/fragments'

const PAGINATOR = gql`
  fragment loanPaginator on LoanPaginator {
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      perPage
      total
    }
  }
`

const GET_LOAN = gql`
  query GetLoan($id: ID!) {
    loan(id: $id) {
      id
      ...loanParameters
      ...loanExtendedParameters
      ...loanNotes
      ...loanInstallments
      ...expectedInstallments
    }
  }
  ${LOAN_PARAMETERS_FRAGMENT}
  ${LOAN_EXTENDED_PARAMETERS_FRAGMENT}
  ${LOAN_NOTES_FRAGMENT}
  ${LOAN_INSTALLMENTS_FRAGMENT}
  ${LOAN_EXPECTED_INSTALLMENTS_FRAGMENT}
`

const GET_LOAN_BY_AGREEMENT_NO = gql`
  query GetLoanByAgreementNo($agreementNo: String!) {
    loanByAgreementNo(agreement_no: $agreementNo) {
      id
      ...loanParameters
      ...loanExtendedParameters
      ...loanNotes
      ...loanInstallments
      ...expectedInstallments
    }
  }
  ${LOAN_PARAMETERS_FRAGMENT}
  ${LOAN_EXTENDED_PARAMETERS_FRAGMENT}
  ${LOAN_NOTES_FRAGMENT}
  ${LOAN_INSTALLMENTS_FRAGMENT}
  ${LOAN_EXPECTED_INSTALLMENTS_FRAGMENT}
`

const GET_LOANS_BY_STATUS = gql`
  query GetLoansByStatus(
    $status: LoanStatus = ANY,
    $search: String = null,
    $repaymentStatus: LoanRepaymentStatus = ANY,
    $duePaymentLateDays: Int,
    $orderBy: [QueryLoansOrderByOrderByClause!],
    $first: Int = 20,
    $page: Int = 1
  ) {
    loans(
      status: $status,
      search: $search,
      repaymentStatus: $repaymentStatus,
      duePaymentLateDays: $duePaymentLateDays,
      orderBy: $orderBy,
      first: $first,
      page: $page
    ) {
      ...loanPaginator
      data {
        id
        ...loanParameters
      }
    }
  }
  ${PAGINATOR}
  ${LOAN_PARAMETERS_FRAGMENT}
`

const GET_LOAN_USER_PAYMENTS = gql`
  query GetLoan($id: ID!) {
    loan(id: $id) {
      id
      ...loanUserPayments
    }
  }
  ${LOAN_USER_PAYMENTS_FRAGMENT}
`

const GET_LOAN_MESSAGE_LOG = gql`
  query GetLoan(
    $id: ID!,
    $first: Int = 30,
    $page: Int = 1
  ) {
    loan(id: $id) {
      id
      ...loanMessageLog
    }
  }
  ${LOAN_MESSAGE_LOG_FRAGMENT}
`

const GET_LOAN_REQUEST_DOCUMENT_PACKAGE_LINK = gql`
  query GetLoanRequestDocumentPackage($id: ID!, $confirmedAt: DateTime) {
    loanRequestDocumentPackage(id: $id, confirmedAt: $confirmedAt) {
      url
      validUntil
    }
  }
`

const AUTHORIZE_LOAN_APPLICATION = gql`
  mutation AuthorizeLoanApplication($id: ID!, $loanCategory: LoanCategory) {
    authorizeLoanApplication(id: $id, loanCategory: $loanCategory) {
      id
      ...loanParameters
      ...loanExtendedParameters
      ...loanNotes
      ...loanInstallments
      ...expectedInstallments
    }
  }
  ${LOAN_PARAMETERS_FRAGMENT}
  ${LOAN_EXTENDED_PARAMETERS_FRAGMENT}
  ${LOAN_NOTES_FRAGMENT}
  ${LOAN_INSTALLMENTS_FRAGMENT}
  ${LOAN_EXPECTED_INSTALLMENTS_FRAGMENT}
`

const CONFIRM_LOAN_APPLICATION = gql`
  mutation ConfirmLoanApplication($id: ID!, $confirmedAt: DateTime) {
    confirmLoanApplication(id: $id, confirmedAt: $confirmedAt) {
      id
      ...loanParameters
      ...loanExtendedParameters
      ...loanNotes
      ...loanInstallments
      ...expectedInstallments
    }
  }
  ${LOAN_PARAMETERS_FRAGMENT}
  ${LOAN_EXTENDED_PARAMETERS_FRAGMENT}
  ${LOAN_NOTES_FRAGMENT}
  ${LOAN_INSTALLMENTS_FRAGMENT}
  ${LOAN_EXPECTED_INSTALLMENTS_FRAGMENT}
`

const REJECT_LOAN_APPLICATION = gql`
  mutation RejectLoanApplication($id: ID!, $rejectedBecause: String) {
    rejectLoanApplication(id: $id, rejectedBecause: $rejectedBecause) {
      id
      ...loanParameters
      ...loanExtendedParameters
      ...expectedInstallments
    }
  }
  ${LOAN_PARAMETERS_FRAGMENT}
  ${LOAN_EXTENDED_PARAMETERS_FRAGMENT}
  ${LOAN_EXPECTED_INSTALLMENTS_FRAGMENT}
`
const PROPOSE_LOAN_APPLICATION = gql`
  mutation ProposeLoanApplication(
    $loanId: ID!,
    $principal: Float!,
    $delayPeriods: Int!,
    $periods: Int!,
    $apr: Float!
  ) {
    proposeLoanApplication(
      input: {
        loan_id: $loanId,
        principal: $principal,
        delay_periods: $delayPeriods,
        periods: $periods,
        apr: $apr
      }
    ) {
      id
      ...loanParameters
      ...loanExtendedParameters
      ...expectedInstallments
    }
  }
  ${LOAN_PARAMETERS_FRAGMENT}
  ${LOAN_EXTENDED_PARAMETERS_FRAGMENT}
  ${LOAN_EXPECTED_INSTALLMENTS_FRAGMENT}
`

const CLOSE_LOAN = gql`
  mutation CloseLoan(
    $id: ID!,
    $closedAt: DateTime,
    $closedBecause: String
  ) {
    closeLoan(
      id: $id,
      closedAt: $closedAt,
      closedBecause: $closedBecause
    ) {
      id
      ...loanParameters
      ...loanExtendedParameters
      ...loanInstallments
    }
  }
  ${LOAN_PARAMETERS_FRAGMENT}
  ${LOAN_EXTENDED_PARAMETERS_FRAGMENT}
  ${LOAN_INSTALLMENTS_FRAGMENT}
`

const GET_LOAN_NOTES = gql`
  query GetLoanNotes(
    $id: ID!,
    $trashed: Trashed
  ) {
    getLoanNotes(
      id: $id,
      trashed: $trashed
    ) {
      id
      has_relevant_notes
      has_important_notes

      notes(trashed: $trashed) {
        id
        ...loanNoteAttributes
      }
    }
  }
  ${LOAN_NOTE_ATTRIBUTES_FRAGMENT}
`

const GET_LOAN_ATTACHMENTS = gql`
  query GetUser($id: ID!) {
    loan(id: $id) {
      id
      ...loanAttachments
    }
  }
  ${LOAN_ATTACHMENTS_FRAGMENT}
`

const SAVE_LOAN_NOTE = gql`
  mutation SaveLoanNote(
    $loan: ID!,
    $content: String!,
    $importance: LoanNoteImportance!,
    $relevantUntil: DateTime
  ) {
    saveLoanNote(
      loan: $loan,
      content: $content,
      importance: $importance,
      relevantUntil: $relevantUntil
    ) {
      id
      has_relevant_notes
      has_important_notes

      notes(trashed: WITHOUT) {
        id
        ...loanNoteAttributes
      }
    }
  }
  ${LOAN_NOTE_ATTRIBUTES_FRAGMENT}
`

const DELETE_LOAN_NOTE = gql`
  mutation DeleteLoanNote($id: ID!) {
    deleteLoanNote(id: $id) {
      id
      has_relevant_notes
      has_important_notes

      notes(trashed: WITHOUT) {
        id
        ...loanNoteAttributes
      }
    }
  }
  ${LOAN_NOTE_ATTRIBUTES_FRAGMENT}
`

export {
  GET_LOANS_BY_STATUS,
  GET_LOAN,
  GET_LOAN_BY_AGREEMENT_NO,
  GET_LOAN_REQUEST_DOCUMENT_PACKAGE_LINK,
  AUTHORIZE_LOAN_APPLICATION,
  CONFIRM_LOAN_APPLICATION,
  REJECT_LOAN_APPLICATION,
  PROPOSE_LOAN_APPLICATION,
  CLOSE_LOAN,
  SAVE_LOAN_NOTE,
  GET_LOAN_NOTES,
  GET_LOAN_ATTACHMENTS,
  GET_LOAN_USER_PAYMENTS,
  GET_LOAN_MESSAGE_LOG,
  DELETE_LOAN_NOTE,
}