import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Form, FormGroup, Label, Input, Button, Spinner, FormFeedback } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'

import LoadableContent from 'shared/components/LoadableContent'
import { GET_OPTION } from 'shared/models/Options/queries'
import { CLOSE_LOAN } from '../../queries'
import { datetimeInputPattern } from 'shared/config/format'

interface Props {
  id: any,
}

const LoanClose: React.FC<Props> = ({ id }) => {
  const [ reasonClassificator, setReasonClassificator ] = useState<any[]>()
  const dateFormat = 'yyyy-MM-dd HH:mm:ss'
  const [ closedAt, setClosedAt ] = useState<Date>(new Date())

  const { loading, networkStatus } = useQuery(GET_OPTION, {
    variables: { key: 'loan_closed_reason_classificator' },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data: any ) => {
      if (!data?.option?.option_value) return
      setReasonClassificator(JSON.parse(data.option.option_value))
    }
  })
  
  const { register, handleSubmit, errors, reset } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      closedAt      : format(new Date(), dateFormat),
      closedBecause : '',
    },
  })
  
  const [ closeLoan, {
    loading: closingLoan, error: closeLoanError,
  } ] = useMutation(CLOSE_LOAN, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
  })

  const onSubmit = async (data: any) => {
    try {
      await closeLoan({variables: {
        id,
        closedAt: format(closedAt, dateFormat),
        closedBecause: data.closedBecause,
      }})
      reset()
    } catch (e) {
      // ...
    }
  }

  return (
    <LoadableContent ns={networkStatus} loading={loading}>
      {reasonClassificator &&
        <Form
          onSubmit={handleSubmit(onSubmit)}
          className="pt-4 pb-2"
          autoComplete="off"
        >
          <FormGroup row>
            <Label
              for="closedAt"
              className="col-form-label col-4 text-right"
            >Uždarymo data</Label>
            <div className="col">
              <div className={errors.closedAt ? 'is-invalid' : ''}>
                <DatePicker
                  name="closedAt"
                  className={errors.closedAt ? 'is-invalid' : ''}
                  selected={closedAt}
                  dateFormat={dateFormat}
                  todayButton="Šiandien"
                  onChange={(value: Date|null) => value && setClosedAt(value)}
                  wrapperClassName="w-100"
                  withPortal
                  customInput={(
                    <Input
                      id="closedAt"
                      type="text"
                      bsSize="sm"
                      innerRef={register({
                        required: true,
                        pattern: datetimeInputPattern,
                      })}
                    />
                  )}
                />
              </div>
              <FormFeedback valid={!(errors.closedAt?.type === 'required')}>Įvesikte datą ir laiką</FormFeedback>
              <FormFeedback valid={!(errors.closedAt?.type === 'pattern')}>Patikrinkite datos ir laiko formatą (pvz. <b>2020-01-01 15:43:11</b>)</FormFeedback>
            </div>
            <div className="col-1 px-0"></div>
          </FormGroup>

          <FormGroup row>
            <Label
              for="closedBecause"
              className="col-form-label col-4 text-right"
            >Uždarymo priežastis</Label>
            <div className="col">
              <Input type="select"
                     id="closedBecause" name="closedBecause"
                     className={errors.closedBecause ? 'is-invalid':''}
                     bsSize="sm"
                     innerRef={register({ required: true })}>
                <option key={'empty'}></option>
                {Object.entries(reasonClassificator).map(([key, option]) =>
                  <option key={key} value={key}>{option}</option>
                )}
              </Input>
            </div>
            <div className="col-1 px-0"></div>
          </FormGroup>

          <FormGroup row>
            <Label for="date" className="col-4 text-right align-self-center">
              <Spinner size="sm" color="primary" className={closingLoan ? 'visible' : 'invisible'} />
            </Label>
            <div className="col-md-auto">
              <Button
                color="outline-primary"
                type="submit"
                className="w-100 w-md-auto"
                disabled={closingLoan}
              >Uždaryti</Button>
            </div>
            <div className="col align-self-center">
              {closeLoanError && <div className="text-danger">Nenumatyta klaida</div>}
            </div>
          </FormGroup>
        </Form>}
    </LoadableContent>
  )
}


export default LoanClose