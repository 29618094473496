export type LoanStatusType = 'ANY' | 'UNAUTHORIZED' | 'PENDING' | 'ACTIVE' | 'CLOSED' | 'REJECTED'

type LoanStatusEnum = {
  [key: string]: LoanStatusType
}

export const LoanStatus: LoanStatusEnum = {
  ANY           : 'ANY',
  UNAUTHORIZED  : 'UNAUTHORIZED',
  PENDING       : 'PENDING',
  REJECTED      : 'REJECTED',
  ACTIVE        : 'ACTIVE',
  CLOSED        : 'CLOSED',
}


export type LoanRepaymentStatusType = 'ANY' | 'DRY' | 'LATE_AND_DRY' | 'ONTIME' | 'APPROACHING' | 'LATE' | 'OVERDUE'

type LoanRepaymentStatusEnum = {
  [key: string]: LoanRepaymentStatusType
}

export const LoanRepaymentStatus: LoanRepaymentStatusEnum = {
  ANY           : 'ANY',
  ONTIME        : 'ONTIME',
  DRY           : 'DRY',
  LATE_AND_DRY  : 'LATE_AND_DRY',
  APPROACHING   : 'APPROACHING',
  LATE          : 'LATE',
  OVERDUE       : 'OVERDUE',
}


