import React, { useEffect, useReducer } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { Col, Form, FormGroup, Input, Label, Row, Spinner } from 'reactstrap'
import LoadableContent from 'shared/components/LoadableContent'
import { GET_OPTIONS, UPDATE_OPTION } from 'shared/models/Options/queries'

const REPAYMENT_SCHEDULE_OPTIONS = {
  annuity: {
    nicename: 'Anuitetas',
  },
  linear: {
    nicename: 'Linijinis',
  },
  balloon: {
    nicename: 'Perpetuitetas',
  },
}

const General: React.FC = () => {
  const { register, setValue, watch } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  })
  
  const schedules = watch(Object.keys(REPAYMENT_SCHEDULE_OPTIONS).map((key: string) => key+'ScheduleEnabled')); 
  
  const { data, loading, networkStatus } = useQuery(GET_OPTIONS, {notifyOnNetworkStatusChange: true})
  const [ updateOption ] = useMutation(UPDATE_OPTION, { errorPolicy: 'all' })
  
  const [ nowUpdating, setNowUpdating ] = useReducer((state: any[], action: any) => {
    switch (action.type) {
      case 'push':
        return [...state, action.key]
      case 'pop':
        return state.filter(element => element !== action.key)
      case 'reset':
        return []
      default:
        throw new Error()
    }
  }, [])

  useEffect(() => {
    if (!data?.options) return

    data.options.forEach((option: any) => {
      setValue(option.option_key, option.option_value)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  
  const onOptionChange = (key: string, value: any) => {
    setNowUpdating({type: 'push', key})

    updateOption({variables: {key, value}})
      .then(() => {
        setNowUpdating({type: 'pop', key})
      })
  }

  const isScheduleEnabled = (key: string) => schedules && schedules[key + 'ScheduleEnabled']

  return (
    <LoadableContent ns={networkStatus} loading={loading}>
    {data &&
      <Form autoComplete="off" className="pt-3">
        <FormGroup row>
          <Label
            for="isRegistrationEnabled"
            className="col-form-label col-form-label-sm col-4 col-md-3 text-left"
          >Registracija aktyvi</Label>
          <div className="col">
            <Input
              id="isRegistrationEnabled" name="isRegistrationEnabled"
              bsSize="sm" type="checkbox" className="ml-0"
              onChange={(e) => onOptionChange(e.target.name, e.target.checked ? '1':'0')}
              innerRef={register}
            />
          </div>
          <div className="col-1 px-0 align-self-center">
            <Spinner size="sm" color="primary" className={~nowUpdating.indexOf('isRegistrationEnabled') ? 'visible' : 'invisible'} />
          </div>
        </FormGroup>

        <FormGroup row>
          <Label
            for="isNewLoanApplicationEnabled"
            className="col-form-label col-form-label-sm col-4 col-md-3 text-left"
          >Naujos paraiškos priimamos</Label>
          <div className="col">
            <Input
              id="isNewLoanApplicationEnabled" name="isNewLoanApplicationEnabled"
              bsSize="sm" type="checkbox" className="ml-0"
              onChange={(e) => onOptionChange(e.target.name, e.target.checked ? '1':'0')}
              innerRef={register}
            />
          </div>
          <div className="col-1 px-0 align-self-center">
            <Spinner size="sm" color="primary" className={~nowUpdating.indexOf('isNewLoanApplicationEnabled') ? 'visible' : 'invisible'} />
          </div>
        </FormGroup>

        <h3 className="mt-4 mb-3">Nustatymai pagal grafiką</h3>

        <Row>
          <Col className="d-none d-lg-block" lg>
            <h5>&nbsp;</h5>

            <FormGroup>
              <Label className="col-form-label col-form-label-sm text-right">Aktyvus</Label>
            </FormGroup>
            <FormGroup>
              <Label className="col-form-label col-form-label-sm text-right">Periodo trukmė</Label>
            </FormGroup>
            <FormGroup>
              <Label className="col-form-label col-form-label-sm text-right">Maksimalus atidėjimas (periodais)</Label>
            </FormGroup>
            <FormGroup>
              <Label className="col-form-label col-form-label-sm text-right">Minimali suma</Label>
            </FormGroup>
            <FormGroup>
              <Label className="col-form-label col-form-label-sm text-right">Maksimali suma</Label>
            </FormGroup>
            <FormGroup>
              <Label className="col-form-label col-form-label-sm text-right">Minimali trukmė</Label>
            </FormGroup>
            <FormGroup>
              <Label className="col-form-label col-form-label-sm text-right">Maksimali trukmė</Label>
            </FormGroup>
          </Col>
          
          {Object.entries(REPAYMENT_SCHEDULE_OPTIONS).map(([key, option]) =>
            <Col key={key} md={12} lg>
              <h5>{option.nicename}</h5>

              <FormGroup row>
                <Label
                  for={`${key}ScheduleEnabled`}
                  className="col-form-label col-form-label-sm col-4 text-left d-lg-none"
                >Aktyvus</Label>
                <div className="col">
                  <Input
                    id={`${key}ScheduleEnabled`} name={`${key}ScheduleEnabled`}
                    bsSize="sm" type="checkbox" className="position-relative ml-0 align-middle"
                    onChange={(e) => onOptionChange(e.target.name, e.target.checked ? '1':'0')}
                    innerRef={register}
                  />
                </div>
                <div className="col-1 px-0 align-self-center">
                  <Spinner size="sm" color="primary" className={~nowUpdating.indexOf(`${key}ScheduleEnabled`) ? 'visible' : 'invisible'} />
                </div>
              </FormGroup>

              <FormGroup row>
                <Label
                  for={`${key}PeriodDuration`}
                  className="col-form-label col-form-label-sm col-4 text-left d-lg-none"
                >Periodo trukmė</Label>
                <div className="col">
                  <Input
                    id={`${key}PeriodDuration`} name={`${key}PeriodDuration`}
                    bsSize="sm" type="select"
                    disabled={!isScheduleEnabled(key)}
                    onBlur={(e) => onOptionChange(e.target.name, e.target.value)}
                    innerRef={register}
                  >
                    <option value={'monthly'}>mėnuo</option>
                  </Input>
                </div>
                <div className="col-1 px-0 align-self-center">
                  <Spinner size="sm" color="primary" className={~nowUpdating.indexOf(`${key}PeriodDuration`) ? 'visible' : 'invisible'} />
                </div>
              </FormGroup>

              <FormGroup row>
                <Label
                  for={`${key}MaximumDelayPeriods`}
                  className="col-form-label col-form-label-sm col-4 text-left d-lg-none"
                >Maksimalus atidėjimas (periodais)</Label>
                <div className="col">
                  <Input
                    id={`${key}MaximumDelayPeriods`} name={`${key}MaximumDelayPeriods`}
                    bsSize="sm" type="number" step="1"
                    disabled={!isScheduleEnabled(key)}
                    onBlur={(e) => onOptionChange(e.target.name, e.target.value)}
                    innerRef={register}
                  />
                </div>
                <div className="col-1 px-0 align-self-center">
                  <Spinner size="sm" color="primary" className={~nowUpdating.indexOf(`${key}MaximumDelayPeriods`) ? 'visible' : 'invisible'} />
                </div>
              </FormGroup>

              <FormGroup row>
                <Label
                  for={`${key}MinimumLoanAmount`}
                  className="col-form-label col-form-label-sm col-4 text-left d-lg-none"
                >Minimali suma</Label>
                <div className="col">
                  <Input
                    id={`${key}MinimumLoanAmount`} name={`${key}MinimumLoanAmount`}
                    bsSize="sm" type="number" step="1"
                    disabled={!isScheduleEnabled(key)}
                    onBlur={(e) => onOptionChange(e.target.name, e.target.value)}
                    innerRef={register}
                  />
                </div>
                <div className="col-1 px-0 align-self-center">
                  <Spinner size="sm" color="primary" className={~nowUpdating.indexOf(`${key}MinimumLoanAmount`) ? 'visible' : 'invisible'} />
                </div>
              </FormGroup>

              <FormGroup row>
                <Label
                  for={`${key}MaximumLoanAmount`}
                  className="col-form-label col-form-label-sm col-4 text-left d-lg-none"
                >Maksimali suma</Label>
                <div className="col">
                  <Input
                    id={`${key}MaximumLoanAmount`} name={`${key}MaximumLoanAmount`}
                    bsSize="sm" type="number" step="1"
                    disabled={!isScheduleEnabled(key)}
                    onBlur={(e) => onOptionChange(e.target.name, e.target.value)}
                    innerRef={register}
                  />
                </div>
                <div className="col-1 px-0 align-self-center">
                  <Spinner size="sm" color="primary" className={~nowUpdating.indexOf(`${key}MaximumLoanAmount`) ? 'visible' : 'invisible'} />
                </div>
              </FormGroup>

              <FormGroup row>
                <Label
                  for={`${key}MinimumLoanDuration`}
                  className="col-form-label col-form-label-sm col-4 text-left d-lg-none"
                >Minimali trukmė</Label>
                <div className="col">
                  <Input
                    id={`${key}MinimumLoanDuration`} name={`${key}MinimumLoanDuration`}
                    bsSize="sm" type="number" step="1"
                    disabled={!isScheduleEnabled(key)}
                    onBlur={(e) => onOptionChange(e.target.name, e.target.value)}
                    innerRef={register}
                  />
                </div>
                <div className="col-1 px-0 align-self-center">
                  <Spinner size="sm" color="primary" className={~nowUpdating.indexOf(`${key}MinimumLoanDuration`) ? 'visible' : 'invisible'} />
                </div>
              </FormGroup>

              <FormGroup row>
                <Label
                  for={`${key}MaximumLoanDuration`}
                  className="col-form-label col-form-label-sm col-4 text-left d-lg-none"
                >Maksimali trukmė</Label>
                <div className="col">
                  <Input
                    id={`${key}MaximumLoanDuration`} name={`${key}MaximumLoanDuration`}
                    bsSize="sm" type="number" step="1"
                    disabled={!isScheduleEnabled(key)}
                    onBlur={(e) => onOptionChange(e.target.name, e.target.value)}
                    innerRef={register}
                  />
                </div>
                <div className="col-1 px-0 align-self-center">
                  <Spinner size="sm" color="primary" className={~nowUpdating.indexOf(`${key}MaximumLoanDuration`) ? 'visible' : 'invisible'} />
                </div>
              </FormGroup>
            </Col>
          )}
        </Row>

        <h5 className="mt-2">Pradinės paraiškos reikšmės</h5>
        <Row>
          <Col className="d-none d-lg-block" lg>
            <FormGroup>
              <Label className="col-form-label col-form-label-sm text-right">Suma</Label>
            </FormGroup>
            <FormGroup>
              <Label className="col-form-label col-form-label-sm text-right">Trukmė</Label>
            </FormGroup>
            <FormGroup>
              <Label className="col-form-label col-form-label-sm text-right">Atidėjimas</Label>
            </FormGroup>
          </Col>
          
          {Object.entries(REPAYMENT_SCHEDULE_OPTIONS).map(([key, option]) =>
            <Col key={key} md={12} lg>
              <h5 className="d-lg-none">{option.nicename}</h5>

              <FormGroup row>
                <Label
                  for={`${key}InitialLoanAmount`}
                  className="col-form-label col-form-label-sm col-4 text-left d-lg-none"
                >Pradinė paskolos suma</Label>
                <div className="col">
                  <Input
                    id={`${key}InitialLoanAmount`} name={`${key}InitialLoanAmount`}
                    bsSize="sm" type="number" step="1"
                    disabled={!isScheduleEnabled(key)}
                    onBlur={(e) => onOptionChange(e.target.name, e.target.value)}
                    innerRef={register}
                  />
                </div>
                <div className="col-1 px-0 align-self-center">
                  <Spinner size="sm" color="primary" className={~nowUpdating.indexOf(`${key}InitialLoanAmount`) ? 'visible' : 'invisible'} />
                </div>
              </FormGroup>

              <FormGroup row>
                <Label
                  for={`${key}InitialLoanDuration`}
                  className="col-form-label col-form-label-sm col-4 text-left d-lg-none"
                >Pradinė paskolos trukmė</Label>
                <div className="col">
                  <Input
                    id={`${key}InitialLoanDuration`} name={`${key}InitialLoanDuration`}
                    bsSize="sm" type="number" step="1"
                    disabled={!isScheduleEnabled(key)}
                    onBlur={(e) => onOptionChange(e.target.name, e.target.value)}
                    innerRef={register}
                  />
                </div>
                <div className="col-1 px-0 align-self-center">
                  <Spinner size="sm" color="primary" className={~nowUpdating.indexOf(`${key}InitialLoanDuration`) ? 'visible' : 'invisible'} />
                </div>
              </FormGroup>
              
              <FormGroup row>
                <Label
                  for={`${key}InitialLoanDelay`}
                  className="col-form-label col-form-label-sm col-4 text-left d-lg-none"
                >Pradinė paskolos suma</Label>
                <div className="col">
                  <Input
                    id={`${key}InitialLoanDelay`} name={`${key}InitialLoanDelay`}
                    bsSize="sm" type="number" step="1"
                    disabled={!isScheduleEnabled(key)}
                    onBlur={(e) => onOptionChange(e.target.name, e.target.value)}
                    innerRef={register}
                  />
                </div>
                <div className="col-1 px-0 align-self-center">
                  <Spinner size="sm" color="primary" className={~nowUpdating.indexOf(`${key}InitialLoanDelay`) ? 'visible' : 'invisible'} />
                </div>
              </FormGroup>
            </Col>
          )}
        </Row>
        
        <h5 className="mt-2">Išankstinio grąžinimo struktūra</h5>
        <Row>
          <Col className="d-none d-lg-block" lg>
            <FormGroup>
              <Label className="col-form-label col-form-label-sm text-right">Negrąžintas kūnas</Label>
            </FormGroup>
            <FormGroup>
              <Label className="col-form-label col-form-label-sm text-right">Negrąžintos palūkanos</Label>
            </FormGroup>
            <FormGroup>
              <Label className="col-form-label col-form-label-sm text-right">Procentas nuo negrąžintų palūkanų</Label>
            </FormGroup>
            <FormGroup>
              <Label className="col-form-label col-form-label-sm text-right">Fiksuota dalis</Label>
            </FormGroup>
          </Col>
          
          {Object.entries(REPAYMENT_SCHEDULE_OPTIONS).map(([key, option]) =>
            <Col key={key} md={12} lg>
              <h5 className="d-lg-none">{option.nicename}</h5>

              <FormGroup row>
                <Label
                  for={`${key}EarlyRepaymentIncludeRemainingPrincipal`}
                  className="col-form-label col-form-label-sm col-4 text-left d-lg-none"
                >Negrąžintas kūnas</Label>
                <div className="col">
                  <Input
                    id={`${key}EarlyRepaymentIncludeRemainingPrincipal`} name={`${key}EarlyRepaymentIncludeRemainingPrincipal`}
                    bsSize="sm" type="checkbox" className="position-relative ml-0 align-middle"
                    onChange={(e) => onOptionChange(e.target.name, e.target.checked ? '1':'0')}
                    innerRef={register}
                  />
                </div>
                <div className="col-1 px-0 align-self-center">
                  <Spinner size="sm" color="primary" className={~nowUpdating.indexOf(`${key}EarlyRepaymentIncludeRemainingPrincipal`) ? 'visible' : 'invisible'} />
                </div>
              </FormGroup>

              <FormGroup row>
                <Label
                  for={`${key}EarlyRepaymentIncludeRemainingInterest`}
                  className="col-form-label col-form-label-sm col-4 text-left d-lg-none"
                >Negrąžintos palūkanos</Label>
                <div className="col">
                  <Input
                    id={`${key}EarlyRepaymentIncludeRemainingInterest`} name={`${key}EarlyRepaymentIncludeRemainingInterest`}
                    bsSize="sm" type="checkbox" className="position-relative ml-0 align-middle"
                    onChange={(e) => onOptionChange(e.target.name, e.target.checked ? '1':'0')}
                    innerRef={register}
                  />
                </div>
                <div className="col-1 px-0 align-self-center">
                  <Spinner size="sm" color="primary" className={~nowUpdating.indexOf(`${key}EarlyRepaymentIncludeRemainingInterest`) ? 'visible' : 'invisible'} />
                </div>
              </FormGroup>
              
              <FormGroup row>
                <Label
                  for={`${key}EarlyRepaymentInludePercentOfRemainingInterest`}
                  className="col-form-label col-form-label-sm col-4 text-left d-lg-none"
                >Procentas nuo negrąžintų palūkanų</Label>
                <div className="col">
                  <Input
                    id={`${key}EarlyRepaymentInludePercentOfRemainingInterest`} name={`${key}EarlyRepaymentInludePercentOfRemainingInterest`}
                    bsSize="sm" type="number" step="1"
                    disabled={!isScheduleEnabled(key)}
                    onBlur={(e) => onOptionChange(e.target.name, e.target.value)}
                    innerRef={register}
                  />
                </div>
                <div className="col-1 px-0 align-self-center">
                  <Spinner size="sm" color="primary" className={~nowUpdating.indexOf(`${key}EarlyRepaymentInludePercentOfRemainingInterest`) ? 'visible' : 'invisible'} />
                </div>
              </FormGroup>
              
              <FormGroup row>
                <Label
                  for={`${key}EarlyRepaymentFixedPrice`}
                  className="col-form-label col-form-label-sm col-4 text-left d-lg-none"
                >Fiksuota dalis</Label>
                <div className="col">
                  <Input
                    id={`${key}EarlyRepaymentFixedPrice`} name={`${key}EarlyRepaymentFixedPrice`}
                    bsSize="sm" type="number" step="1"
                    disabled={!isScheduleEnabled(key)}
                    onBlur={(e) => onOptionChange(e.target.name, e.target.value)}
                    innerRef={register}
                  />
                </div>
                <div className="col-1 px-0 align-self-center">
                  <Spinner size="sm" color="primary" className={~nowUpdating.indexOf(`${key}EarlyRepaymentFixedPrice`) ? 'visible' : 'invisible'} />
                </div>
              </FormGroup>
            </Col>
          )}
        </Row>

      </Form>}
    </LoadableContent>
  )
}

export default General