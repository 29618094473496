import { gql } from '@apollo/client'
import { LOAN_EXTENDED_PARAMETERS_FRAGMENT, LOAN_INSTALLMENTS_FRAGMENT, LOAN_PARAMETERS_FRAGMENT } from 'shared/models/Loan/fragments'

const EDIT_LOAN_INSTALLMENT = gql`
  mutation EditLoanInstallment(
    $id: ID!,
    $principalDiscount: String!,
    $interestDiscount: String!
  ) {
    editLoanInstallment(
      id: $id,
      principalDiscount: $principalDiscount,
      interestDiscount: $interestDiscount
    ) {
      id
      ...loanParameters
      ...loanExtendedParameters
      ...loanInstallments
    }
  }
  ${LOAN_PARAMETERS_FRAGMENT}
  ${LOAN_EXTENDED_PARAMETERS_FRAGMENT}
  ${LOAN_INSTALLMENTS_FRAGMENT}
`

export {
  EDIT_LOAN_INSTALLMENT,
}