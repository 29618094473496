import React, { useState } from 'react'
import { Pagination as RSPagination, PaginationItem, PaginationLink } from 'reactstrap'
import { NavLink, useRouteMatch, useParams } from 'react-router-dom'

interface Props {
  paginator: any,
  urlBased?: boolean,
  paginatorCallback?: (page: number) => void,
}

const Pagination: React.FC<Props> = ({ paginator, urlBased = false, paginatorCallback }) => {
  const { page } = useParams<any>()
  const [ pageState, setPageState ] = useState<number>(1)

  const match = useRouteMatch<any>()
  const pageDisplayCount: number = 12
  const { lastPage } = paginator

  if (!match) return null
  if (!paginator) return null
  if (1 >= lastPage) return null

  const getTargetUrl = (page: number): string => (
    match && match.params && match.params.page
      ? match.path.replace(':page', String(page))
      : match.path + '/' + page
  )

  const handleClick = (page: number) => {
    paginatorCallback && paginatorCallback(page)
    setPageState(page)
  }

  return (
    <RSPagination size="md">
      <PaginationItem>
        {urlBased
          ? <PaginationLink first tag={NavLink} activeClassName="active" to={getTargetUrl(1)} />
          : <PaginationLink first tag={'button'} onClick={() => handleClick(1)} />}
      </PaginationItem>
      
      {lastPage > pageDisplayCount &&
        <PaginationItem>
          {urlBased
            ? <PaginationLink previous tag={NavLink} activeClassName="active" to={getTargetUrl(Math.max(1, Number(page) - 1))} />
            : <PaginationLink previous tag={'button'}onClick={() => handleClick(Math.max(1, Number(pageState) - 1))} />}
        </PaginationItem>}

      {lastPage && [...Array(lastPage)].map((e, i) => {
        const p = i + 1
        return (
          <PaginationItem key={i}>
            {urlBased
              ? <PaginationLink tag={NavLink} activeClassName="active" to={getTargetUrl(p)}>{p}</PaginationLink>
              : <PaginationLink tag={'button'} onClick={() => handleClick(p)}>{p}</PaginationLink>}
          </PaginationItem>
        )
      })}

      {lastPage > pageDisplayCount &&
        <PaginationItem>
          {urlBased
            ? <PaginationLink next tag={NavLink} activeClassName="active" to={getTargetUrl(Math.min(lastPage, Number(page) + 1))} />
            : <PaginationLink next tag={'button'} onClick={() => handleClick(Math.min(lastPage, pageState + 1))} />}
        </PaginationItem>}

      <PaginationItem>
        {urlBased
          ? <PaginationLink last tag={NavLink} activeClassName="active" to={getTargetUrl(lastPage)} />
          : <PaginationLink last tag={'button'} onClick={() => handleClick(lastPage)} />}
      </PaginationItem>
      
      {paginator.total > 5 &&
        <span className="align-self-center ml-2">
          {paginator.firstItem} &mdash; {paginator.lastItem} iš {paginator.total}
        </span>}
    </RSPagination>
  )
}

export default Pagination