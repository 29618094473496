import { gql } from '@apollo/client'
import { ATTACHMENT_DETAILS_FRAGMENT } from '../Attachment/fragments'
import { MESSAGE_LOG_ENTRY_ATTRIBUTES, MESSAGE_LOG_PAGINATOR } from '../MessageLog/fragments'
import { USER_PAYMENT_BASE_FIELDS_FRAGMENT } from '../UserPayment/fragments'

const LOAN_PARAMETERS_FRAGMENT = gql`
  fragment loanParameters on Loan {
    agreement_no
    
    borrower {
      id
      first_name
      last_name

      customer {
        score
        classificator
      }
    }

    type
    purpose
    principal
    interest
    apr
    air
    delay_periods
    periods
    periods_per_year
    early_repayment

    has_relevant_notes
    has_important_notes

    next_payment_at
    last_paid_at
    principal_paid
    interest_paid

    principal_discount
    interest_discount

    requested_at
    authorized_at
    rejected_at
    issued_at
    confirmed_at
    closed_at
  }
`

const LOAN_EXTENDED_PARAMETERS_FRAGMENT = gql`
  fragment loanExtendedParameters on Loan {
    confirmed_by {
      id
      first_name
      last_name
    }

    rejected_by {
      id
      first_name
      last_name
    }
    rejected_because

    closed_by {
      id
      first_name
      last_name
    }
    closed_because
  }
`

const LOAN_USER_PAYMENTS_FRAGMENT = gql`
  fragment loanUserPayments on Loan {
    user_payments {
      id
      ...userPaymentBaseFields
    }
  }
  ${USER_PAYMENT_BASE_FIELDS_FRAGMENT}
`

const LOAN_NOTE_ATTRIBUTES_FRAGMENT = gql`
  fragment loanNoteAttributes on LoanNote {
    content
    is_relevant
    relevant_until
    importance

    created_by {
      id
      first_name
      last_name
    }
    created_at

    deleted_by {
      id
      first_name
      last_name
    }
    deleted_at
  }
`

const LOAN_NOTES_FRAGMENT = gql`
  fragment loanNotes on Loan {
    has_relevant_notes
    has_important_notes
    
    notes(trashed: WITHOUT) {
      id
      ...loanNoteAttributes
    }
  }
  ${LOAN_NOTE_ATTRIBUTES_FRAGMENT}
`

const LOAN_MESSAGE_LOG_FRAGMENT = gql`
  fragment loanMessageLog on Loan {    
    message_log(first: $first, page: $page) {
      ...messageLogPaginator
      data {
        id
        ...messageLogEntryAttributes
      }
    }
  }
  ${MESSAGE_LOG_ENTRY_ATTRIBUTES}
  ${MESSAGE_LOG_PAGINATOR}
`

const LOAN_ATTACHMENTS_FRAGMENT = gql`
  fragment loanAttachments on Loan {
    attachments {
      id
      ...attachmentDetails
    }
  }
  ${ATTACHMENT_DETAILS_FRAGMENT}
`

const LOAN_INSTALLMENTS_BASE_FRAGMENT = gql`
  fragment loanInstallmentsBase on LoanInstallment {
    id
    expected_at
    principal
    interest
  }
`

const LOAN_INSTALLMENTS_FRAGMENT = gql`
  fragment loanInstallments on Loan {
    installments {
      ...loanInstallmentsBase

      discounted_at
      principal_discount
      interest_discount

      closed_at
      principal_paid
      interest_paid
    }
  }
  ${LOAN_INSTALLMENTS_BASE_FRAGMENT}
`

const LOAN_EXPECTED_INSTALLMENTS_FRAGMENT = gql`
  fragment expectedInstallments on Loan {
    expected_installments {
      ...loanInstallmentsBase
    }
  }
  ${LOAN_INSTALLMENTS_BASE_FRAGMENT}
`

export {
  LOAN_PARAMETERS_FRAGMENT,
  LOAN_EXTENDED_PARAMETERS_FRAGMENT,
  LOAN_USER_PAYMENTS_FRAGMENT,
  LOAN_NOTE_ATTRIBUTES_FRAGMENT,
  LOAN_NOTES_FRAGMENT,
  LOAN_MESSAGE_LOG_FRAGMENT,
  LOAN_ATTACHMENTS_FRAGMENT,
  LOAN_INSTALLMENTS_FRAGMENT,
  LOAN_EXPECTED_INSTALLMENTS_FRAGMENT,
}
