import React from 'react'

import CurrencyDisplay from 'shared/components/CurrencyDisplay'
import {
  getStartingDebt, getCurrentDiscount, getCurrentPayments,
  getCurrentDebt, getCurrentPrincipalDebt, getCurrentInterestDebt
} from 'shared/models/Loan/helpers'

interface Props {
  loan: any,
  columns: {[key:string]: boolean},
}

const LoanScheduleFooter: React.FC<Props> = ({ loan, columns }) => (
  <tfoot>
    <tr key={'subtotal'} className="font-weight-bold">
      <td className="text-right"><span className="text-muted">&ndash;</span></td>
      <td className="text-center"><span className="text-muted">&ndash;</span></td>
      {(columns.daysUntillDeadline || columns.dayOfPayment) && <td><span className="text-muted">&ndash;</span></td>}
      <td className="text-right">
        <CurrencyDisplay value={getStartingDebt(loan)} />
        <small className="d-block text-muted">
          <CurrencyDisplay value={loan.principal} />&#8202;/&#8202;<CurrencyDisplay value={loan.interest} />
        </small>
      </td>
      {columns.discount && <td className="text-right">
        <CurrencyDisplay value={getCurrentDiscount(loan)} />
        <small className="d-block text-muted">
          <CurrencyDisplay value={loan.principal_discount} />&#8202;/&#8202;<CurrencyDisplay value={loan.interest_discount} />
        </small>
      </td>}
      {columns.paidAmount && <td className="text-right">
        <CurrencyDisplay value={getCurrentPayments(loan)} />
        <small className="d-block text-muted">
          <CurrencyDisplay value={loan.principal_paid} />&#8202;/&#8202;<CurrencyDisplay value={loan.interest_paid} />
        </small>
      </td>}
      {columns.payableAmount && <td className="text-right">
        <CurrencyDisplay value={getCurrentDebt(loan)} />
        <small className="d-block text-muted">
          <CurrencyDisplay value={getCurrentPrincipalDebt(loan)} />&#8202;/&#8202;<CurrencyDisplay value={getCurrentInterestDebt(loan)} />
        </small>
      </td>}
      {columns.datePaid && <td className="text-center"><span className="text-muted">&ndash;</span></td>}
    </tr>
  </tfoot>
)

export default LoanScheduleFooter