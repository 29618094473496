import { gql } from '@apollo/client'

const GET_MESSAGE_TEMPLATES = gql`
  query GetMessageTemplates {
    messageTemplates {
      id

      category
      event
      description

      email_enabled
      email_subject
      email_content

      sms_enabled
      sms_content
    }
  }
`

export {
  GET_MESSAGE_TEMPLATES,
}
