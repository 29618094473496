import React from 'react'
import { Modal, ModalBody } from 'reactstrap'

import UserPaymentDetails from './UserPaymentDetails'

interface IProps {
  id: string,
  toggle: () => void,
}

const UserPaymentModal: React.FC<IProps> = ({ id, toggle }) => {
  const actionCallback = (action: string) => {
    switch (action) {
      case 'editUserPayment':
      case 'deleteUserPayment':
        toggle()
        break
    }
  }

  return (
    <Modal isOpen={!!id} toggle={toggle} size="lg">
      <ModalBody>
        <UserPaymentDetails id={id} onAction={actionCallback} />
      </ModalBody>
    </Modal>
  )
}


export default UserPaymentModal