import React from 'react'

interface Props {
  users: [any]
}

const UserListTableBody: React.FC<Props> = ({ users }) => (
  <>
    <tbody>
      {users.map((user: any) => (
        <tr key={user.id}>
          <td>{user.first_name} {user.last_name}</td>
          <td>{user.email}</td>
        </tr>
      ))}
    </tbody>
  </>
)

export default UserListTableBody
