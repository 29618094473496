import React from 'react'
import { Row, Col, UncontrolledTooltip } from 'reactstrap'
import { InfoCircle } from 'react-bootstrap-icons'

import { FormatDate } from 'shared/components/FormatDate'
import CopyToClipboard from 'shared/components/CopyToClipboard'
import CurrencyDisplay from 'shared/components/CurrencyDisplay'
import UserPaymentType from 'components/user-payments/UserPaymentType'
import LoanLink from 'components/loans/LoanLink'
import CustomerLink from 'components/customers/CustomerLink'

interface Props {
  payment: any,
}

const UserPaymentMetadata: React.FC<Props> = ({ payment }) => (
  <Row className="pt-2 pb-3">
    <Col xs={12} lg={6}>
      <Row className="mb-1">
        <Col className="font-weight-bold text-right">Suma:</Col>
        <Col className="text-left">
          <CurrencyDisplay value={payment.amount} />
        </Col>
      </Row>
      <Row className="mb-1">
        <Col className="font-weight-bold text-right">Skolininkas:</Col>
        <Col className="text-left">
          <CustomerLink subject={payment.loan.borrower} opensModal />
        </Col>
      </Row>
      <Row className="mb-1">
        <Col className="font-weight-bold text-right">
          <CopyToClipboard data={payment.loan.agreement_no}>Sutarties nr.:</CopyToClipboard>
        </Col>
        <Col className="text-left">
          <LoanLink loanId={payment.loan.id} />
        </Col>
      </Row>
    </Col>

    <Col xs={12} lg={6}>
      <Row className="mb-1">
        <Col className="font-weight-bold text-right">Tipas:</Col>
        <Col className="text-left">
          <UserPaymentType type={payment.type} />
        </Col>
      </Row>

      <Row className="mb-1">
        <Col className="font-weight-bold text-right">Įmoka gauta:</Col>
        <Col className="text-left">
          <FormatDate format="yyyy-MM-dd HH:mm" date={payment.paid_at} />
        </Col>
      </Row>

      <Row className="mb-1">
        <Col className="font-weight-bold text-right">Įmoka įvesta:</Col>
        <Col className="text-left">
          <FormatDate format="yyyy-MM-dd HH:mm" date={payment.created_at} />
          <span className="align-text-bottom">
            <InfoCircle size="12" className="text-info ml-1" id={'created_by_tooltip-' + payment.id} />
          </span>
          <UncontrolledTooltip target={'created_by_tooltip-' + payment.id}>
            <b>Atliko:</b> {payment.created_by.first_name + ' ' + payment.created_by.last_name}
            {payment.rejected_because && <div>
              <b>Komentaras:</b> {payment.rejected_because}
            </div>}
          </UncontrolledTooltip>
        </Col>
      </Row>
    </Col>
  </Row>
)

export default UserPaymentMetadata