export type MessageTemplateCategoryType = 'ANY' | 'LOAN_APPLICATION' | 'LOAN' | 'CUSTOMER' | 'INSTALLMENT'

type MessageTemplateCategoryEnum = {
  [key: string]: MessageTemplateCategoryType
}

export const MessageTemplateCategory: MessageTemplateCategoryEnum = {
  ANY               : 'ANY',
  LOAN_APPLICATION  : 'LOAN_APPLICATION',
  INSTALLMENT       : 'INSTALLMENT',
  LOAN              : 'LOAN',
  CUSTOMER          : 'CUSTOMER',
}