import React from 'react'

interface IProps {
  type: string
}

const ScheduleType: React.FC<IProps> = ({ type }) => {
  let element;

  switch (type) {
    case 'LINEAR':
      element = <span className="schedule-type">L</span>
      break
    case 'ANNUITY':
      element = <span className="schedule-type">A</span>
      break
    case 'BALLOON':
      element = <span className="schedule-type">B</span>
      break
  }

  return element || <React.Fragment />
}

export default ScheduleType
