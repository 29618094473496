import { gql } from '@apollo/client'

import { LOAN_PARAMETERS_FRAGMENT } from 'shared/models/Loan/fragments'
import { CUSTOMER_DETAILS_FRAGMENT, CUSTOMER_LOAN_STATS_FRAGMENT } from 'shared/models/Customer/fragments'
import { USER_NOTE_ATTRIBUTES_FRAGMENT, USER_SODRA_DATA_ATTRIBUTES_FRAGMENT } from 'shared/models/User/fragments'

const PAGINATOR = gql`
  fragment paginatorInfo on CustomerPaginator {
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      perPage
      total
    }
  }
`

const GET_CUSTOMERS = gql`
  query GetCustomers(
    $hasLoansWithStatus: LoanStatus = ANY,
    $score: Int = null,
    $classificator: Int = null,
    $search: String = null,
    $first: Int = 20,
    $page: Int = 1
  ) {
    customers(
      hasLoansWithStatus: $hasLoansWithStatus,
      score: $score,
      classificator: $classificator,
      search: $search,
      first: $first,
      page: $page
    ) {
      ...paginatorInfo
      data {
        id
        ...customerDetails
        ...customerLoanStats

        user {
          id
          email
          created_at
        }
      }
    }
  }
  ${PAGINATOR}
  ${CUSTOMER_DETAILS_FRAGMENT}
  ${CUSTOMER_LOAN_STATS_FRAGMENT}
`

const GET_USER = gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      id
      first_name
      last_name
      email
      created_at
      customer {
        id
        ...customerDetails
        ...customerLoanStats
      }
    }
  }
  ${CUSTOMER_DETAILS_FRAGMENT}
  ${CUSTOMER_LOAN_STATS_FRAGMENT}
`

const GET_CUSTOMER = gql`
  query GetCustomer($id: ID!) {
    customer(id: $id) {
      id
      ...customerDetails
      ...customerLoanStats

      user {
        id
        email
        created_at
      }
    }
  }
  ${CUSTOMER_DETAILS_FRAGMENT}
  ${CUSTOMER_LOAN_STATS_FRAGMENT}
`

const GET_CUSTOMER_BY_PERSONAL_ID = gql`
  query GetCustomerByPersonalId($personalId: String!) {
    customerByPersonalId(personal_id: $personalId) {
      id
      ...customerDetails
      ...customerLoanStats

      user {
        id
        email
        created_at
      }
    }
  }
  ${CUSTOMER_DETAILS_FRAGMENT}
  ${CUSTOMER_LOAN_STATS_FRAGMENT}
`

const EDIT_CUSTOMER = gql`
  mutation EditCustomer(
    $id: ID!,
    $isMessagingEnabled: Boolean,
  ) {
    editCustomer(
      id: $id,
      isMessagingEnabled: $isMessagingEnabled
    ) {
      id
      ...customerDetails
      ...customerLoanStats

      user {
        id
        email
        created_at
      }
    }
  }
  ${CUSTOMER_DETAILS_FRAGMENT}
  ${CUSTOMER_LOAN_STATS_FRAGMENT}
`

const UPDATE_CUSTOMER_SCORE = gql`
  mutation UpdateCustomerScore(
    $id: ID!,
    $score: Int!,
  ) {
    updateCustomerScore(
      id: $id,
      score: $score
    ) {
      id
      ...customerDetails
      ...customerLoanStats

      user {
        id
        email
        created_at
      }
    }
  }
  ${CUSTOMER_DETAILS_FRAGMENT}
  ${CUSTOMER_LOAN_STATS_FRAGMENT}
`

const UPDATE_CUSTOMER_CLASSIFICATOR = gql`
  mutation UpdateCustomerClassificator(
    $id: ID!,
    $classificator: Int!,
  ) {
    updateCustomerClassificator(
      id: $id,
      classificator: $classificator
    ) {
      id
      ...customerDetails
      ...customerLoanStats

      user {
        id
        email
        created_at
      }
    }
  }
  ${CUSTOMER_DETAILS_FRAGMENT}
  ${CUSTOMER_LOAN_STATS_FRAGMENT}
`

const GET_CUSTOMER_LOANS = gql`
  query GetCustomerLoans($id: ID!) {
    customer(id: $id) {
      id
      loans {
        id
        ...loanParameters
        borrower {
          id
          first_name
          last_name
        }
      }
    }
  }
  ${LOAN_PARAMETERS_FRAGMENT}
`

const GET_CUSTOMER_PAYMENTS = gql`
  query GetCustomerPayments($id: ID!) {
    customer(id: $id) {
      id
      payments {
        id
        paid_at
        created_at
        created_by {
          id
          first_name
          last_name
        }
        loan {
          id
          agreement_no
          borrower {
            id
            first_name
            last_name
          }
        }
        type
        purpose
        amount
      }
    }
  }
`

const GET_RECENTLY_REGISTERED_CUSTOMERS = gql`
  query GetRecentlyRegisteredCustomers {
    customers(first: 10) {
      ...paginatorInfo
      data {
        id
        ...customerDetails

        user {
          id
          first_name
          last_name
          email
          created_at
        }
      }
    }
  }
  ${PAGINATOR}
  ${CUSTOMER_DETAILS_FRAGMENT}
`

const GET_USER_SODRA_DATA = gql`
  query GetSodraData(
    $id: ID!,
    $trashed: Trashed
  ) {
    getSodraData(
      id: $id,
      trashed: $trashed
    ) {
      id

      sodraData(trashed: $trashed) {
        id
        ...userSodraDataAttributes
      }
    }
  }
  ${USER_SODRA_DATA_ATTRIBUTES_FRAGMENT}
`

const QUERY_USER_SODRA_DATA = gql`
  mutation QuerySodraData(
    $user: ID!,
    $firstName: String!,
    $lastName: String!
  ) {
    querySodraData(
      user: $user,
      firstName: $firstName,
      lastName: $lastName
    ) {
      id

      sodraData(trashed: WITHOUT) {
        id
        ...userSodraDataAttributes
      }
    }
  }
  ${USER_SODRA_DATA_ATTRIBUTES_FRAGMENT}
`

const GET_USER_NOTES = gql`
  query GetUserNotes(
    $id: ID!,
    $trashed: Trashed
  ) {
    getUserNotes(
      id: $id,
      trashed: $trashed
    ) {
      id
      has_relevant_notes
      has_important_notes

      notes(trashed: $trashed) {
        id
        ...userNoteAttributes
      }
    }
  }
  ${USER_NOTE_ATTRIBUTES_FRAGMENT}
`

const SAVE_USER_NOTE = gql`
  mutation SaveUserNote(
    $user: ID!,
    $content: String!,
    $importance: UserNoteImportance!,
    $relevantUntil: DateTime
  ) {
    saveUserNote(
      user: $user,
      content: $content,
      importance: $importance,
      relevantUntil: $relevantUntil
    ) {
      id
      has_relevant_notes
      has_important_notes

      notes(trashed: WITHOUT) {
        id
        ...userNoteAttributes
      }
    }
  }
  ${USER_NOTE_ATTRIBUTES_FRAGMENT}
`

const DELETE_USER_NOTE = gql`
  mutation DeleteUserNote($id: ID!) {
    deleteUserNote(id: $id) {
      id
      has_relevant_notes
      has_important_notes

      notes(trashed: WITHOUT) {
        id
        ...userNoteAttributes
      }
    }
  }
  ${USER_NOTE_ATTRIBUTES_FRAGMENT}
`

export {
  GET_RECENTLY_REGISTERED_CUSTOMERS,
  GET_CUSTOMERS,
  GET_CUSTOMER,
  GET_CUSTOMER_BY_PERSONAL_ID,
  EDIT_CUSTOMER,
  UPDATE_CUSTOMER_SCORE,
  UPDATE_CUSTOMER_CLASSIFICATOR,
  GET_USER,
  GET_CUSTOMER_LOANS,
  GET_CUSTOMER_PAYMENTS,

  GET_USER_NOTES,
  SAVE_USER_NOTE,
  DELETE_USER_NOTE,

  GET_USER_SODRA_DATA,
  QUERY_USER_SODRA_DATA,
}