import React from 'react'

interface Props {
  columns: {[key:string]: boolean},
}

const MessageLogHeader: React.FC<Props> = ({ columns }) => (
  <thead className="thead-dark">
    <tr>
      <th scope="col" className="text-center">Išsiųsta</th>
      <th scope="col" className="text-center">Pristatyta</th>
      {columns.description && <th scope="col" className="text-center">Tipas</th>}
      {columns.loan && <th scope="col" className="text-center">Paskola</th>}
      {columns.recipient && <th scope="col" className="text-center">Gavėjas</th>}
      {columns.content && <th scope="col" className="text-left">Pranešimas</th>}
    </tr>
  </thead>
)

export default MessageLogHeader
