import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'

import { LoanStatus } from 'shared/models/Loan/enums'
import Pagination from 'shared/components/pagination'
import { GET_LOANS_BY_STATUS } from './queries'
import LoanList from './LoanList'
import LoadableContent from 'shared/components/LoadableContent'

const PendingLoanApplications: React.FC = () => {
  const [ page, setPage ] = useState<number>(1)
  const [ loans, setLoans ] = useState<any>()
  
  const { data, loading, networkStatus } = useQuery(GET_LOANS_BY_STATUS, {
    variables: {
      status: LoanStatus.PENDING,
      page,
      first: 20,
    },
    notifyOnNetworkStatusChange: true,
    pollInterval: 5 * 1000,
  })

  useEffect(() => {
    if (data?.loans) setLoans(data.loans)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <LoadableContent ns={networkStatus} loading={loading}>
      {loans && (loans.paginatorInfo?.count
        &&  <>
              <h3>Paraiškos</h3>
              <LoanList loans={loans.data} status={LoanStatus.PENDING} />
              <Pagination
                paginator={loans.paginatorInfo}
                paginatorCallback={setPage}
              />
            </>
        ||  <h5 className="py-2 text-center text-muted">Aktyvių paraiškų nėra</h5>)}
    </LoadableContent>
  )
}

export default PendingLoanApplications
