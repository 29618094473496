import { InMemoryCache, ApolloClient } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client';

export const cache = new InMemoryCache()

const httpLink = createUploadLink({
  /* eslint-disable-next-line no-undef */
  uri: process.env.API_URL + '/graphql',
  headers: {
    'keep-alive': 'true',
  }
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')
  
  return {
    headers: {
      ...headers,
      ...(token && {authorization: `Bearer ${token}`})
    }
  }
})

const client = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
  connectToDevTools: true,
  queryDeduplication: true,
})

export default client
