import React, { useReducer } from 'react'

interface ProviderProps {
  pushModal(modal: any): void,
  popModal(key: string): void,
  resetModals(): void,
}

interface ContextProps {
  children: React.ReactNode,
}

export const modalContext = React.createContext<ProviderProps>({
  pushModal: () => undefined,
  popModal: () => undefined,
  resetModals: () => undefined,
})

const { Provider } = modalContext

const ModalContextProvider: React.FC<ContextProps> = ({ children }) => {
  const reducer = (state: any[], action: any) => {
    switch (action.type) {
      case 'push':
        return [...state, action.payload];
      case 'pop':
        return state.filter(element => element.key !== action.payload)
      case 'reset':
        return [];
      default:
        throw new Error();
    }
  }
  const [ modalStack, dispatch ] = useReducer(reducer, [])

  const pushModal = (modal: any) => dispatch({ type: 'push', payload: modal })
  const popModal = (key: string) => dispatch({ type: 'pop', payload: key })
  const resetModals = () => dispatch({ type: 'reset' })

  const providerMethods = {
    pushModal,
    popModal,
    resetModals,
  }

  return (
    <Provider value={providerMethods}>
      {children}
      {modalStack}
    </Provider>
  )
}

export default ModalContextProvider
