export type UserPaymentTypeType = 'ANY' | 'REGISTRATION' | 'INSTALLMENT' | 'EARLY_REPAYMENT'

type UserPaymentTypeEnum = {
  [key: string]: UserPaymentTypeType
}

export const UserPaymentType: UserPaymentTypeEnum = {
  ANY             : 'ANY',
  REGISTRATION    : 'REGISTRATION',
  INSTALLMENT     : 'INSTALLMENT',
  EARLY_REPAYMENT : 'EARLY_REPAYMENT',
}