import React, { useState, useEffect } from 'react'
import cn from 'classnames'
import { useMutation, useQuery } from '@apollo/client'
import { Alert, Table, Nav, NavItem, NavLink, Button } from 'reactstrap'
import { InfoCircleFill, X } from 'react-bootstrap-icons'
import toaster from 'toasted-notes'

import { cache } from 'shared/config/graphql'
import { FormatDateSplit } from 'shared/components/FormatDate'
import ButtonWithConfirmation from 'shared/components/ButtonWithConfirmation'

import UserNoteModal from './UserNoteModal'
import { GET_USER_NOTES, DELETE_USER_NOTE } from '../../queries'
import LoadableContent from 'shared/components/LoadableContent'
import { trimMessage } from 'shared/models/MessageLog/helpers'

interface Props {
  id: any,
  compact?: boolean,
  includeTrashed?: boolean,
}

const UserNotes: React.FC<Props> = ({ id, compact = false, includeTrashed = false }) => {
  const [ notes, setNotes ] = useState([])
  const [ expandedNode, setExpandedNode ] = useState<number>()
  const [ displayNonRelevantNotes, setDisplayNonRelevantNotes ] = useState<boolean>(false)
  const [ displayDeletedNotes, setDisplayDeletedNotes ] = useState<boolean>(false)
  const [ userNoteModal, toggleUserNoteModal ] = useState<boolean>(false)

  const { data, loading, networkStatus } = useQuery(GET_USER_NOTES, {
    variables: {
      id,
      trashed: includeTrashed ? 'WITH' : 'WITHOUT',
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  })
  
  const [ deleteNoteMutation, {
    loading: deletingNote,
  } ] = useMutation(DELETE_USER_NOTE, { errorPolicy: 'all' })

  useEffect(() => {
    data?.getUserNotes?.notes && setNotes(data.getUserNotes.notes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const deleteNote = (note: any) => {
    deleteNoteMutation({ variables: { id: note.id }})
      .then(() => {
        cache.modify({
          id: cache.identify(note),
          fields: (_, {DELETE}) => DELETE,
        })
        toaster.notify('Pastaba ištrinta!', { duration: 5000 })
      })
      .catch(() => toaster.notify('Klaida trinant pastabą!', { duration: 5000 }))
  }

  return (
    <LoadableContent ns={networkStatus} loading={!compact && loading}>
      {!compact &&
        <div className="pb-1 mb-3 border-bottom justify-content-center">
          <Nav pills className="justify-content-end">
            {!!notes.filter((n: any) => !n.is_relevant).length &&
              <NavItem>
                <NavLink
                  tag={Button} color="link" className="btn-sm"
                  disabled={displayNonRelevantNotes}
                  onClick={() => setDisplayNonRelevantNotes(true)}
                >Rodyti negaliojančias pastabas</NavLink>
              </NavItem>}
            {!!notes.filter((n: any) => n.deleted_at).length &&
              <NavItem>
                <NavLink
                  tag={Button} color="link" className="btn-sm"
                  disabled={displayDeletedNotes}
                  onClick={() => setDisplayDeletedNotes(true)}
                >Rodyti ištrintas pastabas</NavLink>
              </NavItem>}
            <NavItem>
              <NavLink
                tag={Button} color="link" className="btn-sm text-success"
                onClick={() => toggleUserNoteModal(true)}
              >Pridėti pastabą</NavLink>
            </NavItem>
          </Nav>

          <UserNoteModal
              isOpen={userNoteModal}
              user={id}
              toggle={() => toggleUserNoteModal(false)}
            />
        </div>}

      {data && notes?.length > 0 &&
        <Alert color={compact ? 'warning' : 'light'} className={compact ? '' : 'p-0'}>
          <Table className="mb-0" striped borderless>
            <tbody>
              {notes
                .filter((n: any) => displayNonRelevantNotes || n.is_relevant)
                .filter((n: any) => displayDeletedNotes || !n.deleted_at)
                .map((note: any) => (
                  <tr
                    key={note.id}
                    className={note.is_relevant && !note.deleted_at ? '' : 'text-muted'}
                    onClick={() => expandedNode === note.id ? setExpandedNode(undefined) : setExpandedNode(note.id)}
                  >
                    <td className="text-center">
                      {note.importance === 'IMPORTANT' && <InfoCircleFill className="text-warning" size="15" />}
                    </td>

                    <td 
                      className={cn(
                        'w-50',
                        {'text-primary': note.content?.length > 40 && expandedNode !== note.id}
                      )} style={{ whiteSpace: 'pre-wrap' }}
                    >{expandedNode === note.id ? note.content : trimMessage(note.content)}</td>

                    <td className="text-center">
                      <FormatDateSplit date={note.created_at} />
                    </td>

                    <td className="text-right">{note.created_by.first_name}</td>
                    
                    {!compact &&
                      <td className="text-right">
                        <ButtonWithConfirmation
                          color="inline"
                          disabled={!!note.deleted_at}
                          className={note.deleted_at ? 'text-muted' : 'text-danger'}
                          buttonLabel={<X size="14"></X>}
                          confirmationTooltip="Tikrai norite ištrinti pastabą?"
                          handleClick={() => deleteNote(note)}
                          displayLoader={deletingNote}
                        />
                      </td>}
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </Alert>}

      {!compact && data && !notes?.length && <div className="display-4 py-5 text-center">Pastabų nėra</div>}
    </LoadableContent>
  )
}

export default UserNotes