const currencyInputPattern = /^[0-9]+([,.][0-9]{1,2})?$/

const dateInputPatternFragment = '([1-2][0-9]{3})-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])'
const dateInputPattern = new RegExp(`^${dateInputPatternFragment}$`)

const timeInputPatternFragment = '([0-1][0-9]|2[0-3])(:[0-5][0-9]){1,2}(:[0-5][0-9]){1,2}'
const timeInputPattern = new RegExp(`^${timeInputPatternFragment}$`)

const datetimeInputPattern = new RegExp(`^${dateInputPatternFragment} ${timeInputPatternFragment}$`)

export { currencyInputPattern, dateInputPattern, timeInputPattern, datetimeInputPattern }