import React from 'react'
import { Form, FormGroup, Label, Input } from 'reactstrap'
import DatePicker from 'react-datepicker'


interface FilterProps {
  dateAfter: any,
  dateBefore: any,
  template: any,
}

interface Props {
  filters: FilterProps,
  updateFilters: (filters: FilterProps) => void,
}

const MessageLogFilter: React.FC<Props> = ({ filters, updateFilters }) => (
  <Form
    onSubmit={e => e.preventDefault()}
    className="pb-2 justify-content-end"
    autoComplete="off"
    inline
  >
    <FormGroup className="mr-4">
      <Label for="user-payment-type" className="mr-1">Šablonas:</Label>
      <Input
        id="user-payment-type" name="user-payment-type"
        type="select"
        bsSize="sm"
        value={filters.template}
        onChange={(e: any) => updateFilters({...filters, template: e.target.value})}
      >
        <option value="">visi</option>
        <option value={"9229ed40-f796-4f80-9b3f-51f020e58083"}>artėjanti įmoka</option>
      </Input>
    </FormGroup>

    <FormGroup className="mr-2">
      <Label for="date-from" className="mx-1">Apmokėta nuo:</Label>
      <DatePicker
        dateFormat="yyyy-MM-dd"
        selected={filters.dateAfter}
        onChange={(value: Date) => updateFilters({...filters, dateAfter: value})}
        customInput={(
          <Input
            id="date-from" name="date-from"
            type="text"
            bsSize="sm"
          />
        )}
      />
    </FormGroup>
    
    <FormGroup>
      <Label for="date-to" className="mr-1">iki:</Label>
      <DatePicker
        dateFormat="yyyy-MM-dd"
        selected={filters.dateBefore}
        onChange={(value: Date) => updateFilters({...filters, dateBefore: value})}
        customInput={(
          <Input
            id="date-to" name="date-to"
            type="text"
            bsSize="sm"
          />
        )}
      />
    </FormGroup>
  </Form>
)

export default MessageLogFilter