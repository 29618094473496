import React from 'react'
import { InfoCircle, Gear } from 'react-bootstrap-icons'
import { UncontrolledTooltip, Button } from 'reactstrap'

import CurrencyDisplay from 'shared/components/CurrencyDisplay'
import { FormatDateSplit } from 'shared/components/FormatDate'
import UserPaymentType from '../../UserPaymentType'
import useUserPaymentModal from '../../useUserPaymentModal'
import LoanLink from 'components/loans/LoanLink'
import CustomerLink from 'components/customers/CustomerLink'

interface Props {
  payments: any[],
  columns: {[key: string]: boolean},
}

const UserPaymentListBody: React.FC<Props> = ({ payments, columns }) => {
  const { displayUserPaymentModal } = useUserPaymentModal()

  return (
    <tbody>
      {payments.map((payment: any) => (
        <tr key={payment.id}>
          <td className="text-center">
            <FormatDateSplit date={payment.paid_at} />
          </td>
          <td className="text-right">
            <CurrencyDisplay value={payment.amount} />
          </td>
          <td className="text-center">
            <UserPaymentType type={payment.type} />
          </td>
          {columns.payer &&
            <td className="text-center">
              <CustomerLink subject={payment.loan.borrower} opensModal />
            </td>}
          {columns.loan &&
            <td className="text-center">
              <LoanLink loanId={payment.loan.id} />
            </td>}
          <td className="text-center">
            <div className="d-inline-flex align-items-center">
              <div className="flex-shrink-1 mr-1">
                <FormatDateSplit date={payment.created_at} />
              </div>
              <span className="align-text-bottom">
                <InfoCircle size="12" className="text-info ml-1" id={'created_at_tooltip-' + payment.id} />
              </span>
            </div>
            <UncontrolledTooltip target={'created_at_tooltip-' + payment.id}>
              <b>Atliko:</b> {payment.created_by.first_name + ' ' + payment.created_by.last_name}
            </UncontrolledTooltip>
          </td>

          {columns.actions &&
            <td className="text-right">
              <Button color="light" className="btn-inline" onClick={() => displayUserPaymentModal(payment.id)}>
                <Gear size="14" className="mx-1" />
              </Button>
            </td>}
        </tr>
      ))}
    </tbody>
  )
}

export default UserPaymentListBody