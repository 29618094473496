import React from 'react'
import NumberFormat from 'react-number-format'

interface IProps {
  value: number,
  float?: boolean,
  pad?: boolean,
  decimalScale?: number,
  suffix?: boolean,
  colorize?: boolean,
}

const CurrencyDisplay: React.FC<IProps> = ({
  value,
  float = true,
  pad = true,
  decimalScale = 2,
  suffix = false,
  colorize = false
}) => {
  let className

  if (colorize) {
    if (value === 0) className = 'text-muted'
  }

  return (
    <NumberFormat
      className={className}
      value={float ? value/100 : value}
      displayType="text"
      thousandSeparator="&thinsp;"
      decimalScale={decimalScale}
      fixedDecimalScale={pad}
      suffix={suffix ? "\u20AC" : ""}
    />
  )
}

export default CurrencyDisplay
