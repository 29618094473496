import React from 'react'
import { Route, Switch, RouteComponentProps } from 'react-router-dom'
import { Container } from 'reactstrap'

import Create from './Create'
import Edit from './Edit'
import List from './List'
import View from './View'

const SystemUsers: React.FC<RouteComponentProps<{id: string}>> = ({ match }) => (
  <Container fluid>
    <Switch>
      <Route exact path={`${match.path}/create`} component={Create} />
      <Route exact path={`${match.path}/edit/:id`} component={Edit} />
      <Route exact path={`${match.path}/list`} component={List} />
      <Route exact path={`${match.path}/view/:id`} component={View} />
    </Switch>
  </Container>
)

export default SystemUsers