import React, { useState } from 'react'
import { Form, FormGroup, Label, Input } from 'reactstrap'

import { LoanStatus, LoanRepaymentStatus } from 'shared/models/Loan/enums'
import { LoanListFilterProps } from '../index'

interface Props {
  filters: LoanListFilterProps,
  updateFilters: (filters: LoanListFilterProps) => void,
}

const LoanListFilter: React.FC<Props> = ({ filters, updateFilters }) => {
  const [ search, setSearchString ] = useState<string|undefined>(filters.search)
  
  return (
    <Form
      onSubmit={e => e.preventDefault()}
      className="pb-2 justify-content-end"
      autoComplete="off"
      inline
    >
      <FormGroup className="mr-auto">
        <Label for="search" className="mr-1">Paieška:</Label>
        <Input
          id="search" name="search"
          type="text"
          bsSize="sm"
          value={search}
          onChange={(e: any) => setSearchString(e.target.value)}
          onBlur={() => updateFilters({...filters, search})}
        />
      </FormGroup>
  
      <FormGroup>
        <Label for="loan-status" className="mr-1">Paskolos pagal būseną:</Label>
        <Input
          id="loan-status" name="loan-status"
          type="select"
          bsSize="sm"
          value={filters.loanStatus}
          onChange={(e: any) => updateFilters({...filters, loanStatus: e.target.value})}
        >
          <option value={LoanStatus.ANY}>visos</option>
          <option value={LoanStatus.UNAUTHORIZED}>neautorizuotos</option>
          <option value={LoanStatus.PENDING}>nepatvirtintos</option>
          <option value={LoanStatus.ACTIVE}>aktyvios</option>
          <option value={LoanStatus.CLOSED}>uždarytos</option>
          <option value={LoanStatus.REJECTED}>atmestos</option>
        </Input>
      </FormGroup>
  
      <FormGroup className="ml-3">
        <Label for="repayment-status" className="mr-1">mokumas:</Label>
        <Input
          id="repayment-status" name="repayment-status"
          type="select"
          bsSize="sm"
          value={filters.loanRepaymentStatus}
          onChange={(e: any) => updateFilters({
            ...filters, loanRepaymentStatus: e.target.value,
            duePaymentLateDays: e.target.value === LoanRepaymentStatus.LATE ? 1 : undefined,
          })}
        >
          <option value={LoanRepaymentStatus.ANY}>visos</option>
          <option value={LoanRepaymentStatus.DRY}>be įmokų</option>
          <option value={LoanRepaymentStatus.ONTIME}>mokamos laiku</option>
          <option value={LoanRepaymentStatus.LATE}>vėluojančios</option>
          <option value={LoanRepaymentStatus.LATE_AND_DRY}>vėluojančios be įmokų</option>
        </Input>
      </FormGroup>
  
      {filters.loanRepaymentStatus === LoanRepaymentStatus.LATE &&
        <FormGroup className="ml-1">
          <Input
            id="repayment-status-days" name="repayment-status-days"
            type="number"
            bsSize="sm"
            min={1}
            step={1}
            value={filters.duePaymentLateDays}
            onChange={(e: any) => e.target.value > 0 && updateFilters({...filters, duePaymentLateDays: e.target.value})}
            style={{'width': '60px'}}
          />
          <Label for="repayment-status-days" className="ml-1">dienų ir daugiau</Label>
        </FormGroup>}
    </Form>
  )
}


export default LoanListFilter