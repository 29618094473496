import React from 'react'
import { Table } from 'reactstrap'

import CustomerListHeader from './components/CustomerListHeader'
import CustomerListBody from './components/CustomerListBody'

interface Props {
  customers: [any],
}

const CustomerList: React.FC<Props> = ({ customers }) => (
  <Table striped hover size="sm">
    <CustomerListHeader />
    <CustomerListBody customers={customers} />
  </Table>
)


export default CustomerList
