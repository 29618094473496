import { isAfter, addDays } from 'date-fns/esm'
import { LoanStatus, LoanStatusType, LoanRepaymentStatus, LoanRepaymentStatusType } from './enums'

export const getLoanStatus = (loan: any): LoanStatusType => {
  if (loan.rejected_at)
    return LoanStatus.REJECTED

  if (! loan.authorized_at)
    return LoanStatus.UNAUTHORIZED
    
  if (loan.closed_at)
    return LoanStatus.CLOSED
    
  if (loan.confirmed_at)
    return LoanStatus.ACTIVE

  return LoanStatus.PENDING
}

export const isLoanActive = (loan: any): boolean => {
  return getLoanStatus(loan) === LoanStatus.ACTIVE
}

export const isLoanClosed = (loan: any): boolean => {
  return getLoanStatus(loan) === LoanStatus.CLOSED
}

export const isLoanRejected = (loan: any): boolean => {
  return getLoanStatus(loan) === LoanStatus.REJECTED
}

export const isLoanAuthorized = (loan: any): boolean => {
  return getLoanStatus(loan) !== LoanStatus.UNAUTHORIZED
}

export const getOrderByLoanStatus = (status: LoanStatusType): [{
  column: string, order: 'ASC'|'DESC'
}] => {
  switch (status) {
    case LoanStatus.UNAUTHORIZED:
    case LoanStatus.PENDING:
      return [{column: 'REQUESTED_AT', order: 'DESC'}]
    case LoanStatus.REJECTED:
      return [{column: 'REJECTED_AT', order: 'DESC'}]
    case LoanStatus.ACTIVE:
      return [{column: 'CONFIRMED_AT', order: 'DESC'}]
    case LoanStatus.CLOSED:
      return [{column: 'CLOSED_AT', order: 'DESC'}]
    default:
      return [{column: 'ID', order: 'DESC'}]
  }
}

export const getRepaymentStatus = (loan: any): LoanRepaymentStatusType => {
  const now           = new Date()
  const next          = new Date(loan.next_payment_at)
  const nextWithGrace = addDays(next, 7)
  const approaching   = addDays(next, -7)

  if (isAfter(now, nextWithGrace))
    return LoanRepaymentStatus.OVERDUE

  else if (isAfter(now, next))
    return LoanRepaymentStatus.LATE

  else if (isAfter(now, approaching))
    return LoanRepaymentStatus.APPROACHING

  else
    return LoanRepaymentStatus.ONTIME
}


export const getStartingDebt = (loan: any): number => {
  return loan.principal + loan.interest
}

export const getCurrentDiscount = (loan: any): number => {
  return loan.principal_discount + loan.interest_discount
}

export const getCurrentPayments = (loan: any): number => {
  return loan.principal_paid + loan.interest_paid
}

export const getCurrentDebt = (loan: any): number => {
  return getStartingDebt(loan) - getCurrentDiscount(loan) - getCurrentPayments(loan)
}

export const getCurrentPrincipalDebt = (loan: any): number => {
  return loan.principal - loan.principal_discount - loan.principal_paid
}

export const getCurrentInterestDebt = (loan: any): number => {
  return loan.interest - loan.interest_discount - loan.interest_paid
}