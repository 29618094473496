import { isAfter, addDays } from 'date-fns/esm'
import { InstallmentRepaymentStatus, InstallmentRepaymentStatusType } from './enums'

export const getStartingDebt = (installment: any): number => {
  return installment.principal + installment.interest
}

export const getCurrentDiscount = (installment: any): number => {
  return installment.principal_discount + installment.interest_discount
}

export const getCurrentPayments = (installment: any): number => {
  return installment.principal_paid + installment.interest_paid
}

export const getCurrentDebt = (installment: any): number => {
  return getStartingDebt(installment) - getCurrentDiscount(installment) - getCurrentPayments(installment)
}

export const getRepaymentStatus = (installment: any): InstallmentRepaymentStatusType => {
  const now       = new Date()
  const deadline  = new Date(installment.expected_at)

  const approaching   = addDays(deadline, -7)
  const deadlineGrace  = addDays(deadline, 7)

  if (installment.closed_at) return InstallmentRepaymentStatus.CLOSED

  if (isAfter(now, deadlineGrace))
    return InstallmentRepaymentStatus.OVERDUE
  else if (isAfter(now, deadline))
    return InstallmentRepaymentStatus.LATE
  else if (isAfter(now, approaching))
    return InstallmentRepaymentStatus.APPROACHING
  else
    return InstallmentRepaymentStatus.PENDING
}
