import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Row, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, Label, Alert, Table, Tooltip, Container } from 'reactstrap'

import CurrencyDisplay from 'shared/components/CurrencyDisplay'
import { normalizeCurrencyDecimalSeparator } from 'shared/components/CurrencyHelpers'
import LoadableContent from 'shared/components/LoadableContent'

import { CALCULATE_LOAN_SCHEDULE } from './queries'

const AprCalculator: React.FC = () => {
  const [ activeTooltip, setActiveTooltip ] = useState('')

  /* inputs */
  const [ repaymentType, setRepaymentType ] = useState('ANNUITY')
  const [ principal, setPrincipal ] = useState("1000")
  const [ interest, setInterest ] = useState("200")
  const [ periodsPerYear, setPeriodsPerYear ] = useState(12)
  const [ periods, setPeriods ] = useState(12)
  const [ delayPeriods, setDelayPeriods ] = useState(0)
  const [ duration, setDuration ] = useState(0)
  const [ originationFee, setOriginationFee ] = useState(0)
  const [ closingFee, setClosingFee ] = useState(0)
  
  /* products */
  const [ apr, setApr ] = useState<string>('0')
  const [ air, setAir ] = useState<string>('0')
  const [ installments, setInstallments ] = useState<any[]>()
  
  const [ load, { loading, error, networkStatus }] = useLazyQuery(CALCULATE_LOAN_SCHEDULE, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted(response) {
      setApr((response.calculateLoanParams.apr / 100).toFixed(2))
      setAir((response.calculateLoanParams.air / 100).toFixed(2))
      setInstallments(response.calculateLoanParams.installments)
    },
  })

  const handleChange = (): void => {
    load({ variables: {
        type: repaymentType,
        principal: parseFloat(principal),
        interest: parseFloat(interest),
        periodsPerYear,
        periods,
        delayPeriods,
        originationFee,
        closingFee,
    }})
  }

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault()
    handleChange()
  }

  useEffect(() => {
    handleChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setDuration(Math.ceil((periods + delayPeriods) * 365 / periodsPerYear))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodsPerYear, periods, delayPeriods])

  return (
    <Container className="py-3">
      <Form
        onBlur={() => handleChange()}
        onSubmit={e => handleSubmit(e)}
        autoComplete="off"
      >
        <Row>
          <Col md={3} className="align-self-end">
            <FormGroup>
              <Label for="repayment-type">Grąžinimo grafikas</Label>
              <Input type="select"
                     id="repayment-type" name="repayment-type"
                     value={repaymentType}
                     onChange={e => setRepaymentType(e.target.value)}>
                <option value={'ANNUITY'}>Anuitetinis</option>
                <option value={'LINEAR'}>Linijinis</option>
                <option value={'BALLOON'}>Perpetuitetas</option>
              </Input>
            </FormGroup>
          </Col>
          <Col md={3} />
          <Col md={3} className="align-self-end">
            <FormGroup>
              <Label for="apr">MKGN</Label>
              <InputGroup>
                <Input
                  type="text" pattern="[0-9]*"
                  id="apr" name="apr"
                  value={apr}
                  onChange={e => setApr(e.target.value)} />
                <InputGroupAddon addonType="append">%</InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md={3} className="align-self-end">
            <FormGroup>
              <Label for="air">MPN</Label>
              <InputGroup>
                <Input
                  type="text" pattern="[0-9]*"
                  id="air" name="air"
                  value={air}
                  onChange={e => setAir(e.target.value)}
                  disabled />
                <InputGroupAddon addonType="append">%</InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={3} className="align-self-end">
            <FormGroup>
              <Label for="principal">Paskolos suma</Label>
              <InputGroup>
                <Input
                  type="text"
                  pattern="[0-9]+([\,][0-9]+)?"
                  id="principal" name="principal"
                  value={principal}
                  onChange={e => setPrincipal(normalizeCurrencyDecimalSeparator(e.target.value))} />
                <InputGroupAddon addonType="append">&euro;</InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Col>        
          <Col md={3} className="align-self-end">
            <FormGroup>
              <Label for="interest">Palūkanos</Label>
              <InputGroup>
                <Input
                  type="text"
                  id="interest" name="interest"
                  value={interest}
                  onChange={e => setInterest(normalizeCurrencyDecimalSeparator(e.target.value))} />
                <InputGroupAddon addonType="append">&euro;</InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md={3} className="align-self-end">
            <FormGroup>
              <Label for="origination-fee">Sutarties sudarymo mokestis</Label>
              <InputGroup>
                <Input
                  type="text" pattern="[0-9]*"
                  id="origination-fee" name="origination-fee"
                  value={originationFee}
                  onChange={e => setOriginationFee(parseInt(e.target.value))} />
                <InputGroupAddon addonType="append">&euro;</InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Col>        
          <Col md={3} className="align-self-end">
            <FormGroup>
              <Label for="closing-fee">Sutarties nutraukimo mokestis</Label>
              <InputGroup>
                <Input
                  type="text" pattern="[0-9]*"
                  id="closing-fee" name="closing-fee"
                  value={closingFee}
                  onChange={e => setClosingFee(parseInt(e.target.value))} />
                <InputGroupAddon addonType="append">&euro;</InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Col>  
        </Row>

        <Row>
          <Col md={3} className="align-self-end">
            <FormGroup>
              <Label for="periods-per-year">Periodų skaičius metuose</Label>
              <InputGroup>
                <Input
                  type="text" pattern="[0-9]*"
                  id="periods-per-year" name="periods-per-year"
                  value={periodsPerYear}
                  onChange={e => setPeriodsPerYear(parseInt(e.target.value))} />
                <InputGroupAddon addonType="append">vnt.</InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Col>
          <Col md={3} className="align-self-end">
            <FormGroup>
              <Label for="period-count">Įmokų skaičius</Label>
              <Input
                type="text" pattern="[0-9]*"
                id="period-count" name="period-count"
                value={periods}
                onChange={e => setPeriods(parseInt(e.target.value))} />
            </FormGroup>
          </Col>
          <Col md={3} className="align-self-end">
            <FormGroup>
              <Label for="delay-period-count">Atidėjimas</Label>
              <Input
                type="text" pattern="[0-9]*"
                id="delay-period-count" name="delay-period-count"
                value={delayPeriods}
                onChange={e => setDelayPeriods(parseInt(e.target.value))} />
              <Tooltip
                placement="bottom"
                target="delay-period-count"
                isOpen={activeTooltip == 'delay-period-count'}
                toggle={() => setActiveTooltip(activeTooltip === 'delay-period-count' ? '' : 'delay-period-count')}>
                <span className="text-monospace">Atidėjimas dienomis = X &times; periodo trukmė</span>
              </Tooltip>
            </FormGroup>
          </Col>
          <Col md={3} className="align-self-end">
            <FormGroup>
              <Label for="duration">Bendra trukmė</Label>
              <Input
                type="text" pattern="[0-9]*"
                id="duration" name="duration"
                value={duration}
                disabled />
            </FormGroup>
          </Col>
        </Row>      
      </Form>

      <LoadableContent
        ns={networkStatus}
        loading={loading}
        error={<Alert color="danger" isOpen={!!error}>Klaida bandant apskaičiuoti reikšmę</Alert>}
      >
        {!error && installments &&
          <Table striped borderless size="sm" className="text-right">
            <thead className="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Diena</th>
                <th scope="col">Kūnas</th>
                <th scope="col">Palūkanos</th>
                <th scope="col">Įmoka</th>
              </tr>
            </thead>
            <tbody>
              {installments.map((installment: any, index: number) => (
                <tr key={installment.day}>
                  <td>{index+1}</td>
                  <td>{installment.day}</td>
                  <td>
                    <CurrencyDisplay value={installment.principal} />
                  </td>
                  <td>
                    <CurrencyDisplay value={installment.interest} />
                  </td>
                  <td>
                    <CurrencyDisplay value={installment.principal + installment.interest} />
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot className="bg-light border-top border-dark">
              <tr>
                <td />
                <td />
                <td>
                  <CurrencyDisplay value={installments.reduce((acc: number, instl: any) => instl.principal + acc, 0)} />
                </td>
                <td>
                  <CurrencyDisplay value={installments.reduce((acc: number, instl: any) => instl.interest + acc, 0)} />
                </td>
                <td>
                  <CurrencyDisplay value={installments.reduce((acc: number, instl: any) => instl.principal + instl.interest + acc, 0)} />
                </td>
              </tr>
            </tfoot>
          </Table>
        }
      </LoadableContent>
        
    </Container>
  )
}

export default AprCalculator
