import React, { useState } from 'react'

import Header from './Header'
import { AdminLayoutContext } from './AdminLayoutContext'


const AdminLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [ compactHeader, setCompactHeader ] = useState(false)

  return (
    <AdminLayoutContext.Provider value={{ compactHeader, setCompactHeader: (value: boolean) => setCompactHeader(value) }}>
      <Header />
      <div className="main-content">{children}</div>
    </AdminLayoutContext.Provider>
  )
}

export default AdminLayout
