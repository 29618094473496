import React from 'react'
import { Table } from 'reactstrap'

import UserListTableHeader from './components/user-list-table-header'
import UserListTableBody from './components/user-list-table-body'

interface Props {
  users: [any]
}

const UserList: React.FC<Props> = ({ users }) => (
  <Table striped hover size="sm">
    <UserListTableHeader />
    <UserListTableBody users={users} />
  </Table>
)

export default UserList
