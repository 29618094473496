import React from 'react'
import { NetworkStatus } from '@apollo/client'
import { animated, useSpring } from 'react-spring'

interface LoadableContentProps {
  children: React.ReactNode,
  ns: NetworkStatus,
  loading: boolean,
  error?: React.ReactNode,
}

const LoadableContent: React.FC<LoadableContentProps> = ({ children, ns, loading, error = false }) => {
  const spinnerStyle = useSpring({opacity: loading ? 1 : 0})
  const displaySpinner = loading && ns !== NetworkStatus.refetch && ns !== NetworkStatus.poll

  return (
    <div className={displaySpinner ? 'loadable-content loading' : 'loadable-content'}>
      {ns === NetworkStatus.error && (error || <p>ERROR</p>)}
      {displaySpinner &&
        <animated.div className="loading-overlay" style={spinnerStyle}>
          <div data-loader="spinner"></div>
        </animated.div>}
      {children}
    </div>
  )
}

export default LoadableContent