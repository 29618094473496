import { gql } from '@apollo/client'
import { USER_ATTACHMENTS_FRAGMENT, USER_MESSAGE_LOG_FRAGMENT } from 'shared/models/User/fragments'

const PAGINATOR = gql`
  fragment paginatorInfo on UserPaginator {
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      perPage
      total
    }
  }
`

const GET_USERS = gql`
  query GetUsers($first: Int = 10, $page: Int = 1) {
    users(role: ADMIN, first: $first, page: $page) {
      ...paginatorInfo
      data {
        id
        first_name
        email
      }
    }
  }
  ${PAGINATOR}
`

const GET_USER_MESSAGE_LOG = gql`
  query GetUser(
    $id: ID!,
    $first: Int = 30,
    $page: Int = 1
  ) {
    user(id: $id) {
      id
      ...userMessageLog
    }
  }
  ${USER_MESSAGE_LOG_FRAGMENT}
`

const GET_USER_ATTACHMENTS = gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      id
      ...userAttachments
    }
  }
  ${USER_ATTACHMENTS_FRAGMENT}
`

export {
  GET_USERS,
  GET_USER_MESSAGE_LOG,
  GET_USER_ATTACHMENTS,
}
