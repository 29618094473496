
import React from 'react'
import { InfoCircle } from 'react-bootstrap-icons'
import { UncontrolledTooltip } from 'reactstrap'

import { FormatDateSplit } from 'shared/components/FormatDate'
import DownloadAttachment from 'components/attachments/DownloadAttachment'

interface Props {
  attachments: any[],
  columns: {[key: string]: boolean},
}

const AttachmentListBody: React.FC<Props> = ({ attachments, columns }) => (
  <tbody>
    {attachments.map((attachment: any) => (
      <tr key={attachment.id}>
        <td className="text-center">
          <div className="d-inline-flex align-items-center">
            <div className="flex-shrink-1 mr-1">
              <FormatDateSplit date={attachment.created_at} />
            </div>
            <span className="align-text-bottom">
              <InfoCircle size="12" className="text-info ml-1" id={'created_by_tooltip-' + attachment.id} />
            </span>
          </div>
          <UncontrolledTooltip target={'created_by_tooltip-' + attachment.id}>
            {attachment.created_by.first_name + ' ' + attachment.created_by.last_name}
          </UncontrolledTooltip>
        </td>
        {columns.description && <td className="text-center">{attachment.description}</td>}
        {columns.filename &&
          <td className="text-center">
            {columns.download
              ? <DownloadAttachment id={attachment.id} label={attachment.file_name} />
              : attachment.file_name}
            </td>}
      </tr>
    ))}
  </tbody>
)

export default AttachmentListBody