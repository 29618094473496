import React from 'react'
import { Row, Col } from 'reactstrap'

import CopyToClipboard from 'shared/components/CopyToClipboard'
import { FormatDate } from 'shared/components/FormatDate'

interface Props {
  user: any,
  customer: any,
}

const Questionnaire: React.FC<Props> = ({ user, customer }) => (
  <Row>
    <Col xs={12} lg={6} className="mb-3">
      <Row className="mb-1">
        <Col className="font-weight-bold text-right">
          <CopyToClipboard data={customer.first_name}>Vardas</CopyToClipboard>
        </Col>
        <Col className="text-left">{customer.first_name}</Col>
      </Row>
      <Row className="mb-1">
        <Col className="font-weight-bold text-right">
          <CopyToClipboard data={customer.last_name}>Pavardė</CopyToClipboard>
        </Col>
        <Col className="text-left">{customer.last_name}</Col>
      </Row>
      <Row className="mb-1">
        <Col className="font-weight-bold text-right">
          <CopyToClipboard data={customer.personal_id}>Asmens kodas</CopyToClipboard>
        </Col>
        <Col className="text-left">{customer.personal_id}</Col>
      </Row>
      <Row className="mb-1">
        <Col className="font-weight-bold text-right">
          <CopyToClipboard data={customer.birthday}>Gimimo data</CopyToClipboard>
        </Col>
        <Col className="text-left">{customer.birthday}</Col>
      </Row>
      <Row className="mb-1">
        <Col className="font-weight-bold text-right">
          <CopyToClipboard data={user.email}>El. paštas</CopyToClipboard>
        </Col>
        <Col className="text-left">{user.email}</Col>
      </Row>
      <Row className="mb-1">
        <Col className="font-weight-bold text-right">
          <CopyToClipboard data={customer.phone_number}>Tel. nr.</CopyToClipboard>
        </Col>
        <Col className="text-left">{customer.phone_number}</Col>
      </Row>
    </Col>

    <Col xs={12} lg={6} className="mb-3">
      <Row className="mb-1">
        <Col className="font-weight-bold text-right">
          <CopyToClipboard data={user.created_at}>Reg. data</CopyToClipboard>
        </Col>
        <Col className="text-left">
          <FormatDate format="yyyy-MM-dd HH:mm" date={user.created_at} />
        </Col>
      </Row>
      <Row className="mb-1">
        <Col className="font-weight-bold text-right">
          <CopyToClipboard data={[customer.workplace, ' ', customer.workplace_position]}>Darbovietė, pareigos</CopyToClipboard>
        </Col>
        <Col className="text-left">{customer.workplace} {customer.workplace_position}</Col>
      </Row>
      <Row className="mb-1">
        <Col className="font-weight-bold text-right">
          <CopyToClipboard data={customer.registration_address}>Reg. adresas</CopyToClipboard>
        </Col>
        <Col className="text-left">{customer.registration_address}</Col>
      </Row>
      <Row className="mb-1">
        <Col className="font-weight-bold text-right">
          <CopyToClipboard data={customer.residence_address}>Gyv. adresas</CopyToClipboard>
        </Col>
        <Col className="text-left">{customer.residence_address}</Col>
      </Row>
    </Col>

    <Col xs={12} lg={6} className="mb-3">
      <Row className="mb-1">
        <Col className="font-weight-bold text-right">
          <CopyToClipboard data={customer.avg_monthly_income}>Mėn. pajamos</CopyToClipboard>
        </Col>
        <Col className="text-left">{customer.avg_monthly_income}</Col>
      </Row>
      <Row className="mb-1">
        <Col className="font-weight-bold text-right">
          <CopyToClipboard data={customer.avg_monthly_liabilities}>Mėn. įsipareigojimai</CopyToClipboard>
        </Col>
        <Col className="text-left">{customer.avg_monthly_liabilities}</Col>
      </Row>
    </Col>
  </Row>
)

export default Questionnaire
