import { isAfter } from "date-fns"

const validatePaymentDateRange = (loan: any, paymentDate: string): boolean => {
  const confirmedAt = new Date(loan.confirmed_at)
  const paidAt = new Date(paymentDate)
  const now = new Date()
  
  return isAfter(paidAt, confirmedAt) && isAfter(now, paidAt)
}

export {
  validatePaymentDateRange,
}