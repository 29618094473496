import React from 'react'
import { Table } from 'reactstrap'

import { LoanStatus } from 'shared/models/Loan/enums'
import { getLoanStatus } from 'shared/models/Loan/helpers'

import LoanScheduleHeader from './components/LoanScheduleHeader'
import LoanScheduleBody from './components/LoanScheduleBody'
import LoanScheduleFooter from './components/LoanScheduleFooter'

interface Props {
  loan: any,
}

const LoanSchedule: React.FC<Props> = ({ loan }) => {
  const loanStatus = getLoanStatus(loan)

  let installments = loan.installments

  const columns = {
    index: true,
    deadline: true,
    daysUntillDeadline: true,
    dayOfPayment: false,
    discount: false,
    installmentAmount: true,
    paidAmount: true,
    payableAmount: true,
    datePaid: true,
    actions: false,
  }

  switch (loanStatus) {
    case LoanStatus.ANY:
      break

    case LoanStatus.UNAUTHORIZED:
    case LoanStatus.PENDING:
      installments = loan.expected_installments      
      columns.paidAmount = false
      columns.datePaid = false
      columns.daysUntillDeadline = false
      columns.dayOfPayment = true
      break

    case LoanStatus.ACTIVE:
      columns.discount = true
      columns.actions = true
      break

    case LoanStatus.CLOSED:
      columns.discount = true
      break
      
    case LoanStatus.REJECTED:
      installments = loan.expected_installments
      columns.paidAmount = false
      columns.datePaid = false
      break
  }

  return (
    <Table striped hover size="sm" className="loan-schedule">
      <LoanScheduleHeader columns={columns} />
      <LoanScheduleBody loan={loan} columns={columns} installments={installments} />
      <LoanScheduleFooter loan={loan} columns={columns} />
    </Table>
  )
}

export default LoanSchedule
