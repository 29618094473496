import React from 'react'
import { Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { GET_CURRENT_USER } from 'shared/models/User/queries'
import LoadingSplashScreen from 'shared/components/loading-splash-screen'

interface ProviderProps {
  children: React.ReactNode
}

interface ContextProps {
  data: any,
  called: boolean,
  loading: boolean,
  logout: () => void,
}

export const authContext = React.createContext<ContextProps>({
  data: null,
  called: false,
  loading: false,
  logout: Function,
})

const { Provider } = authContext

const AuthContextProvider: React.FC<ProviderProps> = ({ children }) => {
  const { data, loading, called, client } = useQuery(GET_CURRENT_USER)
  const logout = () => {
    window.localStorage.clear()
    client.resetStore()   
  }
  
  if (called && loading) {
    return <LoadingSplashScreen />
  }
  
  if (called && !loading && (!data || !data.me)) {
    return <Redirect to='/' />
  }

  return (
    <Provider value={{ data, loading, called, logout }}>
      {children}
    </Provider>
  )
}

export default AuthContextProvider
