import { gql } from '@apollo/client'
import { ATTACHMENT_DETAILS_FRAGMENT } from '../Attachment/fragments'
import { MESSAGE_LOG_PAGINATOR } from '../MessageLog/fragments'

const USER_NOTE_ATTRIBUTES_FRAGMENT = gql`
  fragment userNoteAttributes on UserNote {
    content
    is_relevant
    relevant_until
    importance

    created_by {
      id
      first_name
      last_name
    }
    created_at

    deleted_by {
      id
      first_name
      last_name
    }
    deleted_at
  }
`

const USER_SODRA_DATA_ATTRIBUTES_FRAGMENT = gql`
  fragment userSodraDataAttributes on SodraData {
    content

    created_by {
      id
      first_name
      last_name
    }
    created_at

    deleted_by {
      id
      first_name
      last_name
    }
    deleted_at
  }
`

const USER_MESSAGE_LOG_FRAGMENT = gql`
  fragment userMessageLog on User {    
    message_log(first: $first, page: $page) {
      ...messageLogPaginator
      data {
        id
        created_at
        last_attempt_at
        last_error
        delivered_at

        message_template {
          id
          category
          description
        }
        type
        content
      }
    }
  }
  ${MESSAGE_LOG_PAGINATOR}
`

const USER_ATTACHMENTS_FRAGMENT = gql`
  fragment userAttachments on User {    
    attachments {
      id
      ...attachmentDetails
    }
  }
  ${ATTACHMENT_DETAILS_FRAGMENT}
`

export {
  USER_NOTE_ATTRIBUTES_FRAGMENT,
  USER_SODRA_DATA_ATTRIBUTES_FRAGMENT,
  USER_MESSAGE_LOG_FRAGMENT,
  USER_ATTACHMENTS_FRAGMENT,
}