import React from 'react'
import { Nav, NavItem, NavLink, Button } from 'reactstrap'

import ButtonWithConfirmation from 'shared/components/ButtonWithConfirmation'

interface Props {
  onClick: (action: string) => void
}

const UserPaymentActions: React.FC<Props> = ({ onClick }) => (
  <div className="pb-1 mb-3 border-bottom justify-content-center">
    <Nav pills className="justify-content-end">
      <NavItem>
        <NavLink
          onClick={() => onClick('editUserPayment')}
          tag={Button} color="link" className="btn-sm"
        >Redaguotį įmoką</NavLink>
      </NavItem>
      <NavItem>
        <ButtonWithConfirmation
          className="nav-link"
          size="sm"
          buttonLabel="Ištrinti įmoką"
          confirmationTooltip="Tikrai norite ištrinti įmoką?"
          handleClick={() => onClick('deleteUserPayment')}
        />
      </NavItem>
    </Nav>
  </div>
)

export default UserPaymentActions