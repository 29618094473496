import React from 'react'

import { FormatDateSplit } from 'shared/components/FormatDate'

import LoanLink from 'components/loans/LoanLink'
import { InfoCircleFill } from 'react-bootstrap-icons'

interface Props {
  activities: any[],
  columns: {[key: string]: boolean},
}

const ActivityListBody: React.FC<Props> = ({ activities, columns }) => {
  const getSubjectButton = (subject: any) => {
    switch (subject.__typename) {
      case 'Loan':
        return <LoanLink loanId={subject.id}>Paskola &nbsp;<InfoCircleFill size={15} /></LoanLink>

      case 'LoanNote':
      case 'LoanInstallment':
      case 'UserPayment':
        return <LoanLink loanId={subject.loan.id}>Paskola &nbsp;<InfoCircleFill size={15} /></LoanLink>

      case 'User':
        return 'Vartotojas: ' + subject.id
      case 'UserNote':
        return 'Vartotojas: ' + subject.user.id
      case 'Customer':
        return 'Vartotojas: ' + subject.user.id

      case 'DocumentTemplate':
        return 'Dokumento šablonas: ' + subject.id

      default:
        return null;
    }
  }

  return (
    <tbody>
      {activities.map((activity: any) => (
        <tr key={activity.id}>
          {columns.performedAt && 
            <td className="text-center">
              <FormatDateSplit date={activity.created_at} />
            </td>}
          {columns.action && <td className="text-center">{activity.description}</td>}
          {columns.subject && <td className="text-center">{getSubjectButton(activity.subject)}</td>}
          {columns.causer &&
            <td className="text-center">
              {activity.causer.first_name + ' ' + activity.causer.last_name}
            </td>}
        </tr>
      ))}
    </tbody>
  )
}

export default ActivityListBody