import React from 'react'
import { Badge } from 'reactstrap'

interface Props {
  customer: any,
}

export const customerScoreOptions = [
  { value:  1, label: 11, color: 'danger' },
  { value:  2, label: 21, color: 'danger' },
  { value:  3, label: 12, color: 'warning' },
  { value:  4, label: 22, color: 'warning' },
  { value:  5, label: 13, color: 'gold' },
  { value:  6, label: 23, color: 'gold' },
  { value:  7, label: 14, color: 'cyan' },
  { value:  8, label: 24, color: 'info' },
  { value:  9, label: 15, color: 'success' },
  { value: 10, label: 25, color:  'success' },
];

const CustomerScoreBadge: React.FC<Props> = ({ customer }) => {
  const scoreOption = customerScoreOptions.find(score => score.value === customer.score);

  if (!customer.score || !scoreOption) return null

  return (
    <Badge
      color={scoreOption.color}
      style={{ padding: '.125rem .25rem' }}
    >
      {scoreOption.label}
    </Badge>
  )
}

export default CustomerScoreBadge
