import React, { useState } from 'react'

import CurrencyDisplay from 'shared/components/CurrencyDisplay'
import { FormatDate, DifferenceInDays, FormatDateSplit } from 'shared/components/FormatDate'
import { getRepaymentStatus, getCurrentPayments, getCurrentDiscount, getStartingDebt, getCurrentDebt } from 'shared/models/Installment/helpers'
import { getLoanStatus } from 'shared/models/Loan/helpers'
import { LoanStatus } from 'shared/models/Loan/enums'
import { Button } from 'reactstrap'
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons'
import LoanScheduleEditRow from './LoanScheduleEditRow'

interface Props {
  index: number,
  installment: any,
  loan: any,
  columns: {[key:string]: boolean},
}

const LoanScheduleRow: React.FC<Props> = ({ index, installment, loan, columns }) => {
  const [ edit, setEdit ] = useState<boolean>(false)

  return (<>
    <tr className={'repayment-status-' + getRepaymentStatus(installment).toLowerCase()}>
      <td className="text-right">{index+1}</td>
      <td className="text-center">
        <FormatDate format="yyyy-MM-dd" date={installment.expected_at} />
      </td>
      
      {(columns.daysUntillDeadline || columns.dayOfPayment) &&
        <td className="text-center">
          {!installment.closed_at && getLoanStatus(loan) === LoanStatus.CLOSED
            ? <span className="text-muted">&ndash;</span>
            : <DifferenceInDays dateLeft={installment.expected_at} dateRight={installment.closed_at} />}
        </td>}

      {columns.installmentAmount &&
        <td className="text-right">
          <CurrencyDisplay value={getStartingDebt(installment)} />
          <small className="d-block text-muted">
            <CurrencyDisplay value={installment.principal} />&#8202;/&#8202;<CurrencyDisplay value={installment.interest} />
          </small>
        </td>}

      {columns.discount &&
        <td className="text-right">
          {installment.discounted_at
            ? <>
                <CurrencyDisplay value={getCurrentDiscount(installment)} />
                <small className="d-block text-muted">
                  <CurrencyDisplay value={installment.principal_discount} />&#8202;/&#8202;<CurrencyDisplay value={installment.interest_discount} />
                </small>
              </>
            : <span className="text-muted">&ndash;</span>}
        </td>}

      {columns.paidAmount &&
        <td className="text-right">
          {installment.principal_paid || installment.interest_paid
            ? <>
                <CurrencyDisplay value={getCurrentPayments(installment)} />
                <small className="d-block text-muted">
                  <CurrencyDisplay value={installment.principal_paid} />&#8202;/&#8202;<CurrencyDisplay value={installment.interest_paid} />
                </small>
              </>
            : <span className="text-muted">&ndash;</span>}
        </td>}

      {columns.payableAmount &&
        <td className="text-right">
          {getCurrentDebt(installment) > 0
            ? <>
                <CurrencyDisplay value={getCurrentDebt(installment)} />
                <small className="d-block text-muted">
                  <CurrencyDisplay value={installment.principal - installment.principal_paid - installment.principal_discount} />
                  &#8202;/&#8202;
                  <CurrencyDisplay value={installment.interest - installment.interest_paid - installment.interest_discount}/>
                </small>
              </>
            : <span className="text-muted"><CurrencyDisplay value={0} /></span>}
        </td>}

      {columns.datePaid &&
        <td className="text-center">
          {installment.closed_at
            ? <FormatDateSplit date={installment.closed_at} />
            : <span className="text-muted">&ndash;</span>}
        </td>}

      {columns.actions &&
        <td className="text-right px-0">
          <Button color="light" className="btn-inline btn-muted" onClick={() => setEdit(!edit)}>
            {!edit && <ChevronDown size="12" className="mx-0" />}
            {edit && <ChevronUp size="12" className="mx-0" />}
          </Button>
        </td>}
    </tr>
    {edit && <LoanScheduleEditRow installment={installment} columns={columns} />}
  </>)
}

export default LoanScheduleRow