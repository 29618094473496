import React from 'react'
import { Redirect, match, useRouteMatch } from 'react-router-dom'

type TParams = { id: string };

const Edit: React.FC = () => {  
  const routeMatch: match<TParams> | null = useRouteMatch()

  if (!routeMatch) return <Redirect to="/users/customers/list" />

  return (
    <h2>Klientas {routeMatch.params.id}</h2>
  )
}

export default Edit
