import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'

import LoadableContent from 'shared/components/LoadableContent'
import UserPaymentList from 'components/user-payments/UserPaymentList'
import { GET_LOAN_USER_PAYMENTS } from '../../queries'

interface Props {
  id: any,
}

const LoanPayments: React.FC<Props> = ({ id }) => {
  const [ payments, setPayments ] = useState<any[]>([])

  const { data, loading, networkStatus } = useQuery(GET_LOAN_USER_PAYMENTS, {
    variables: { id },
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    data?.loan?.user_payments && setPayments(data.loan.user_payments)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <LoadableContent ns={networkStatus} loading={loading}>
      {data && (payments?.length > 0
        && <UserPaymentList payments={payments} columns={{ loan: false, payer: false }} />
        || <div className="display-4 py-5 text-center">Įmokų nėra</div>)
      }
    </LoadableContent>
  )
}

export default LoanPayments