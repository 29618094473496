import React from 'react'
import CustomerDetails from 'components/customers/CustomerDetails'
import { useParams, useRouteMatch, match, Redirect } from 'react-router-dom'

type TParams = { id: string };

const View: React.FC = (  ) => {
  const { id } = useParams<any>()
  const routeMatch: match<TParams> | null = useRouteMatch()

  if (!routeMatch) return <Redirect to="/users/customers/list" />

  return <CustomerDetails id={id} />
}

export default View
