import { gql } from '@apollo/client'
import { ACTIVITY_DETAILS_FRAGMENT } from 'shared/models/Activity/fragments'

const PAGINATOR = gql`
  fragment activityPaginator on ActivityPaginator {
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      perPage
      total
    }
  }
`

const GET_ACTIVITIES = gql`
  query GetActivities(
    $first: Int = 30,
    $dateAfter: Date,
    $dateBefore: Date
  ) {
    activities(
      first: $first,
      dateAfter: $dateAfter,
      dateBefore: $dateBefore,
    ) {
      ...activityPaginator
      data {
        id
        ...activityDetails
      }
    }
  }
  ${PAGINATOR}
  ${ACTIVITY_DETAILS_FRAGMENT}
`

export {
  GET_ACTIVITIES,
}