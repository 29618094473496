import React from 'react'
import { Table } from 'reactstrap'

import ActivityListHeader from './components/ActivityListHeader'
import ActivityListBody from './components/ActivityListBody'

interface Props {
  activities: [any],
  columns?: {[key:string]: boolean},
  onChange?: () => void,
}

const ActivityList: React.FC<Props> = ({ activities, columns = {} }) => {
  columns = {
    performedAt: true,
    action: true,
    subject: true,
    causer: true,
    ...columns
  }

  return (
    <Table striped hover size="sm" className="loan-list">
      <ActivityListHeader columns={columns} />
      <ActivityListBody activities={activities} columns={columns} />
    </Table>
  )
}

export default ActivityList
