import React from 'react'
import { Helmet } from 'react-helmet'
import { Container, Row, Col } from 'reactstrap'

const LoadingSplashScreen: React.FC = () => (
  <>
    <Helmet>
      <body className="overflow-hidden" />
    </Helmet>

    <div className="fullscreen-loader">
      <Container className="h-100">
        <Row className="h-100 justify-content-center align-items-center">
          <Col xs="auto">
            <h1>Loading...</h1>
          </Col>
        </Row>
      </Container>
    </div>
  </>
)

export default LoadingSplashScreen
