import React from 'react'
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom'
import { Container } from 'reactstrap'


import FilteredActivityList from 'components/activity/FilteredActivityList'
import FilteredMessageLog from 'components/messaging/FilteredMessageLog'

const Activity: React.FC<RouteComponentProps<{id: string}>> = ({ match }) => (  
  <Container fluid>
    <Switch>
      <Route exact path={[`${match.path}/users/list`, `${match.path}/customers/list`]}>
        <FilteredActivityList withFilter />
      </Route>

      <Route exact path={[`${match.path}/message-log`, `${match.path}/message-log/:page`]}>
        <FilteredMessageLog withFilter />
      </Route>
      
      <Redirect to="/dashboard" />
    </Switch>
  </Container>
)

export default Activity
