import { gql } from '@apollo/client'

const CALCULATE_LOAN_SCHEDULE = gql`
  query GetLoanSchedule(
    $type: LoanType!
    $principal: Float!
    $interest: Float!
    $delayPeriods: Int!
    $periods: Int!
    $periodsPerYear: Int!
    $originationFee: Float!
    $closingFee: Float!
  ) {
    calculateLoanParams(
      input: {
        type: $type
        principal: $principal
        interest: $interest
        delay_periods: $delayPeriods
        periods: $periods
        periods_per_year: $periodsPerYear
        origination_fee: $originationFee
        closing_fee: $closingFee
      }
    ) {
      apr
      air
      installments {
        day
        principal
        interest
      }
    }
  }
`

export {
  CALCULATE_LOAN_SCHEDULE
}
