import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { HashRouter, NavLink as Link, Switch, Route, Redirect } from 'react-router-dom'
import { Navbar, Nav, NavItem, NavLink } from 'reactstrap'

import { getLoanStatus } from 'shared/models/Loan/helpers'
import LoadableContent from 'shared/components/LoadableContent'

import { GET_LOAN } from '../queries'
import { UnauthorizedApplication } from './components/UnauthorizedApplication'
import { PendingApplication } from './components/PendingApplication'
import { RejectedApplication } from './components/RejectedApplication'
import Notes from './components/Notes'
import Metadata from './components/Metadata'
import LoanSchedule from '../LoanSchedule'
import LoanList from '../LoanList'
import Payments from './components/Payments'
import LoanClose from './components/LoanClose'
import Documents from './components/Documents'
import Messaging from './components/Messaging'

interface Props {
  id: any,
}

const LoanDetails: React.FC<Props> = ({ id }) => {
  const [ load, { loading, data, networkStatus } ] = useLazyQuery(GET_LOAN, {
    variables: { id },
    notifyOnNetworkStatusChange: true,
  })

  const [ loan, setLoan ] = useState<any>()

  useEffect(() => {
    if (id) load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (data?.loan) setLoan(data.loan)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <LoadableContent ns={networkStatus} loading={loading}>
      {loan &&
        <HashRouter basename={`loan/${id}`}>
          <Navbar color="dark" dark expand className="justify-content-center">
            <Nav navbar>
              <NavItem>
                <NavLink tag={Link} replace activeClassName="active" to={`/schedule`}>
                  Grafikas
                </NavLink>
              </NavItem>
              {loan.confirmed_at &&
                <NavItem>
                  <NavLink tag={Link} replace activeClassName="active" to={`/payments`}>
                    Įmokos
                  </NavLink>
                </NavItem>}
              <NavItem>
                <NavLink tag={Link} replace activeClassName="active" to={`/notes`}>
                  Pastabos
                </NavLink>
              </NavItem>
              {loan.confirmed_at &&
                <NavItem>
                  <NavLink tag={Link} replace activeClassName="active" to={`/messaging`}>
                    Pranešimai
                  </NavLink>
                </NavItem>}
              {loan.confirmed_at &&
                <NavItem>
                  <NavLink tag={Link} replace activeClassName="active" to={`/documents`}>
                    Dokumentai
                  </NavLink>
                </NavItem>}
              {!loan.closed_at && loan.confirmed_at &&
                <NavItem>
                  <NavLink tag={Link} replace activeClassName="active" to={`/close`}>
                    Paskolos uždarymas
                  </NavLink>
                </NavItem>}
            </Nav>
          </Navbar>

          <div className="p-2">
            <UnauthorizedApplication loan={loan} />
            <PendingApplication loan={loan} />
            <RejectedApplication loan={loan} />
            <Switch>
              <Route path="/schedule">
                <Notes key="schedule" loan={loan} compact />
                <Metadata loan={loan} />
                <LoanList loans={[loan]} columns={{loanInfo: false, notes: false}} status={getLoanStatus(loan)} />
                <LoanSchedule loan={loan} />
              </Route>
              {loan.confirmed_at &&
                <Route path="/payments">
                  <Payments id={id} />
                </Route>}
              <Route path="/notes">
                <Notes key="notes" loan={loan} includeTrashed />
              </Route>
              <Route path="/messaging">
                <Messaging id={id} />
              </Route>
              <Route path="/documents">
                <Documents id={id} />
              </Route>
              {!loan.closed_at && loan.confirmed_at &&
                <Route path="/close">
                  <LoanClose id={id} />
                </Route>}
              
              <Redirect push to={`/schedule`} from="/" strict exact />
            </Switch>
          </div>
        </HashRouter>}
    </LoadableContent>
  )
}

export default LoanDetails