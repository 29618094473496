import React from 'react'

import DurationDisplay from 'shared/components/DurationDisplay'
import LoanScheduleRow from './LoanScheduleRow'

interface Props {
  loan: any,
  installments: any,
  columns: {[key:string]: boolean},
}

const LoanScheduleBody: React.FC<Props> = ({ loan, installments, columns }) => (
  <tbody>
    {!!loan.delay_periods &&
      <tr>
        <td
          className="py-2 bg-info text-center"
          colSpan={Object.values(columns).filter(visible => !!visible).length}
        >
          <b><DurationDisplay periods={loan.delay_periods} periodsPerYear={loan.periods_per_year} displayPeriods={false} /></b>d. atidėjimas
        </td>
      </tr>}

    {installments.map((installment: any, index: number) => (
      <LoanScheduleRow key={index} index={index} loan={loan} installment={installment} columns={columns} />
    ))}
  </tbody>
)

export default LoanScheduleBody