import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Table } from 'reactstrap'

import { FormatDistance } from 'shared/components/FormatDate'
import LoadableContent from 'shared/components/LoadableContent'

import { GET_RECENTLY_REGISTERED_CUSTOMERS } from './queries'
import CustomerLink from './CustomerLink';
import CustomerScoreBadge from './CustomerScoreBadge'
import CustomerClassificatorBadge from './CustomerClassificatorBadge'

const RecentlyRegisteredCustomers: React.FC = () => {
  const [ customers, setCustomers ] = useState<any>()

  const { data, loading, networkStatus } = useQuery(GET_RECENTLY_REGISTERED_CUSTOMERS, {
    pollInterval: 30 * 1000,
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    if (data?.customers) setCustomers(data.customers)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <LoadableContent ns={networkStatus} loading={loading}>
      {customers && (customers.paginatorInfo?.count
        &&  <>
              <h3>Naujausios registracijos</h3>
              <Table striped hover size="sm">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Vardas</th>
                    <th scope="col">R</th>
                    <th scope="col">El. paštas</th>
                    <th scope="col">Miestas</th>
                  </tr>
                </thead>
                <tbody>
                  {customers.data.map((customer: any) => (
                    <tr key={customer.id}>
                      <td>
                        <FormatDistance date={customer.user.created_at} />
                      </td>
                      <td>
                        <CustomerLink subject={customer.user} opensModal />
                      </td>
                      <td>
                        <CustomerScoreBadge customer={customer} />
                        {' '}
                        <CustomerClassificatorBadge customer={customer} />
                      </td>
                      <td>{customer.user.email}</td>
                      <td>{customer.residence_city}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          ||  <h5 className="py-2 text-center text-muted">Naujų registracijų nėra</h5>)}
    </LoadableContent>
  )
}

export default RecentlyRegisteredCustomers
